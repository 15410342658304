import React from "react";
import { Row, Col, Container } from "react-bootstrap";

function ObavestenjeRGZRadionice() {
  return (
    <Container>
      <Row>
        <div style={{ textAlign: "center" }}>
          <Col md={12}>
            <h2>Obavestenje RGZ radionice</h2>
          </Col>
        </div>
      </Row>
      <Row>
        <Col md={3}>
          <i>Savez geodeta Srbije</i>
          <br></br>
          <i>08.11.2023.</i>
        </Col>
        <Col md={6}></Col>
        <Col md={3}></Col>
      </Row>
      <hr></hr>
      <Row>
        <Col md={2}></Col>
        <Col md={8}>
          <p style={{ textAlign: "justify" }}>
            Savez geodeta Srbije, dana 3.11.2023. godine, uputio je dopis
            Republičkom geodetskom zavodu povodom donošenja Zakona o izmenama i
            dopunama Zakona o državnom premeru i katastru, Zakona o izmenama i
            dopunama Zakona o postupku upisa u katastar nepokretnosti i vodova,
            kao i Pravilnika o katastarskom premeru, obnovi katastra i
            geodetskim radovima u održavanju katastra nepokretnosti.
          </p>
          <p style={{ textAlign: "justify" }}>
            U tom dopisu se izražava spremnost Saveza da organizuje radionice i
            skupove za svoje članove i druge zainteresovane kolege koji se bave
            geodetskim radovima u održavanju katastra nepokretnosti, a gde bi
            bila izvršena prezentacija elaborata geodetskih radova i obuka od
            strane stručnjaka Republičkog geodetskog zavoda. Savez geodeta
            Srbije je stavio na raspolaganje sve svoje resurse u pogledu
            organizacije takvih radionica jer mislimo da je to interesu našeg
            članstva i celokupne geodetske struke.
          </p>
          <p style={{ textAlign: "justify" }}>
            Nadamo se da će republički geodetski zavod pozitivno odgovoriti na
            našu inicijativu, tim pre što je u opštem interesu da prezentacije
            elaborata geodetskih radova i obuke budu sprovedene na što
            efikasniji način.
          </p>
        </Col>
        <Col md={2}></Col>
      </Row>
    </Container>
  );
}

export default ObavestenjeRGZRadionice;
