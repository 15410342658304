import React from "react";
import { Carousel, Col, Container, Row } from "react-bootstrap";
import Slika1 from "../../assets/img/news/clanstvo-eu-komisije/01.webp";

function ClanstvoEuKomisiji() {
  return (
    <Container>
      <Row>
        <div style={{ textAlign: "center" }}>
          <Col md={12}>
            <h2>Članstvo u evropskoj komisiji geodeta (CLGE)</h2>
          </Col>
        </div>
      </Row>
      <Row>
        <Col md={3}>
          <i>Savez geodeta Srbije</i>
          <br></br>
          <i>24.04.2024.</i>
        </Col>
        <Col md={6}></Col>
        <Col md={3}></Col>
      </Row>
      <hr></hr>
      <Row>
        <Col md={1}></Col>
        <Col md={10}>
          <div
            className="text-center"
            style={{ height: "auto", width: "100%" }}
          >
            <img
              className="text-center"
              style={{ height: "auto", width: "50%" }}
              src={Slika1}
            ></img>
          </div>
        </Col>
        <Col md={1}></Col>
      </Row>
      <br></br>
      <Row>
        <Col md={2}></Col>
        <Col md={8}>
          <p style={{ textAlign: "justify" }}>
            Savez geodeta Srbije primljen je u članstvo Evropske komisije
            geodeta, CLGE (Comité de Liaison des Géomètres Européens) kao
            punopravni član (Principal Member). Na skupštini CLGE održanoj od
            18. do 20. aprila 2024. godine u Cavtatu, izglasan je prijem Saveza
            geodeta Srbije, kao krovne organizacije geodeta u Srbiji, u
            punopravno članstvo. Za prijem su bili predstavnici svih zemalja
            osim predstavnika samozvane države Kosovo koji je bio protiv.
          </p>
          <p style={{ textAlign: "justify" }}>
            Savez geodeta Srbije kao jedan od glavnih ciljeva svog rada ima
            međunarodnu saradnju. Pored punopravnog članstva u FIG, članstvo u
            ovoj evropskoj organizaciji je korak napred ka ispunjenju tog cilja.
            Aktuelno rukovodstvo SGS-a je vredno radilo na stvaranju uslova za
            učlanjenje u ovu jako značajnu organizaciju što je dovelo do
            konačnog rezultata, punopravnog članstva. Srbije je, do sada, bila
            jedna od malobrojnih evropskih zemalja koja nije bila članica CLGE,
            što, s obzirom na značaj naše zemlje, smatramo da nije bilo dobro.
          </p>
          <p style={{ textAlign: "justify" }}>
            Posebno smo ponosni što su aktivnosti na učlanjenju u CLGE
            motivisale i druge subjete iz Srbije da se aktiviraju i apliciraju
            za članstvo. Nakon aplikacije Udruženja privatnih geodetskih
            organizacija – UGOS, potpisan je sporazum između SGS-a i UGOS-a
            kojim se rešavaju međusobni odnosi te dve organizacije u skupštini
            CLGE, u skladu sa pravilama CLGE. Na osnovu tog sporazuma svaka od
            organizacija ima predstavnika u skupštini CLGE, s tim što pravo
            glasa, uz konsulatacije, ima delegat SGS. RGZ je, nakon naše prijave
            takođe bio motivisan za članstvo, pa je primljen u članstvo kao
            institucionalni-intersni član bez prava glasa.
          </p>
          <p style={{ textAlign: "justify" }}>
            Uvereni smo da će naše članstvo u ovoj značajnoj evropskoj
            organizaciji biti itekako korisno za geodeziju u Srbiji. Korišćenje
            iskustava naših kolega iz raznih evropskih država, njihovo aktivno
            učešće u rešavanju mnogih izazova naše struke, kao i njihova
            povezanost sa institucijama EU, može umnogome da doprinese razvoju i
            podizanju ugleda geodetke struke u Srbiji.
          </p>
        </Col>
        <Col md={2}></Col>
      </Row>
    </Container>
  );
}

export default ClanstvoEuKomisiji;
