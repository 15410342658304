import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import zakon from "../../assets/img/news/zakon-o-pi/zakon.webp"
import "../../assets/css/custom.css"

function ZakonOPIScreen() {
  return (
    <Container>
      <Row>
        <Col md={6}>
          <div className='text-center' style = {{height: "auto", width: "100%"}}>
            <img className='text-center' style = {{height: "auto", width: "80%"}} src={zakon}></img>
          </div>
        </Col>
        <Col md={6}>
          <p style={{textAlign: "justify"}}>Savez geodeta Srbije obaveštava svoje članstvo i stručnu javnost da je uzeo aktivno učešće u izradi predloga izmena i dopuna Zakona o planiranju i izgradnji. Predlozi izmena Zakona su zvanično upućeni Ministarstvu građevinarstva, saobraćaja i infrastrukture.</p>
          <p className='zakon-op-second-p' style={{textAlign: "justify"}}>Prva izmena koju smo predložili je da lice ovlašćeno za izradu projekta preparcelacije bude geodetski stručnjak, umesto dosadašnjeg rešenja po kome je projektom preparcelacije rukovodio urbanista. Pored toga upućeno je niz predloga kojima bi se uredila oblast urbane komasacije. Zahvaljujuči dosadašnjim neadekvatnim zakonskim rešenjima urbana komasacija nije zaživela u praksi u Srbiji. Smatramo da je to velika prilika za našu struku i da bi se masovnijom primenom urbane komasacije otvorili značajni poslovi za geodetske firme i pojedince.</p>
        </Col>
      </Row>
      <Row>
        <p className='zakon-op-third-p' style={{textAlign: "justify"}}>Prva izmena koju smo predložili je da lice ovlašćeno za izradu projekta preparcelacije bude geodetski stručnjak, umesto dosadašnjeg rešenja po kome je projektom preparcelacije rukovodio urbanista. Pored toga upućeno je niz predloga kojima bi se uredila oblast urbane komasacije. Zahvaljujuči dosadašnjim neadekvatnim zakonskim rešenjima urbana komasacija nije zaživela u praksi u Srbiji. Smatramo da je to velika prilika za našu struku i da bi se masovnijom primenom urbane komasacije otvorili značajni poslovi za geodetske firme i pojedince.</p>
        <p style={{textAlign: "justify", marginTop: "16px"}}>Javna rasprava o izmenama zakona je u toku i želimo da obavestimo naše članstvo da će rukovodstvo SGS-a učestvovati u njoj, 20.2.2023. u terminu od 11:00 do 13:00 časova, u prostorijama Privredne komore Srbije, ulica Resavska 13-15. Pozivamo zainteresovane članove SGS-a da, ukoliko imaju predloge, i oni učestvuju u njoj. </p>
      </Row>
    </Container>
  )
}

export default ZakonOPIScreen