import React, {useState, useEffect} from 'react';
import { Container, Table, Row, Col } from 'react-bootstrap';

function ClanoviScreen() {
    return (
        <Container>
            <Row>
                <Col md={3} />
                <Col md={6}>
                </Col>
                <Col md={3} />
            </Row>
        </Container>
    )
}

export default ClanoviScreen
