import React, {useState, useEffect} from 'react'
import {Tabs, Tab, Form, Button, Container, Col, Row} from 'react-bootstrap';
import '../../assets/css/forms.css';
import axios from 'axios';
import {url, regex} from '../../constants/constants';
import {Alert, Spinner} from 'react-bootstrap';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

function RequestAccountScreen() {
    const [name, setName] = useState();
    const [father, setFather] = useState();
    const [surname, setSurname] = useState();
    const [phone, setPhone] = useState();
    const [email, setEmail] = useState();
    const [strucnaSprema, setStrucnaSprema] = useState();
    const [radnoMesto, setRadnoMesto] = useState();
    const [birthCity, setBirthCity] = useState();
    const [city, setCity] = useState();
    const [address, setAddress] = useState();
    const [idNumber, setIdNumber] = useState();
    const [idCity, setIdCity] = useState();
    const [companyRole, setCompanyRole] = useState();

    const [companyName, setCompanyName] = useState();
    const [pib, setPib] = useState();
    const [companyNumber, setCompanyNumber] = useState();
    const [companyEmail, setCompanyEmail] = useState();
    const [companyPhone, setCompanyPhone] = useState();
    const [numberOfEmployees, setNumberOfEmployees] = useState();

    const [error, setError] = useState();
    const [working, setWorking] = useState(false);

    const [startDate, setStartDate] = useState();
    
    async function checkLogIn(){
        const {data} = await axios.get(url + 'api/isauth', {withCredentials: true})
        if(data == true){
            window.location.href="/";
        }
    }

    useEffect(()=>{
        checkLogIn();
    }, [])

    async function register(){
        setWorking(true);

        const fields = [name, father, surname, phone, email, strucnaSprema, radnoMesto, startDate, birthCity, city, address, idNumber, idCity, companyRole];

        for(let i = 0; i < fields.length; i++){
            if(!fields[i]){
                setError("Molimo Vas da popunite sva polja!");
                setWorking(false);
                return;
            }
        }

        if(!regex.test(email)){
            setError("Loš format email adrese.");
            setWorking(false);
            return;
        }

        try{
            const {data} = await axios.post(url + "api/requestaccount", {name: name, fatherName: father, surname: surname, phone: phone, email: email, profQualLevel: strucnaSprema, currPosition: radnoMesto, birthDate: startDate, isCompany: false, birthCity: birthCity, address: address, city: city, idNumber: idNumber, idCity: idCity, companyRole: companyRole}, {withCredentials: true});
            window.location.href = "/?success=true&message=101";
        } catch(err){
            setError(err.response.data);
            setWorking(false);
        }
    }

    async function registerCompany(){
        setWorking(true);

        const fields = [companyName, pib, companyNumber, companyEmail, companyPhone, numberOfEmployees];

        for(let i = 0; i < fields.length; i++){
            if(!fields[i]){
                setError("Molimo Vas da popunite sva polja!");
                setWorking(false);
                return;
            }
        }

        if(!regex.test(companyEmail)){
            setError("Loš format email adrese.");
            setWorking(false);
            return;
        }

        try{
            const {data} = await axios.post(url + "api/requestaccount", {companyName: companyName, pib: pib, companyNumber: companyNumber, email: companyEmail, phone: companyPhone, employees: numberOfEmployees, isCompany: true}, {withCredentials: true});
            window.location.href = "/?success=true&message=101";
        } catch(err){
            setError(err.response.data);
            setWorking(false);
        }
    }
    return (
        <Container>

            <Row>
                <Col md={3}></Col>
                    <Col md={6}>
                        <Tabs fill defaultActiveKey="fizicka-lica" id="uncontrolled-tab-example">
                            <Tab eventKey="fizicka-lica" title="Fizička lica"> 
                            <br></br>
                            <Form>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Ime</Form.Label>
                                    <Form.Control type="text" placeholder="Ime" onChange={(e)=>{setName(e.target.value)}} />
                                </Form.Group>
                                <br></br>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Ime oca</Form.Label>
                                    <Form.Control type="text" placeholder="Ime oca" onChange={(e)=>{setFather(e.target.value)}} />
                                </Form.Group>
                                <br></br>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Prezime</Form.Label>
                                    <Form.Control type="text" placeholder="Prezime" onChange={(e)=>{setSurname(e.target.value)}} required/>
                                </Form.Group>
                                <br></br>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Datum rođenja</Form.Label>
                                    <Form.Control type="text" placeholder="Datum Rođenja" onChange={(e)=>{setStartDate(e.target.value)}} required/>
                                </Form.Group>
                                <br></br>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Mesto rođenja</Form.Label>
                                    <Form.Control type="text" placeholder="Mesto rođenja" onChange={(e)=>{setBirthCity(e.target.value)}} required/>
                                </Form.Group>
                                <br></br>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Adresa stanovanja</Form.Label>
                                    <Form.Control type="text" placeholder="Adresa stanovanja" onChange={(e)=>{setAddress(e.target.value)}} required/>
                                </Form.Group>
                                <br></br>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Mesto stanovanja</Form.Label>
                                    <Form.Control type="text" placeholder="Mesto stanovanja" onChange={(e)=>{setCity(e.target.value)}} required/>
                                </Form.Group>
                                <br></br>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Broj lične karte</Form.Label>
                                    <Form.Control type="text" placeholder="Broj lične karte" onChange={(e)=>{setIdNumber(e.target.value)}} required/>
                                </Form.Group>
                                <br></br>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Mesto izdavanje lične karte</Form.Label>
                                    <Form.Control type="text" placeholder="Mesto izdavanja lične karte" onChange={(e)=>{setIdCity(e.target.value)}} required/>
                                </Form.Group>
                                <br></br>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Email adresa</Form.Label>
                                    <Form.Control type="email" placeholder="Email adresa" onChange={(e)=>{setEmail(e.target.value)}} />
                                </Form.Group>
                                <br></br>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Broj telefona</Form.Label>
                                    <Form.Control type="text" placeholder="Broj telefona" onChange={(e)=>{setPhone(e.target.value)}} />
                                </Form.Group>
                                <br></br>
                                <Form.Group controlId="formBasicPassword">
                                    <Form.Label>Stepen stručne spreme</Form.Label>
                                    <Form.Control type="text" placeholder="Stepen stručne spreme" onChange={(e)=>{setStrucnaSprema(e.target.value)}} />
                                </Form.Group>
                                <br></br>
                                <Form.Group controlId="formBasicPassword">
                                    <Form.Label>Naziv kompanije/organizacije</Form.Label>
                                    <Form.Control type="text" placeholder="Naziv kompanije/organizacije" onChange={(e)=>{setRadnoMesto(e.target.value)}} />
                                </Form.Group>
                                <br></br>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Opis radnog mesta (pozicija u kompaniji/organizaciji)</Form.Label>
                                    <Form.Control type="text" placeholder="Opis radnog mesta (pozicija u kompaniji/organizaciji)" onChange={(e)=>{setCompanyRole(e.target.value)}} required/>
                                </Form.Group>
                                
                                {error && 
                                <Alert variant="danger">
                                    {error}
                                </Alert>
                                }
                                <br></br>
                                <Button variant="success" type="submit" onClick={(e)=>{e.preventDefault(); register()}}>
                                    {working ? <Spinner animation="border" /> : "Pošalji zahtev"}
                                </Button>
                                </Form>
                            </Tab>
                            <Tab eventKey="pravna-lica" title="Pravna lica">
                            <Form>
                            <br></br>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Ime firme</Form.Label>
                                    <Form.Control type="text" placeholder="Ime firme" onChange={(e)=>{setCompanyName(e.target.value)}} />
                                </Form.Group>
                                <br></br>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>PIB</Form.Label>
                                    <Form.Control type="text" placeholder="PIB" onChange={(e)=>{setPib(e.target.value)}} />
                                </Form.Group>
                                <br></br>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Matični broj</Form.Label>
                                    <Form.Control type="text" placeholder="Matični broj" onChange={(e)=>{setCompanyNumber(e.target.value)}} />
                                </Form.Group>
                                <br></br>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" placeholder="Email" onChange={(e)=>{setCompanyEmail(e.target.value)}} />
                                </Form.Group>
                                <br></br>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Broj telefona</Form.Label>
                                    <Form.Control type="text" placeholder="Broj telefona" onChange={(e)=>{setCompanyPhone(e.target.value)}} />
                                </Form.Group>
                                <br></br>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Broj zaposlenih</Form.Label>
                                    <Form.Control type="text" placeholder="Broj zaposlenih" onChange={(e)=>{setNumberOfEmployees(e.target.value)}} />
                                </Form.Group>
                                <br></br>
                                {error && 
                                <div>
                                    <br></br>
                                    <Alert variant="danger">
                                        {error}
                                    </Alert>
                                </div>
                                }
                                <br></br>
                                <Button variant="success" type="submit" onClick={(e)=>{e.preventDefault(); registerCompany()}}>
                                    {!working ? "Pošalji zahtev" : <Spinner animation="border" />}
                                </Button>
                                </Form>
                            </Tab>
                        </Tabs>
                    </Col>
                <Col md={3}></Col>
            </Row>
        </Container>
    )
}
export default RequestAccountScreen