import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import grf from "../../assets/img/grf.webp";
import dusan from "../../assets/img/dusan.webp";
import verica from "../../assets/img/verica.webp";
import tpi from "../../assets/img/tpi.webp";

function PredavanjeSkener() {
  return (
    <Container>
      <Row>
        <div style={{ textAlign: "center" }}>
          <Col md={12}>
            <h2>
              Stručno predavanje na temu Terestrički laserski skener i BIM
            </h2>
          </Col>
        </div>
      </Row>
      <Row>
        <Col md={3}>
          <i>Savez geodeta Srbije</i>
          <br></br>
          <i>12.04.2023.</i>
        </Col>
        <Col md={6}></Col>
        <Col md={3}></Col>
      </Row>
      <hr></hr>
      <Row>
        <Col md={12}>
          <p></p>
          <p style={{ textAlign: "justify" }}>
            Savez geodeta Srbije ima zadovoljstvo da u saradnji sa Dr Dušanom
            Isailović, diplomiranim inženjerom građevinarstva i master
            inženjerom geodezije Vericom Erić organizuje stručno predavanje na
            temu Terestrički laserski skener i BIM, koje će biti prvo u nizu
            predavanja planiranih za ovu godinu.
          </p>
          <p style={{ textAlign: "justify" }}>
            Predavanje će se održati u petak, 21. 4. 2023. godine u zgradi
            Saveza inženjera i tehničara Srbije na adresi Kneza Miloša 7a, sprat
            3, u Beogradu, s početkom u 13 časova.
          </p>
          <p style={{ textAlign: "justify" }}>
            Ovo predavanje je otvoreno za sve zainteresovane, bez naknade za
            ulaz.
          </p>
          <p style={{ textAlign: "justify" }}>Dobrodošli!</p>
        </Col>
      </Row>
      <br></br>
      <Row>
        <Col className="dusan-mobile" md={5}>
          <br></br>
          <div
            className="text-center"
            style={{ height: "auto", width: "100%" }}
          >
            <img
              className="text-center"
              style={{ height: "auto", width: "60%" }}
              src={dusan}
              alt="Dr Dušan Isailović, dipl. inž. građ. je docent
              Građevinskog fakulteta Univerziteta u Beogradu, član Katedre za
              upravljanje projektima u građevinarstvu i predavač na grupi predmeta
              za informacione tehnologije u građevinarstvu i geodeziji."
            ></img>
          </div>
        </Col>
        <Col md={7}>
          <p></p>
          <p style={{ textAlign: "justify" }}>
            <strong>Dr Dušan Isailović</strong>, dipl. inž. građ. je docent
            Građevinskog fakulteta Univerziteta u Beogradu, član Katedre za
            upravljanje projektima u građevinarstvu i predavač na grupi predmeta
            za informacione tehnologije u građevinarstvu i geodeziji. U svojim
            istraživanjima, Dr Isailović pretežno se bavi Informacionim
            modeliranjem građevinskih objekata (Building Information Modeling -
            BIM) i Digitalnim blizancima građevinskih objekata (Building Digital
            Twins - BDT).
          </p>
          <p style={{ textAlign: "justify" }}>
            Dr Isailović je predavač na dva predmeta vezanih za BIM tehnologiju
            na Građevinskom fakultetu u Beogradu: "Osnove projektovanja primenom
            BIM tehnologije" i "Primena BIM-a u upravljanju projektima".
          </p>
        </Col>
        <Col className="dusan-desktop" md={5}>
          <br></br>
          <div
            className="text-center"
            style={{ height: "auto", width: "100%" }}
          >
            <img
              className="text-center"
              style={{ height: "auto", width: "60%" }}
              src={dusan}
              alt="Dr Dušan Isailović, dipl. inž. građ. je docent
              Građevinskog fakulteta Univerziteta u Beogradu, član Katedre za
              upravljanje projektima u građevinarstvu i predavač na grupi predmeta
              za informacione tehnologije u građevinarstvu i geodeziji."
            ></img>
          </div>
        </Col>
      </Row>
      <br></br>
      <Row>
        <Col md={5}>
          <br></br>
          <div
            className="text-center"
            style={{ height: "auto", width: "100%" }}
          >
            <img
              className="text-center"
              style={{ height: "auto", width: "60%" }}
              src={verica}
              alt="Verica Erić završila je osnovne i master studije na
              Odseku za geodeziju i geoinformatiku na Građevinskom fakultetu
              Univerziteta u Beogradu i 2011. godine stekla zvanje Master
              inženjera geodezije."
            ></img>
          </div>
        </Col>
        <Col md={7}>
          <p></p>
          <p style={{ textAlign: "justify" }}>
            <strong>Verica Erić </strong>završila je osnovne i master studije na
            Odseku za geodeziju i geoinformatiku na Građevinskom fakultetu
            Univerziteta u Beogradu i 2011. godine stekla zvanje Master
            inženjera geodezije.
          </p>
          <p style={{ textAlign: "justify" }}>
            Od jula 2017. godine živi i radi u Nemačkoj. Praktična iskustva iz
            oblasti klasične i savremene geodezije od 2020. godine stiče u
            kompaniji TPI Vermessungsgesellschaft mbH u Hesenu. Kao master
            inženjer geodezije i BIM koordinator deo je tima koji se bavi
            terestričkim laserskim skeniranjem, mobile mapping-om, laserskim
            trekingom, 3D modeliranjem, BIM-om itd.
          </p>
        </Col>
      </Row>
      <br></br>
      <Row>
        <Col className="tip-mobile" md={5}>
          <br></br>
          <div
            className="text-center"
            style={{ height: "auto", width: "100%" }}
          >
            <img
              className="text-center"
              style={{ height: "auto", width: "60%" }}
              src={tpi}
              alt="Kompanija TPI je geodetsko društvo sa ograničenom odgovornošću sa
              sedištem u Drajajhu, pokrajina Hesen. Osnovano je 2000. godine i ima
              više od 50 zaposlenih, od toga oko 40 geodetskih inženjera i
              techničara različitih nacionalnosti."
            ></img>
          </div>
        </Col>
        <Col md={7}>
          <p></p>
          <p style={{ textAlign: "justify" }}>
            Kompanija TPI je geodetsko društvo sa ograničenom odgovornošću sa
            sedištem u Drajajhu, pokrajina Hesen. Osnovano je 2000. godine i ima
            više od 50 zaposlenih, od toga oko 40 geodetskih inženjera i
            techničara različitih nacionalnosti. TPI nudi širok spektar usluga
            iz oblasti inženjerske i industrijske geodezije, terestričkog
            laserskog skeniranja, mobile mapping-a, fotogrametrije, UAV snimanja
            iz vazduha, 3D modeliranja, geodetskog veštačenja itd. TPI ima dobru
            saradnju sa visokim školama i univezitetima i podržava studente u
            procesu izrade bachelor i master radova.
          </p>
        </Col>
        <Col className="tip-desktop" md={5}>
          <br></br>
          <div
            className="text-center"
            style={{ height: "auto", width: "100%" }}
          >
            <img
              className="text-center"
              style={{ height: "auto", width: "60%" }}
              src={tpi}
              alt="Kompanija TPI je geodetsko društvo sa ograničenom odgovornošću sa
              sedištem u Drajajhu, pokrajina Hesen. Osnovano je 2000. godine i ima
              više od 50 zaposlenih, od toga oko 40 geodetskih inženjera i
              techničara različitih nacionalnosti."
            ></img>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default PredavanjeSkener;
