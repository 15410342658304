import React, {useEffect, useState} from 'react';
import {Container, Row, Col, Button, Alert, Spinner} from 'react-bootstrap';
import axios from 'axios';
import {url} from '../../constants/constants';
import {useParams} from 'react-router-dom';
import EditUserRequestModal from '../../components/EditUserRequestModal';

function AcceptUserScreen({ handleClick }) {

    const {id} = useParams();

    const [dataObj, setDataObj] = useState();
    const [error, setError] = useState();
    const [working, setWorking] = useState(false);
    const [show, setShow] = useState(false);

    useEffect(()=>{
        if(!dataObj){
            getData();
        }
    }, [])

    async function acceptUser(){
        const {data} = await axios.post(url + "api/acceptuser", {requestId: id}, {withCredentials: true});
        if(data){
            window.location.href = "/";
        } else{
            setError("Neuspešno prihvatanje korisnika.");
        }
    }

    async function deleteRequest(){
        const {data} = await axios.post(url + "api/deleteuserrequest", {requestId: id}, {withCredentials: true});
        if(data){
            window.location.href = "/";
        } else{
            setError("Neuspešno brisanje zahteva.");
        }
    }


    async function getData(){
        const {data} = await axios.get(url + 'api/getuserrequest?requestId=' + id, {withCredentials: true});
        setDataObj(data);
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <Container>
            {error && <Alert variant="danger">{error}</Alert>}
            {dataObj &&
                <div>
                    <Row>
                        <Row>
                            <Col md={4}>
                                <h5>Ime i prezime:</h5>
                            </Col>
                            <Col md={8}>
                                <p>{dataObj.name} {dataObj.fatherName} {dataObj.surname}</p>
                            </Col>
                        </Row>
                    </Row>
                    <Row>
                        <Row>
                            <Col md={4}>
                                    <h5>Datum  i mesto rođenja:</h5>
                            </Col>
                            <Col md={8}>
                                    <p>{dataObj.birthDate} {dataObj.birthCity}</p>
                                    
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                    <h5>Adresa i mesto stanovanja:</h5>
                            </Col>
                            <Col md={8}>
                                    <p>{dataObj.address} {dataObj.city}</p>
                                    
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                    <h5>Email:</h5>
                            </Col>
                            <Col md={8}>
                                    <p>{dataObj.email}</p>
                                    
                            </Col>
                        </Row> 
                        <Row>
                            <Col md={4}>
                                    <h5>Broj lične karte i mesto izdavanja:</h5>
                            </Col>
                            <Col md={8}>
                                    <p>{dataObj.idNumber} {dataObj.idCity}</p>
                                    
                            </Col>
                        </Row>       
                        <Row>
                            <Col md={4}>
                                    <h5>Broj telefona:</h5>
                            </Col>
                            <Col md={8}>
                                    <p>{dataObj.phone}</p>
                            </Col>
                        </Row> 
                        <Row>
                            <Col md={4}>
                                    <h5>Stepen stručne spreme:</h5>
                            </Col>
                            <Col md={8}>
                                    <p>{dataObj.profQualLevel}</p>
                                    
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                    <h5>Naziv kompanije/organizacije:</h5>
                            </Col>
                            <Col md={8}>
                                    <p>{dataObj.currPosition}</p>
                                    
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                    <h5>Opis radnog mesta (pozicija u kompaniji/organizaciji):</h5>
                            </Col>
                            <Col md={8}>
                                    <p>{dataObj.companyRole}</p>
                                    
                            </Col>
                        </Row>
                        <Row>
                            <Col md={2}>
                                <Button variant="success" onClick={()=>{acceptUser()}}>{working ? <Spinner animation="border" /> : "Prihvati zahtev" }</Button>
                            </Col>
                            <Col md={2}>
                                <Button variant="success" onClick={handleShow}>{working ? <Spinner animation="border" /> : "Izmeni zahtev"}</Button>
                            </Col>
                            <Col md={2}>
                                <Button variant="danger" onClick={()=>{deleteRequest()}}>{working ? <Spinner animation="border" /> : "Obriši zahtev"}</Button>
                            </Col>
                        </Row>
                    </Row>
                    <EditUserRequestModal show={show} onHide={handleClose} user={dataObj} />
                </div>  
            }
        </Container>
    )
}

export default AcceptUserScreen