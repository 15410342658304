import React, {useState, useEffect} from 'react';
import CustomTable from '../../components/CustomTable';
import {Button, Container} from 'react-bootstrap';
import axios from 'axios';
import {url} from '../../constants/constants';

function ShowUsersScreen() {
    
    const [users, setUsers] = useState();
    const [error, setError] = useState();
    const [userKeys, setUserKeys] = useState();
    const columns = ["Ime", "Ime oca", "Prezime", "Email", "Datum rođenja", "Mesto rođenja", "Adresa stanovanja", "Mesto stanovanja", "Broj lične karte", "Mesto izdavanja lk", "Broj teleofna", "Zvanje", "Radno mesto", "Pozicija", "Role"];
    async function getUsers(){
        try{
            const {data} = await axios.get(url + "api/getallusers", {withCredentials: true});
            
            let userList = [];
            
            for(let user of data){
                const userObj = {
                    name: user.name,
                    fatherName: user.fatherName,
                    surname: user.surname,
                    email: user.email,
                    birthDate: user.birthDate,
                    birthCity: user.birthCity,
                    address: user.address,
                    city: user.city,
                    idNumber: user.idNumber,
                    idCity: user.idCity,
                    phone: user.phone,
                    profQualLevel: user.profQualLevel,
                    currPosition: user.currPosition,
                    companyRole: user.companyRole,
                    role: user.role
                }
                userList.push(userObj);
            }

            
            if(userList.length > 0){
                setUserKeys(Object.keys(userList[0]));
            }

            setUsers(userList);
        } catch(err){
            setError(err.response.data);
        }
    }

    async function exportUsers(){
        //const {data} = await axios.get(url + "api/exportusers", {withCredentials: true});
        axios({
            method: 'get',
            url: url + "api/exportusers",
            responseType: 'blob',
            withCredentials: true
            })
            .then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', "users.csv");
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => {
                alert(error);
            });
    }

    useEffect(() => {
        if(!users){
            getUsers();
        }
    }, []);

    return (
        <Container>
            {users &&
                <div>
                    <Button variant="success" onClick={exportUsers} className="mb-3">Izvezi korisnike</Button>
                    <p className="pd-3">Broj članova: {users.length}</p>
                    <div style={{width: "100%", height: "75vh",overflow: "auto"}}>
                        <CustomTable columns={columns} data={users} keys={userKeys}/>
                    </div>
                </div>
            }
        </Container>
    )
}

export default ShowUsersScreen