import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import KatastarBihImage from "../../assets/img/news/katastar-bih/katastar_bih.webp";

function KatastarBiH() {
  return (
    <Container>
      <Row>
        <div style={{ textAlign: "center" }}>
          <Col md={12}>
            <h2>V. Kongres o katastru u Bosni i Hercegovini</h2>
          </Col>
        </div>
      </Row>
      <Row>
        <Col md={3}>
          <i>Savez geodeta Srbije</i>
          <br></br>
          <i>02.08.2023.</i>
        </Col>
        <Col md={6}></Col>
        <Col md={3}></Col>
      </Row>
      <hr></hr>
      <Row>
        <Col className="katastar" md={5}>
          <br></br>
          <div
            className="text-center"
            style={{ height: "auto", width: "100%" }}
          >
            <img
              className="text-center"
              style={{ height: "auto", width: "70%" }}
              src={KatastarBihImage}
              alt="Katastar Bosne i Hercegovine."
            ></img>
          </div>
        </Col>
        <Col md={7}>
          <p></p>
          <p style={{ textAlign: "justify" }}>
            Geodetsko društvo Herceg Bosne (GDHB) organizuje V. Kongres o
            katastru u Bosni i Hercegovini s međunarodnim učešćem. Kongres će se
            održati od 27. do 29. septembra u Neumu, u Grand hotelu "Neum", pod
            visokim pokroviteljstvom predsednice Federacije BiH Lidije Bradare.
            Na linku ispod možete naći raspored događaja na Kongresu, kao i sve
            druge korisne informacije potrebne učesnicima.
          </p>
          <a
            href="/assets/media/Druga_obavijest_5kongres_light.pdf"
            target="_blank"
          >
            V. Kongres o katastru u Bosni i Hercegovini
          </a>
        </Col>
      </Row>
    </Container>
  );
}

export default KatastarBiH;
