import React from 'react'
import { Button, Spinner } from 'react-bootstrap'

function PublicMessage(props) {
  return (
    <div>
        <div className="public-message-container">
        <small className="message-email">{props.firstName} {props.lastName}</small>
        <div className="message-container">
            <p className="message">
                {props.message}
            </p>
            {props.role === "admin" &&
              <div class="delete-button">
                <Button variant="outline-danger" size="sm" onClick={()=>props.deleteFunction(props.messageId)}>{props.working ? <Spinner animation="border" /> : <i class="bi bi-trash"></i>}</Button><br></br>
              </div>
            }
            <p class="date-time">
            {props.date} {props.time}
            </p>  
        </div>
      </div>
    </div>
  )
}

export default PublicMessage