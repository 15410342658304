import React, {useState, useEffect} from 'react'
import { Button, Container, Alert } from 'react-bootstrap'
import {useParams} from 'react-router-dom';
import Template from './Template'
import VotingOptions from './VotingOptions'
import axios from 'axios';
import {url} from '../constants/constants';
import ConfirmMailModal from './ConfirmMailModal';
import PollResults from './PollResults';
import "../assets/css/custom.css";

function Voting(props) {

    const [message, setMessage] = useState();
    const [poll, setPoll] = useState();
    const [error, setError] = useState();
    const [showModal, setShowModal] = useState(false)
    const [selected, setSelected] = useState();
    const [voted, setVoted] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [btnClass, setBtnClass] = useState("col-2");

    const params = useParams();

    async function checkVoted(){
        const {data} = await axios.post(url + "api/checkVoted/", {pollId: poll._id},{withCredentials: true});
        setVoted(data);
    }

    async function checkLogIn(){
        const {data} = await axios.get(url + 'api/isauth', {withCredentials: true})
        if(data == false && poll.availability !== "anketa"){
            window.location.href="/";
        } else if(data == false){
            setLoggedIn(false);
        } else{
            setLoggedIn(true);
        }

    }

    async function vote(){
        try{
            const {data} = await axios.post(url + 'api/vote', {pollId: params.id, vote: selected}, {withCredentials: true});
            setMessage("Glasanje je uspešno.");
            setVoted(true);
        } catch(err){
            setError(err.response.data);
        }
    }

    async function openConfirmModal(){
       const {data} = await axios.get(url + "api/getEmail", {withCredentials: true});

        const votes = poll.votes;

        for(let i = 0; i < poll.options.length; i++){
            if(votes[i].includes(data)){
                setError("Dozvoljeno je glasati samo jednom.");
                return;
            }
        }
        setShowModal(true);
    }


    async function getCurrentPoll(){
        try{
            const {data} = await axios.get(url + "api/getpoll?pollId=" + params.id, {withCredentials: true});
            setPoll(data);
            console.log(data.options)
        } catch(err){
            console.log(err);
            setError(err.response.data);
        }
    }

    useEffect(()=>{
        if(width < 900){
            setBtnClass("col-4");
        }
        if(!poll){
            getCurrentPoll();
        }
        if(poll){
            checkLogIn();
            checkVoted();
        }
    }, [poll])


    return (
        <div>
            {message && 
                <Alert variant="success">
                    {message}
                </Alert>
            }
            {error && 
                <Alert variant="danger">
                    {error}
                </Alert>
            }
            {poll && 
                <Container>
                    <div className="row" style={{textAlign: "center"}}>
                        <h3>{poll && poll.question}</h3>
                        <i>{poll.type == "one" ? "Bira se jedan odgovor." : "Bira se više odgovora."}</i>
                    </div>
                    <br></br>
                    <div className="row">
                        <div className="col-3"></div>
                        <div className="col-6">
                            {poll && 
                                <VotingOptions options={poll.options} type={poll.type} setSelected={setSelected}></VotingOptions>
                            }
                        </div>
                        <div className="col-3"></div>
                    </div>
                    <br></br>
                    <div className="row">
                        <div className={btnClass === "col-4" ? "col-4" : "col-5"}></div>
                        <div className={btnClass}>
                            <Button disabled={voted} onClick={()=> {if(poll.availability == "anketa"){vote()} else {openConfirmModal()}}} style={{backgroundColor: "#5cb875", borderColor: "#5cb875", width: "100%"}}>Glasaj</Button>
                        </div>
                        <div className={btnClass === "col-4" ? "col-4" : "col-5"}></div>
                    </div>
                    <div className="row">
                        <div className="col-4"></div>
                        <div className="col-4 rezultati">
                            {voted &&
                                <div style={{textAlign: "center"}}>
                                    <h4 style={{marginTop: 20}}>Rezultati: </h4>
                                    <PollResults pollId={params.id}></PollResults>
                                </div>
                            }
                        </div>
                        <div className="col-4"></div>
                    </div>
                
                    
                </Container>
            } 
            <ConfirmMailModal
                show={showModal}
                onHide={() => setShowModal(false)}
                success={() => vote()}
            ></ConfirmMailModal>
        </div>
    )
}

export default Voting
