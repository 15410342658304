import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import pojmovnik from "../../assets/img/news/sertifikacija/pojmovnik-licenca.webp";

export default function sertifikacija() {
  return (
    <Container>
      <Row>
        <div style={{ textAlign: "center" }}>
          <Col md={12}>
            <h2>
              Obaveštenje povodom odbijanja prodaje licenci za sertifikaciju -
              novi razvoj događaja
            </h2>
          </Col>
        </div>
      </Row>
      <Row>
        <Col md={3}>
          <i>Savez geodeta Srbije</i>
          <br></br>
          <i>19.07.2024.</i>
        </Col>
        <Col md={6}></Col>
        <Col md={3}></Col>
      </Row>
      <hr></hr>
      <Row>
        <Col md={2}></Col>
        <Col md={8}>
          <div
            className="text-center"
            style={{ height: "auto", width: "100%" }}
          >
            <img
              className="text-center"
              style={{ height: "auto", width: "100%" }}
              src={pojmovnik}
              alt="fajlovi"
            ></img>
          </div>
        </Col>
      </Row>
      <br></br>
      <Row>
        <Col md={2}></Col>
        <Col md={8}>
          <p style={{ textAlign: "justify" }}>Poštovane kolege,</p>
          <p style={{ textAlign: "justify" }}>
            Nakon obaveštenja i načinjenih zakonskih koraka Saveza geodeta
            Srbije u vezi odbijanja prodaje aktivacije VPN naloga (licenci)
            vezanih za sertifikaciju servisa digitalnog geodetskog elaborata,
            došlo je do novog razvoja događaja, te smatramo da je jako bitno da
            objektivno i tačno informišemo stručnu javnost o stvarima koje su od
            velikog značaja za našu struku.
          </p>
          <p style={{ textAlign: "justify" }}>
            Kolegama koji su odbijeni prilikom zahteva za kupovinu aktivacije
            VPN naloga za pristup resursima Republičkog geodetskog zavoda
            (dalje: RGZ) stiglo je izvinjenje od privatne firme koja jedina za
            te potrebe ima ugovor sa RGZ. Pored izvinjenja dobili su i
            uveravanja da će moći da kupe licence. Iz toga zaključujemo da je
            RGZ odlučio da prihvati naš poziv i stavi van snage tabelu sa
            spiskom i uputstvom da se licence prodaju samo određenim geodetskim
            organizacijama.
          </p>
          <p style={{ textAlign: "justify" }}>
            Ukoliko je to slučaj, pozdravljamo ovaj potez RGZ i izražavamo nadu
            da će u budućnosti sve geodetske organizacije koje ispunjavaju
            zakonske uslove moći da dobiju licence.
          </p>
          <p style={{ textAlign: "justify" }}>
            Napominjemo da nam je jako drago što je naše obaveštenje izazvalo
            veliku pažnju stručne javnosti i zahvaljujemo svim kolegama na
            podršci nakon toga. Mislimo da je to, uz pravne korake koje smo
            preduzeli, ključno doprinelo pozitivnom razrešenju ove cele
            sitaucije. Uveravamo vas da ćemo i dalje pratiti šta se dešava po
            ovom pitanju ali i svim drugim pitanjima vezanim za geodetsku
            struku, te da ćemo slično ovako upozoravati na loše pojave ali i
            hvaliti one dobre, jer je naša osnovna misija podizanje ugleda i
            statusa geodetske struke.
          </p>
        </Col>
        <Col md={2}></Col>
      </Row>
    </Container>
  );
}
