import React, { useState } from 'react';
import { Container, Button, Form, Row, Col } from 'react-bootstrap'
import {url, regex} from '../../constants/constants';
import axios from 'axios'
import {useParams} from 'react-router-dom';
import {Alert, Spinner} from 'react-bootstrap';

function ResetForgotPassword(props) {
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [error, setError] = useState()
    const params = useParams();
    const [working, setWorking] = useState(false);
    const [message, setMessage] = useState();

    async function checkPassword(){
        setWorking(true);
        if(!password){
            setError("Unesite šifru.");
            setWorking(false);
            return;
        }

        if(password !== confirmPassword){
            setError("Šifre se ne podudaraju.");
            setWorking(false);
            return;
        }

        try{
            const {data} = await axios.post(url + 'api/updateforgotpassword', {token: params.token, password: password});
            setMessage("Šifra je uspešno promenjena.")
            window.location.href = "/login"
        }catch(e){
            setError(e.response.data);
        }
        setWorking(false);

    }

    return (
        <Container>
            <Row>
            <Col md={3}></Col>
            <Col md={6}>
                <Form>
                    <Form.Group>
                        <h2>Promenite Šifru</h2>
                        <p></p>
                        <Form.Label>Šifra</Form.Label>
                        <Form.Control type="password" placeholder="Šifra" onChange={(e)=>{setPassword(e.target.value)}}/>
                        <p></p>
                        <Form.Label>Potvrdite Šifru</Form.Label>
                        <Form.Control type="password" placeholder="Potvrdite šifru" onChange={(e)=>{setConfirmPassword(e.target.value)}}/>
                    </Form.Group>
                    <br></br>
                    {error && 
                        <Alert variant="danger">
                            {error}
                        </Alert>
                    }
                    {message &&
                        <Alert variant="success">
                            {message}
                        </Alert>
                    }
                    <br></br>
                    <Button variant='success' onClick={checkPassword}>{
                        working ? <Spinner animation="border" /> : "Sačuvaj"
                    }</Button>
                </Form>
            </Col>
            <Col md={3}></Col>
            </Row>
        </Container>
    )
}

export default ResetForgotPassword
