import React from "react";
import { Row, Col, Container, Table } from "react-bootstrap";
import { Carousel } from "react-bootstrap";
import Slika1 from "../../assets/img/news/vest-neum/1.webp";
import Slika2 from "../../assets/img/news/vest-neum/2.webp";
import Slika3 from "../../assets/img/news/vest-neum/3.webp";
import Slika4 from "../../assets/img/news/vest-neum/4.webp";

function VestONeumu() {
  return (
    <Container>
      <Row>
        <div style={{ textAlign: "center" }}>
          <Col md={12}>
            <h2>
              Poseta godišnjoj skupštini Geodetskog društva Herceg-Bosne kao i
              Kongresa o katastru
            </h2>
          </Col>
        </div>
      </Row>
      <Row>
        <Col md={3}>
          <i>Savez geodeta Srbije</i>
          <br></br>
          <i>23.10.2023.</i>
        </Col>
        <Col md={6}></Col>
        <Col md={3}></Col>
      </Row>
      <hr></hr>
      <Row>
        <Col md={1}></Col>
        <Col md={10}>
          <div
            className="text-center"
            style={{ height: "auto", width: "100%" }}
          >
            <img
              className="text-center"
              style={{ height: "auto", width: "50%" }}
              src={Slika2}
            ></img>
          </div>
        </Col>
        <Col md={1}></Col>
      </Row>
      <br></br>
      <Row>
        <Col md={2}></Col>
        <Col md={8}>
          <p style={{ textAlign: "justify" }}>
            Na poziv rukovodstva Geodetskog društva Herceg Bosne predsednik
            Saveza geodeta Srbije dr Mladen Šoškić prisustvovao je Godišnjoj
            skupštini Geodetskog društva Herceg-Bosne kao i Kongresu o katastru
            u BiH od 27.9. do 29.9. 2023. godine u Neumu. Bila je to prilika za
            uspostavljanje novih i produbljivanje postojećih kontakata sa
            kolegama u regionu, imajući u vidu već tradicionalno dobre odnose
            SGS-a sa Geodetskim društvom Herceg Bosne. Ovom skupu prisustvoali
            su svi predstavnici geodetskih uprava u regionu (Hrvatska, Bih-oba
            entiteta, Crna Gora, Slovenija i Severna Makedonija), strukovnih
            udruženja, komora kao i delegacija Evropskog saveta geodeta (CLGE)
            na čelu sa predsednikom.
          </p>
          <p style={{ textAlign: "justify" }}>
            Zahvaljujući SGS-u i Srbija je bila predstavljena na ovom, za našu
            struku, veoma značajnom regionalnom skupu, budući da nije bilo
            drugih predstavnika iz naše zemlje. Regionalna i evropska saradnja
            je veoma značajna za razvoj geodetske struke u Srbiji, jer razmenom
            iskustava i međusobnim pomaganjem, možemo značajno unaprediti njen
            status i značaj. Načinjen je i prvi korak ka punopravnom članstvu
            SGS-a u Evropskom savetu geodeta (CLGE) kroz dogovor oko suštinskih
            i administrativnih koraka koji moraju biti preduzeti. Rukovodstvo
            SGS-a će u narednom periodu uložiti napor u cilju savladavanja tih
            koraka kako bi SGS postao punopravni član več sledeće godine.
          </p>
          <p style={{ textAlign: "justify" }}>
            Više o je Godišnjoj skupštini Geodetskog društva Herceg-Bosne kao i
            Kongresu o katastru u BiH na:
            <br></br>
            <a href="https://www.gdhb.ba/" target="_blank">
              https://www.gdhb.ba/
            </a>
          </p>
        </Col>
        <Col md={2}></Col>
      </Row>
      <br></br>
      <Row>
        <Col md={2}></Col>
        <Col md={8}>
          <Carousel>
            <Carousel.Item>
              <img className="d-block w-100" src={Slika1} alt="First slide" />
              <a
                className="carousel-control-prev"
                role="button"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon bi bi-chevron-left"
                  aria-hidden="true"
                />
              </a>
              <a
                className="carousel-control-next"
                role="button"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon bi bi-chevron-right"
                  aria-hidden="true"
                />
              </a>
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={Slika3} alt="Second slide" />
              <a
                className="carousel-control-prev"
                role="button"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon bi bi-chevron-left"
                  aria-hidden="true"
                />
              </a>
              <a
                className="carousel-control-next"
                role="button"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon bi bi-chevron-right"
                  aria-hidden="true"
                />
              </a>
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={Slika4} alt="Tird slide" />
              <a
                className="carousel-control-prev"
                role="button"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon bi bi-chevron-left"
                  aria-hidden="true"
                />
              </a>
              <a
                className="carousel-control-next"
                role="button"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon bi bi-chevron-right"
                  aria-hidden="true"
                />
              </a>
            </Carousel.Item>
          </Carousel>
        </Col>
        <Col md={2}></Col>
      </Row>
    </Container>
  );
}

export default VestONeumu;
