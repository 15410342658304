import React, {useEffect} from 'react';
import {Container, Row, Col} from 'react-bootstrap';

function Istorijat() {
    return (
        <Container>
            <Row>
                <Col md={4}></Col>
                <Col md={4}><h2>Istorijski razvoj</h2></Col>
                <Col md={4}></Col>
            </Row>
            <hr></hr>
            <Row>
                <Col md={3}>
                    <div className="text-center" style={{textAlign: "left"}}>
                        <img src="assets/img/Milan-Andonovic.png"></img>
                        <br></br>
                        <i>Milan Andonović</i>
                    </div>
                </Col>
                <Col md={9}>
                <p style={{textAlign: "justify"}}>
                        „Savez geodeta Srbije“ ima svoje korene u 19. veku i svoj početak može povezati sa osnivanjem „Tehničarske družine“, koja biva osnovana 3. februara 1868. godine. Ovo udruženje već 1890. godine prerasta u „Društvo srpskih ineženjera“, a 1896. menja naziv u „Društvo srpskih inženjera i arhitekata“, prvi predsednik ovog društva bio je akademik Milan Andonović, geodeta, građevinski inženjer i profesor „Univerziteta u Beogradu“.
                </p>
                <p style={{textAlign: "justify"}}>
                    Geodetski inženjeri i geometri obavljaju svoje funkcije u okviru „Društva inženjera i tehničara“ sve do 1951. godine kada se u okviru njega formira „Društvo geodetskih inženjera i geomatara Srbije“ koje 1960. godine prerasta u „Savez geodetskih inženjera i geometara Srbije“.
                    Ovaj savez se nalazio u okviru „Saveza geodetskih inženjera i geometara Jugoslavije“ (SGIGJ). U sastavu ove organizacije su bili organizovani republički i pokrajinski savezi svih saveznih republika i pokrajina: SGIG Srbije, SGIG Crne Gore, SGIG Bosne i Hercegovine, SGIG Makedonije, DG Hrvatske, ZG Slovenije, SGIG Vojvodine i SGIG Kosova i Metohije. Predsednici predsedništva SGIG Jugoslavije su u ovom periodu birani su iz pojedinih saveza geodeta sa republičkog ili pokrajinskog nivoa.
                    U Beogradu 18.06.1992. godine, u „Domu inženjera i tehničara Jugoslavije“ održana je XXIV sednica „Predsedništva Saveza geodetskih inženjera i geometara Jugoslavije“ na kojoj je odlučeno da savez zadrži kontniuitet iz prethodnog perioda, s’ tim što se njegova organizacija svela na: SGIG Srbije, SGIG Crne gore, SGIG Vojvodine i SGIG Kosova i Metohije. 
                </p>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <p style={{textAlign: "justify"}}>
                    Na zasedanju Skupštine Saveza u Velikoj Plani 2002. godine izvršene su promene u statutu organizacije, a sve u skladu sa promenama u društvu gde između ostalog dolazi do promene naziva u „Savez geodeta Srbije“, koje ostaje i dan danas. Skupština je bila sastavljena od predstavnika tj. delegata iz svih podružnica, Skupština je brojala 192 delegata iz 11 društava i 154 podružnice, gde je svaki delegat predstavljao 20 članova.
                    </p>
                </Col>
            </Row>
            <br></br>
            <Row>
                <Col md={4}></Col>
                <Col md={4}>
                    <div className="text-center" style={{textAlign: "left"}}>
                        <img src="assets/img/skupstina-1.png"></img>
                        <br></br>
                        Organizacija SGS od 2002. godine
                    </div>
                </Col>
                <Col md={4}></Col>
            </Row>
            <br></br>
            <Row>
                <Col md={12}>
                <p style={{textAlign: "justify"}}>
                    Raspadom državne zajednice Srbije i Crne gore 2006. godine prestaje da postoji „Savez geodetskih inženjera i geometara Srbije i Crne Gore“ te su iste godine izvršene izmene i dopune statuta „Saveza geodeta Srbije“. Ovaj oblik organizacije bez većih promena ostaje do danas.
                </p>
                </Col>
            </Row>
            <br></br>
            <Row>
                <Col md={4}></Col>
                <Col md={4}>
                    <div className="text-center" style={{textAlign: "left"}}>
                        <img src="assets/img/skupstina-2.png"></img>
                        <br></br>
                        Organizacija SGIG SCG (2004-2006)
                    </div>
                </Col>
                <Col md={4}></Col>
            </Row>
            <br></br>
            <Row>
                <Col md={12}>
                    <p style={{textAlign: "justify"}}>
                        Raspadom državne zajednice Srbije i Crne gore 2006. godine prestaje da postoji „Savez geodetskih inženjera i geometara Srbije i Crne Gore“ te su iste godine izvršene izmene i dopune statuta „Saveza geodeta Srbije“. Ovaj oblik organizacije bez većih promena ostaje do danas.
                    </p>
                </Col>
            </Row>
            
            
        </Container>
    )
}

export default Istorijat




/*

<Row>
                <Col md={4}></Col>
                <Col md={4}></Col>
                <Col md={4}></Col>
            </Row>


*/