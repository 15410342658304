import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import mladen from "../../assets/img/news/redovna-posteta-upravnog-odbora/mladen.webp";
import plaketa from "../../assets/img/news/redovna-posteta-upravnog-odbora/plaketa.jpg";
import upravniOdbor from "../../assets/img/news/redovna-posteta-upravnog-odbora/upravni-odbor.webp";

function RedovnaSednicaUpravnogOdboraScreen() {
    return (
        <Container>
            <Row>
                <div style={{ textAlign: "center" }}>
                    <Col md={12}>
                        <h2>Poseta Udruženju geodeta centralne Srbije</h2>
                    </Col>
                </div>
            </Row>
            <Row>
                <Col md={3}>
                    <i>Savez Geodeta Srbije</i>
                    <br></br>
                    <i>28.12.2022.</i>
                </Col>
                <Col md={6}></Col>
                <Col md={3}></Col>
            </Row>
            <hr></hr>
            <Row>
                <Col md={4}>
                    <div
                        className="text-center"
                        style={{ height: "auto", width: "100%" }}
                    >
                        <img
                            style={{ height: "auto", width: "100%" }}
                            src={mladen}
                            alt="mladen"
                        ></img>
                        <br></br>
                        <i></i>
                    </div>
                </Col>
                <Col md={8}>
                    <p style={{ textAlign: "justify" }}>
                        Predsednik Saveza geodeta Srbije dr Mladen Šoškić
                        prisustvovao je redovnoj sednici upravnog odbora
                        Udruženja geodeta centralne Srbije koja je održana
                        16.12.2022. godine u Kragujevcu. Pored redovnih tačaka
                        sednice, fokus je bio na razmatranju aktuelnih problema
                        geodetske struke i mogućim aktivnostima koje bi
                        doprinele njihovom rešavanju. Članovi upravnog odbora
                        izrazili su zadovoljstvo zbog prisustva predsednika
                        SGS-a i izneli mnogo korisnih predloga u cilju
                        intenzivnije saradnje i delovanja u cilju podizanja
                        ugleda i prepoznatljivosti naše struke.
                    </p>
                    <p style={{ textAlign: "justify" }}>
                        Udruženje geodeta centralne Srbije, na čelu sa Zoranom
                        Jovanovićem, je jedna od najaktivnijih članica SGS-a i
                        kao takva značajno doprinosi podizanju kapaciteta i
                        ugleda našeg Saveza u celini. Namera predsednika SGS-a
                        je da kroz posete i komunikaciju sa svim regionalnim
                        udruženjima dodatno podstakne članstvo na aktivnije
                        delovanje u korist celokupne struke.
                    </p>
                    <p style={{ textAlign: "justify" }}>
                        Na kraju sednice predsednik Udruženje geodeta centralne
                        Srbije Zoran Jovanović uručio je plaketu zahvalnosti
                        predsedniku SGS-a.
                    </p>
                    <div className="d-flex gap-2 flex-wrap justify-content-center">
                        <img
                            src={upravniOdbor}
                            style={{ maxHeight: "300px", maxWidth: "100%" }}
                            alt="upravni odbor"
                        ></img>
                        <img
                            src={plaketa}
                            style={{ maxHeight: "300px", maxWidth: "100%" }}
                            alt="plaketa"
                        ></img>
                    </div>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col md={12}></Col>
            </Row>
        </Container>
    );
}

export default RedovnaSednicaUpravnogOdboraScreen;
