import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Sastanak from "../../assets/img/news/sastanak-sa-ministrom/sastanak.jpeg";

function SastanakSaMinistromScreen() {
  return (
    <Container>
      <Row>
        <div style={{ textAlign: "center" }}>
          <Col md={12}>
            <h2>
              Sastanak rukovodstva SGS sa ministrom Goranom Vesićem povodom sadržaja geodetskog
              snimka za potrebe priključenja nelegalnih objekata na infrastrukturu
            </h2>
          </Col>
        </div>
      </Row>
      <Row>
        <Col md={3}>
          <i>Savez geodeta Srbije</i>
          <br></br>
          <i>15.09.2023.</i>
        </Col>
        <Col md={6}></Col>
        <Col md={3}></Col>
      </Row>
      <hr></hr>
      <Row>
        <Col className="katastar" md={5}>
          <br></br>
          <div className="text-center" style={{ height: "auto", width: "100%" }}>
            <img
              className="text-center"
              style={{ height: "auto", width: "100%" }}
              src={Sastanak}
              alt="Sastanak sa ministrom"
            ></img>
          </div>
        </Col>
        <Col md={7}>
          <p></p>
          <p style={{ textAlign: "justify" }}>
            Dana 14.9.2023. godine rukovodstvo Saveza odazvalo se pozivu ministra građevinarstva,
            saobraćaja i infrastrukture g. Gorana Vesića povodom aktulenih tema u oblasti geodetske
            struke u Srbiji. Glavna tema sastanka se odnosila na geodetski snimak za potrebe
            privremenog priključenja nelegalnih objekata na infrastrukturnu mrežu. Rukovodstvo
            Saveza uspelo je da obrazloži neophodnost izrade takvog snimka u procesu priključenja
            nelegalnih objekta na infrastrukturnu mrežu, čime se obezbeđuje, sa jedne strane
            kvalitet tog procesa, a sa druge novi poslovi za privatne geodetske firme.
          </p>
          <p style={{ textAlign: "justify" }}>
            Na sastanku je dogovoreno da Savez predloži formu i sadržaj tog dokumenta, pri čemu
            akcenat treba da bude na brzini izrade i efikasnosti. Svesni potrebe da ceo proces
            prijave građana za priključenje na infrastrukturnu mrežu bude što jednostavniji, brži i
            jeftiniji, stručnjaci Saveza su sačinili predlog forme i sadržaja geodetskog snimka za
            potrebe privremenog priključenja nelegalnih objekata na infrastrukturnu mrežu.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <p style={{ textAlign: "justify" }} className="mt-2">
            Pored ove teme razmatrane su i druge teme vezane sa geodetsku struku u Srbiji. Ministar,
            g. Vesić izrazio je spremnost da će aktivno podržati sve predloge Saveza koji doprinose
            razvoju naše struke i oblasti planiranja i izgradnje, a u korist celog društva i države.
            Načelno je dogovoreno da će Savez organizovati skup na kojem će učestvovati članovi
            Saveza i ministar, na kojem će biti razmatrane aktuelne teme iz naše struke.
          </p>
          <p style={{ textAlign: "justify" }}>
            Na predloženu formu i sadržaj geodetskog snimka za potrebe privremenog priključenja
            nelegalnih objekata na infrastrukturnu mrežu od strane Saveza ministarstvo
            građevinarstva, saobraćaja i infrastrukture je već dalo saglasnost svojim aktom od
            15.9.2023., čime predložena forma postaje zvanična i može da se koristi u postupku
            privremenog priključenja nelegalnih objekata na infrastrukturnu mrežu.
          </p>
          <p style={{ textAlign: "justify" }}>
            {" "}
            U prilogu možete preuzeti sadržaj i formu geodetskog snimka za potrebe privremenog
            priključenja nelegalnih objekata na infrastrukturnu mrežu, ugledni primer u pdf i dwg
            formatu, kao i predmetnu saglasnost ministarstva.
          </p>
          <a href="/assets/media/Prilog.zip" download>
            Prilog.zip
          </a>
        </Col>
      </Row>
    </Container>
  );
}

export default SastanakSaMinistromScreen;
