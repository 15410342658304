import React, {useState, useEffect} from 'react';
import {Alert, Button, Form, Container} from 'react-bootstrap';
import axios from 'axios';
import {url} from '../../constants/constants';

function Promote() {
    const [password, setPassword] = useState("");
    const [error, setError] = useState();

    async function checkLogIn(){
        const {data} = await axios.get(url + 'api/isauth', {withCredentials: true})
        if(data == false){
            window.location.href="/";
        }
    }

    async function promoteUser(){
        try{
            const {data} = await axios.post(url + "api/promote", {password: password}, {withCredentials: true});
            window.location.href = "/";
            return;
        } catch(err){
            setError(err.response.data);
            return;
        }
    }
    useEffect(()=>{
        checkLogIn();
    })

    return (
        <div>
            {error && 
                <Alert variant="danger">{error}</Alert>
            }
            <Container>
                <Form>
                    <div style={{display: "flex"}}>
                        <Form.Control type="password" placeholder="Promotion password" onChange={(e)=>setPassword(e.target.value)}></Form.Control>
                        <Button variant="success" onClick={()=>promoteUser()}>Send</Button>
                    </div>
                </Form>
            </Container>
        </div>
    )
}

export default Promote
