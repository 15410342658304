import {Button, Table} from 'react-bootstrap'
import '../assets/vendor/animate.css/animate.min.css'
import '../assets/vendor/select2/select2.min.css'
import '../assets/vendor/perfect-scrollbar/perfect-scrollbar.css'

export default function CustomTable(props){


	return(
		<Table striped bordered hover size="sm">
			<thead>
				<tr>
					{props.columns && props.columns.map((col, index) => {
						return(
							<th>{col}</th>
						)}
					)}
				</tr>
			</thead>
			<tbody>
				{props.data && props.data.map((data) => {
					return (
						<tr>
							{props.keys && props.keys.map((key, index) => {
								return(
									<td >{data[key]}</td>
								)}
							)}
						</tr>
					)}
				)}
			</tbody>
		</Table>
		)
}