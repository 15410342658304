import React, {useEffect} from 'react';
import { Row, Col } from 'react-bootstrap';
import Biography from '../components/Biography';
import mladenimg from '../assets/img/rukovodstvo/mladen-soskic.jpg';
import milutinimg from '../assets/img/rukovodstvo/milutin-pejovic-1.jpg';
import kostaimg from '../assets/img/rukovodstvo/kosta-img.jpg';

function RukovodstvoScreen() {
  return (
    <div>
        <div className="biographies">
            <Row>
                <Col md={6}>
                    <Biography name="Doc. dr Mladen Šoškić" position="dipl. geod. inž." title="Predsednik" img={mladenimg} text="Rođen je u Sarajevu 1976. godine. Diplomirao je na Gra­đevinskom fakultetu Univer­ziteta u Beogradu 2001. godine na Odseku za geodeziju, kao student generacije, a 2010. godine na istom fa­kul­tetu je odbranio i magistarsku tezu. Doktorsku disertaciju je odbranio na Građevinskom fakultetu u Beogradu 2016. godine. Od 2003. do 2010. godine bio je biran u zvanje asistenta pripravnika, od 2010. do 2016. godine obavljao je posao asistenta, da bi 2016. godine bio izabran u zvanje docenta na Građevinskom fakultetu Uni­ver­ziteta u Beogradu.
                    Od 2003. do 2016. godine radi na Katedri za geodeziju i geoinformatiku Građevinskog fakulteta kao saradnik na predmetima Katastar nepokretnosti 1, Katastar nepokretnosti 2, Uređenje zemljišne teritorije, Geodezija u prostornom planiranju i urbanizmu, Komasacija – osnovni kurs, Komasacija – napredni kurs, Procena vrednosti nepokretnosti, Geodezija u uređenju prostora, Projekat iz geodezije u urbanizmu i Projekat iz katastra nepokretnosti. Od 2017. godine postaje nastavnik na predmetima Katsatar nepokretnosti 2, Procena vrednosti nepokretnosti i Analiza ulaganja u nepokretnosti.
                    Pored rada u nastavi i na naučnim projektima, aktivan je i u stručnoj primeni stečenog znanja. Radio je na projektovanju i realizaciji radova u oblasti uređenja zemljišne teritorije komasacijom i to na više desetina komasacionih područja. Učestvovao je u brojnim projektima stručnog nadzora realizacije geodetsko tehničkih radova na uređenju zemljišne teritorije komasacijom. Angažovan je od strane međunardnih institucija (UN, GIZ) kao konsultant – lokalni ekspert, iz oblasti komasacije i katastra. Pored toga značajno je i njegovo angažovanje u oblastima projektovanja i primene GIS tehnologije u mnogim oblastima, prvenstveno kod izrade informacionih sistema za potrebe lokalnih samouprva  i katastra nepokretnosti. Učestvovao je i na mnogim projektima iz oblasti digitalne fotogrametrije. Angažovan je i u radnim grupama za izradu zakonske regulative iz oblasti komasacije i urbane komasacije pri ministarstvima Vlade Republike Srbije.
                    Objavio je preko 20 naučnih radova u domaćim i stra­nim časopisima i na domaćim, odnosno međunarodnim konferencijama. Govori i piše engleski jezik. Oženjen je i otac je dvoje dece."></Biography>
                </Col>
                <Col md={6}>
                    <Biography name="Dr Milutin M. Pejović" position="dipl. geod. inž" title="Potpredsednik" img={milutinimg} text="Rođen je u Vrbasu 30. marta 1983. godine. Osnovnu školu i gimnaziju, završio je u Vrbasu 1998, odnosno 2002. Iste godine, upisao je Građevinski fakultet, Odsek za geodeziju i geoinformatiku. Diplomirao je 2009. godine, čime je stekao zvanje diplomirani inženjer geodezije. Doktorske studije na Građevinskom fakultetu, Odsek za geodeziju i geoinformatiku je upisao 2009. godine. Doktorsku disertaciju odbranio je 2016. godine. 
                    Na Građevinskom fakultetu u Beogradu, na Odseku za geodeziju i geoinformatiku zaposlen je od 2010. godine, od kada  aktivno učestvuje u realizaciji nastave na predmetima iz grupacije Inženjerske geodezije. U zvanje docenta izabran je 2017. godine. Od 2021. godine angažovan je kao predavač i na predmetima novog studijskog programa Geoinformatika, u čijem formiranju je aktivno učestvovao.
                    U okviru svog naučno-istraživačkog rada posvećen je rešavanju problema prikupljanja, obrade i analize prostornih podataka, kao i na probleme modeliranja prostornih fenomena, primenom statističkih  i geostatističkih metoda, kao i metoda mašinskog učenja. Do sada je, kao autor ili koautor, objavio brojne naučno istraživačke i stručne radove u stranim i domaćim naučnim časopisima i međunarodnim naučnim konferencijama iz oblasti geo-nauka. U okviru stručne delatnosti, aktivno je učestvovao u izradi više od 50 stručnih studija i projekata, najviše iz oblasti Inženjerske geodezije. 
                    Trenutno obavlja funkciju upravnika Instituta za geodeziju i geoinformatiku na Građevinskom fakultetu u Beogradu. Član je Inženjerske komore Srbije.
                    Oženjen je suprugom Ksenijom, sa kojom ima jednog sina Vojina."></Biography>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                        <Biography name="Kosta Mirković" position="dipl. pravnik" title="Sekretar" img={kostaimg} text="Rođen 1953. godine. Završio Pravni fakultet 1978. godine i stekao zvanje diplomirani pravnik. Magistrirao na Pravnom fakultetu i stekao zvanje magistra prava. Položio pravosudni ispit 1992. godine pri Ministarstvu pravde.
                            Zaposlenje od 1978 godine. Rad u privredi od 1978 do 1994. godine, a od 1994 do 2018. godine rad u republičkim organima uprave (Republičkom geodetskom zavodu i Republičkoj direkciji za imovinu Republike Srbije).                         
                            U privredi radio na radno – pravnim, ugovornim, statusnim, stambenim imovinsko – pravnim, normativnim, opštim i finansijskim poslovima, poslovima zastupanja, planiranja, organizovanja, rukovođenja, kontrole i  koordinacije radom zaposlenih. Radio na radnim mestima izvršioca, rukovodioca sektora, pomoćnika direktora radne organizacije i direktora radne zajednice. 
                            Radio u Republičkom geodetskom zavodu od 1994 do 2012. godine na poslovima: planiranja, organizovanja i koordinacije radom zaposlenih u odseku, odeljenju, pravnom registraru i sektoru (uže unutrašnje jedinice); pružanju stručne pomoći zaposlenima u tim unutrašnjim jedinicama; rešavanju najsloženijih poslova iz nadležnosti užih unutrašnjih jedinica; pružanju stručne pomoći svim službama za katastar nepokretnosti na teritoriji Republike Srbije; brige o ljudskim resursima; upravljanju upisom stvarnih prava na nepokretnostima obezbeđenjem jednoobrazne primene propisa u cilju zakonitog i blagovremenog upisa prava na nepokretnostima u postupku izrade i održavanja katastra nepokretnosti; normativnm poslovima (izrada nacrta zakona, predloga uredbi, pravilnika, uputstava i instrukcija); javne nabavke dobara, robe i usluga; brige o bezbednosti i zdravlju zaposlenih na radu, održavanja poslovnih objekata i voznog parka.  Radio na radnim mestima: šefa odseka i načelnika odeljenja za pravne i opšte poslove, načelnika odeljenja za normativne poslove, rukovodica pravnog registrara i pomoćnika direktora.
                            Radio u Republičkoj direkciji za imovinu Republike Srbije 2013. do 2018. godine na radnom mestu načelnika odeljenja za upravljanje i raspolaganje imovinom u državnoj svojini stečenom u postupku stečaja. Obavljao poslove: 
                            planiranja, organizovanja i koordinacije radom odeljenja; pružanja stručne pomoći državnim službenicima u odeljenju; vršenja kontrole svih akata koje je pripremalo adeljenje radi provere zakonitosti upravljanja i raspolaganja državnom imovinom stečenom u postupku stečaja u postupku pred Vladom; predlaganja i definisanja odgovarajućih rešenja radi postupanja Direkcije vezano za sve oblike raspolaganja državnom imovinom i sprovođenja odgovarajućeg postupka; predlaganja i usklađivanja procedure u postupku izrade i zaključenja ugovora; pružanja stručnih saveta i neophodnih informacija organima državne uprave koji se odnose na sprovođenje odgovarajućeg postupka u vezi državne imovine; učestvovanja u radu međuresornih grupa i komisija radi rešavanja imovinsko-pravnih pitanja i sprovođenja imovinsko-pravnih postupaka po odluci Vlade; nadgledanja, predlaganja i usklađivanja procedura u postupku upravljanja, otuđenja ili davanja na korišćenje državne imovine stečene u postupku stečaja; upisa prava javne svojine R. Srbije na nepokretnostima stečenim po sili zakona.
                            Učestvovao u realizaciji Projekta za katastar nepokretnosti i upis prava u Srbiji, finansiranog iz sredstava Svetske banke, odnosno Međunarodnog udruženja za razvoj zaključenjem Sporazuma o kreditu  sa Srbijom i Crnom Gorom, koji je ratifikovala Skupština Srbije i Crne Gore Zakonom o ratifikaciji Sporazuma o kreditu za razvoj (''Službeni list SCG – Međunarodni ugovori'', br. 15/04).
                            Godine 2012, učestvovao u pisanju Monografije „175 godina geodetske delatnosti u Srbiji“ (1837-2012) i pisanju publikacije „20 godina Republičkog geodetskog zavoda“ (1992-2012).
                            Radio na ažuriranju jedinstvene evidencije o nepokretnoatima (svih vrsta nepokretnosti) u javnoj svojini Republike Srbije.
                            Poznaje rad na računaru (Word, MS Office, Excel, Internet i dr.).
                            Poseduje specijalizovana znanja iz više oblasti, i to: ljudskih resursa, stvarnih prava, kancelarijskog poslovanja, poslovnih protokola, statističke obrade podataka pomoću IT tehnologija, IT tehnologije i dr.                                                              
                            Govori nemački, srednji nivo, engleskim se služi.
                            Od 2018 godine penzioner.
                            Oženjen, supruga Dubravka Mirković, dipl.pravnik
                            Sin Nikola Mirković, dipl. geod. inž.">
                        </Biography>
                </Col>
                <Col md={6}></Col>
            </Row>
        </div>
    </div>
  )
}

export default RukovodstvoScreen