import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';

function SaopstenjeVestScreen() {
    return (
        <Container>
            <Row>
                <div style={{textAlign: "center"}}>
                    <Col md={12}>
                        <h2>Саопштење поводом ситуације настале нефункционисањем информационог система РГЗ-а</h2>
                    </Col>
                </div>
            </Row>
            <Row>
                <Col md={3}>
                    <i>Савез геодета Србије</i>
                    <br></br>
                    <i>08.7.2022.</i>
                </Col>
                <Col md={6}></Col>
                <Col md={3}></Col>
            </Row>
            <hr></hr>
            <Row>
                <Col md={5}>
                <br></br>
                    <div className="text-center" style = {{height: "auto", width: "100%"}}>
                        <img style = {{height: "auto", width: "100%"}} src="../assets/img/saopstenje.jpg"></img>
                        <br></br>
                        <i></i>
                    </div>
                </Col>
                <Col md={7}>
                    <p style={{textAlign: "justify"}}>
                    Савез геодета Србије као струковна организација геодета жели да скрене пажњу јавности и одговарајућим државним институцијама на последице проистекле из пада информационог система РГЗ-а и нефункционисања одговарајућих сервиса. 
                    </p>
                    <p style={{textAlign: "justify"}}>
                    Желимо да истакнемо чињеницу да много геодетских организација зависи од поменутих сервиса и да им је њиховим нефунционисањем директно спречено право на рад. То за последицу има и немогућност пословног функционисања стотина мањих и већих геодетских организација које запошљавају велики број људи. Не треба додатно напомињати да је тиме угрожена како егзистенција великог броја наших колега, тако и добар део геодетске инфраструктуре на којој почива и сам катастарски систем Србије. Подсећамо да је катастарски систем један од најважнијих система и представља део суверенитета једне државе. У Србији, део тог система представљају и геодетске организације које обаваљају значајан део посла без кога катастар не би могао да функционише.
                    </p>                    
                    <p style={{textAlign: "justify"}}>
                    Не желећи да улазимо у узроке ситуације која је настала, јер верујемо да ће надлежни државни органи детаљно испитати све и побринути се да се у будућности не дешавају сличне ситуације, желимо да будемо конструктивни и нађемо начин како да последице не буду погубне за нашу струку, а самим тим и државу Србију. 
                    </p>
                </Col>
                <Col md={12}>
                    <p style={{textAlign: "justify"}}>
                    Апелујемо на Републички геодетски завод, Владу Републике Србије а нарочито на Министарство финансија и Министарство грађевинарства, саобраћаја и инфраструктуре да пронађу модел помоћи геодетским организацијама које су највише погођене новонасталом ситуацијом. Држава Србија је у време пандемије ковида учинила много на помоћи свима, привреди и грађанима јер смо се сви заједно нашли у ситуацији која је, не нашом кривицом већ вишом силом, погодила целокупну државу, грађане и привреду. Ова ситуација, иако не толико погубна за целокупно друштво, за нашу струку прети да изазове већу штету него што је пандемија изазвала у било којој другој области. Зато апелујемо да се, на сличан начин као што је то држава Србија радила у пандемији, помогне геодетским организацијама које су погођене.
                    </p>
                    <p style={{textAlign: "justify"}}>
                    Поред тога, тражимо од РГЗ-а да благовремено обавештава стручну јавност и геодетске организације о динамици враћања у рад појединих сервиса. Мислимо да досадашња пракса од стране РГЗ-а, да не пружа никава обавештења ни информације о раду њихових сервиса својим клијентима, мора да се промени.
                    Савез геодета Србије стоји на располагању свим државним институцијама да заједнички пронађемо модел да, у што је већој мери могуће, ублажимо последице.
                    </p>
                    <p style={{textAlign: "right"}}>
                        др Младен Шошкић, дипл. инж. геод.,
                    </p>
                    <p style={{textAlign: "right"}}>
                        Председник
                    </p>
                    <div style={{textAlign: "right"}}>
                        <img id="predsednik-potpis" src="/assets/img/predsednik-potpis.png" style = {{height: "auto", width: "15%"}}></img>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default SaopstenjeVestScreen