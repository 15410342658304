import axios from "axios";
import React, { useEffect, useState } from "react";
import "../assets/css/custom.css";
import "../assets/css/style.css";
import Logo from "../assets/img/logo.png";
import "../assets/vendor/animate.css/animate.min.css";
import "../assets/vendor/bootstrap-icons/bootstrap-icons.css";
import "../assets/vendor/bootstrap/css/bootstrap.min.css";
import "../assets/vendor/boxicons/css/boxicons.min.css";
import "../assets/vendor/glightbox/css/glightbox.min.css";
import "../assets/vendor/swiper/swiper-bundle.min.css";
import { url } from "../constants/constants";

function Header() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [email, setEmail] = useState();
  const [role, setRole] = useState();

  async function checkLogIn() {
    const { data } = await axios.get(url + "api/isauth", {
      withCredentials: true,
    });
    const roleReq = await axios.get(url + "api/getRole", {
      withCredentials: true,
    });
    if (data != false) {
      setEmail(data);
      setIsLoggedIn(true);
      setRole(roleReq.data);
    } else {
      setIsLoggedIn(data);
      setEmail();
    }
  }

  async function logout() {
    try {
      const { data } = await axios.post(
        url + "api/logout",
        {},
        { withCredentials: true }
      );
      window.location.href = "/";
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    checkLogIn();
  });

  return (
    <div>
      {/* ======= Top Bar ======= */}
      <section id="topbar" className="d-flex align-items-center">
        <div className="container d-flex justify-content-center justify-content-md-between">
          <div className="contact-info d-flex align-items-center">
            <i className="bi bi-envelope-fill" />
            <a href="mailto:office@savezgeodeta.rs">office@savezgeodeta.rs</a>
          </div>
          <div className="social-links d-none d-md-block">
            <a
              href="https://www.facebook.com/savezgeodetasrbije/"
              target="_blank"
              className="facebook"
              rel="noreferrer"
            >
              <i className="bi bi-facebook" />
            </a>
            <a
              href="https://www.instagram.com/savezgeodeta/"
              target="_blank"
              className="instagram"
              rel="noreferrer"
            >
              <i className="bi bi-instagram" />
            </a>
            <a
              href="https://www.linkedin.com/company/savez-geodeta-srbije/"
              target="_blank"
              className="linkedin"
              rel="noreferrer"
            >
              <i className="bi bi-linkedin" />
            </a>
          </div>
        </div>
      </section>
      {/* ======= Header ======= */}
      <header id="header" className="d-flex align-items-center">
        <div className="container d-flex align-items-center">
          {/*<h1 className="logo me-auto"><a href="index.html">Green</a></h1>*/}
          {/* Uncomment below if you prefer to use an image logo */}
          <a href="/" className="logo me-auto">
            <img src={Logo} alt="" className="img-fluid" />
          </a>
          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <a className="nav-link scrollto active" href="/">
                  Početna
                </a>
              </li>
              <li className="dropdown">
                <a href="/istorijat">
                  <span>O nama</span> <i className="bi bi-chevron-down"></i>
                </a>
                <ul>
                  <li>
                    <a href="/istorijat">Istorijat</a>
                  </li>
                  <li>
                    <a href="/medjunarodna-saradnja">Međunarodna saradnja</a>
                  </li>
                  <li>
                    <a href="/izdavastvo">Izdavaštvo</a>
                  </li>
                  <li>
                    <a href="/rukovodstvo">Rukovodstvo</a>
                  </li>
                </ul>
              </li>
              <li>
                <a className="nav-link scrollto" href="/aktuelnosti">
                  Aktuelnosti
                </a>
              </li>
              <li>
                <a className="nav-link scrollto " href="/#clients">
                  Članovi
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="/ankete">
                  Ankete
                </a>
              </li>
              {isLoggedIn && (
                <li>
                  <a className="nav-link scrollto" href="/glasanje">
                    Glasanje
                  </a>
                </li>
              )}
              <li>
                <a className="nav-link scrollto" href="/knjiga-utisaka">
                  Knjiga utisaka
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="/#contact">
                  Kontakt
                </a>
              </li>
              {!isLoggedIn && (
                <li>
                  <a className="nav-link" href="/login">
                    Prijavi se
                  </a>
                </li>
              )}
              {!isLoggedIn && (
                <li>
                  <a className="nav-link" href="/pristup">
                    Pristup SGS-u
                  </a>
                </li>
              )}
              {isLoggedIn && email && (
                <li className="dropdown" style={{ cursor: "pointer" }}>
                  <a>
                    <span>{email}</span> <i className="bi bi-chevron-down"></i>
                  </a>
                  <ul>
                    {role == "admin" && (
                      <li>
                        <a className="nav-link scrollto" href="/users">
                          Lista korisnika
                        </a>
                      </li>
                    )}
                    <li>
                      <a
                        className="nav-link scrollto"
                        onClick={() => {
                          logout();
                        }}
                      >
                        Odjavi se
                      </a>
                    </li>
                  </ul>
                </li>
              )}
            </ul>
            <i className="bi bi-list mobile-nav-toggle" />
          </nav>
          {/* .navbar */}
        </div>
      </header>
      {/* End Header */}
    </div>
  );
}

export default Header;
