import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';

function VestZaSajt() {
    return (
        <Container>
            <Row>
                <div style={{textAlign: "center"}}>
                    <Col md={12}>
                        <h2>Ponovo sa Vama, na novoj adresi</h2>
                    </Col>
                </div>
            </Row>
            <Row>
                <Col md={3}>
                    <i>Savez Geodeta Srbije</i>
                    <br></br>
                    <i>10.01.2022.</i>
                </Col>
                <Col md={6}></Col>
                <Col md={3}></Col>
            </Row>
            <hr></hr>
            <Row>
            <Col md={4}>
                    <div className="text-center" style={{textAlign: "left"}}>
                        <img src="assets/img/savamavest.jpg"></img>
                        <br></br>
                        <i></i>
                    </div>
                </Col>
                <Col md={8}>
                    <p style={{textAlign: "justify"}}>
                    Usled definisanog plana omasovljavanja članstva, firma Geotaur je izradila inicijalni sajt Saveza geodeta Srbije, na kome će se vršiti prezentovanje rada Saveza i promocija geodetske struke. Izradi samog sajta prethodilo je konfigurisanje VPS servera, registracija domena, instaliranje i osposobljavanje mejl servera. Članovi Saveza kao i javnost mogu putem kontakt platforme na sajtu ili preko email-a (info@savezgeodeta.rs) direktno da kontaktiraju rukovodstvo sa bilo kakvim pitanjima vezanim za funkcionisanje Saveza ili učlanjenjem u isti. Na sajtu su definisani nivoi pristupa, tako da članovi mogu da učestvuju u funkcionisanju samog Saveza značajno efektivnije, dok je javnosti omogućen uvid u konačne odluke, ciljeve i ideje samog Saveza. Ova stranica će služiti kao zvanična prezentacija i glasnik Saveza, te će na njemu biti prikazane najnovije aktuelnosti vezane za njega. Pored toga na sajtu je omogućeno definisanje javnih anketa čije je učestvovanje i pristup omogućen svima, dok je za registrovane korisnike tj. članove skupštine kreirana i implementirana aplikacija za glasanje i direktno učestvovanje u donošenju odluka koje se tiču poslovanja, ali i inicijative koja je proistekla iz samog Saveza. Cilj ove aplikacije je da podstakne članove i omogući aktivnije učestvovanje u donošenju odluka. Bitno je napomenuti da će svako glasanje biti vremenski ograničeno i da je moguće glasati jedino uz verifikaciju. 
                    </p>
                </Col>
            </Row>
        </Container>
    )
}

export default VestZaSajt
