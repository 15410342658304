import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {url} from '../../constants/constants';
import {ListGroup, Container, Button} from 'react-bootstrap';
import CreatePollModal from '../../components/CreatePollModal';

function PollsScreen() {

    const [polls, setPolls] = useState();
    const [showModal, setShowModal] = useState(false);
    const [role, setRole] = useState("user");

    /*async function checkLogIn(){
        const {data} = await axios.get(url + 'api/isauth', {withCredentials: true})
        if(data == false){
            window.location.href="/";
        }
    }*/

    async function getRole(){
        const {data} = await axios.get(url + 'api/getrole', {withCredentials: true});
        console.log(data);
        setRole(data);
    }

    async function getPolls(){
        const {data} = await axios.get(url + "api/getPolls", {withCredentials: true});
        let validPolls = []; 

        for(let i = 0; i < data.length; i++){
            if(data[i].availability == "anketa"){
                validPolls.push(data[i]);
            }
        }
        setPolls(validPolls);
    }

    async function removePoll(index){
        const pollId = polls[index]._id;
        try{
            const {data} = await axios.post(url + "api/removepoll/", {pollId: pollId},{withCredentials: true});
            polls.pop(pollId);
            setPolls(polls);
            window.location.reload();
        } catch(e){
            console.log(e);
        }
    }

    useEffect(()=> {
        getPolls();
        getRole();
    }, []);

    return (
        <div>
            <Container>
                <h1>Aktivne ankete:</h1>
                {polls && polls.length == 0 &&
                    <h5>Nema aktivnih anketa</h5>
                }

                {polls &&
                    
                    <ListGroup>
                        {polls.map((poll, index) => {
                            return(
                            <div style={{display: "flex"}}>
                                <ListGroup.Item action href={"/poll/" + poll._id}>{poll.question}</ListGroup.Item>
                                {role === "admin" && 
                                <Button variant="danger" style={{marginLeft: 5}} onClick={()=>removePoll(index)}>X</Button>
                                }
                            </div>
                            );
                        })}
                    </ListGroup>
                }
                <br></br>
                {role === "admin" &&
                    <Button variant="success" onClick={()=>setShowModal(true)}>Napravi novo glasanje</Button>
                }
                <CreatePollModal
                show={showModal}
                onHide={() => setShowModal(false)}
            ></CreatePollModal>
            </Container>
        </div>
    )
}

export default PollsScreen
