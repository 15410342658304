import react, { useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Link, Route, Routes, useParams } from "react-router-dom";
import "./App.css";
import "./assets/css/custom.css";
import "./assets/css/newsPriview.css";
import "./assets/css/rukovdstvo.css";
import "./assets/css/style.css";
import "./assets/vendor/animate.css/animate.min.css";
import "./assets/vendor/bootstrap-icons/bootstrap-icons.css";
import "./assets/vendor/bootstrap/css/bootstrap.min.css";
import "./assets/vendor/boxicons/css/boxicons.min.css";
import "./assets/vendor/glightbox/css/glightbox.min.css";
import "./assets/vendor/swiper/swiper-bundle.min.css";
import logo from "./logo.svg";

import DownloadComponent from "./components/DownloadComponent";
import Login from "./components/Login";
import Template from "./components/Template";
import Voting from "./components/Voting";
import ClanoviScreen from "./screens/ClanoviScreen";
import HomeScreen from "./screens/HomeScreen";
import IstorijatScreen from "./screens/Istorijat";
import IzdavastvoScreen from "./screens/IzdavastvoScreen";
import MedjunarodnaSaradnjaScreen from "./screens/MedjunarodnaSaradnjaScreen";
import PublicMessagesScreen from "./screens/PublicMessagesScreen";
import RukovodstvoScreen from "./screens/RukovodstvoScreen";
import TermsofUse from "./screens/TermsofUse";
import ClanstvoEuKomisiji from "./screens/news/ClanstvoEuKomisiji";
import KatastarBiH from "./screens/news/KatastarBiH";
import Memorandum from "./screens/news/Memorandum";
import NewsListScreen from "./screens/news/NewsListScreen";
import NewsScreen from "./screens/news/NewsScreen";
import ObavestenjePovodomSertifikacije from "./screens/news/ObavestenjePovodomSertifikacije";
import ObavestenjeRGZRadionice from "./screens/news/ObavestenjeRGZRadionice";
import OdbijanjeProdajeLicenci from "./screens/news/OdbijanjeProdajeLicenci";
import PosetaGeodetskihInzenjeraScreen from "./screens/news/PosetaGeodetskihInzenjeraScreen";
import PredavanjeGNSS from "./screens/news/PredavanjeGNSS";
import PredavanjeKartografija from "./screens/news/PredavanjeKartografija";
import PredavanjeSkener from "./screens/news/PredavanjeSkener";
import PredavanjeVestScreen from "./screens/news/PredavanjeVestScreen";
import PredsednikScreen from "./screens/news/PredsednikScreen";
import PrisustvoSekretara from "./screens/news/PrisustvoSekretara";
import ProgramSabora2023 from "./screens/news/ProgramSabora2023";
import RedovnaSednicaUpravnogOdboraScreen from "./screens/news/RedovnaSednicaUpravnogOdboraScreen";
import Rekovac2023 from "./screens/news/Rekovac2023";
import RukovodstvoVest from "./screens/news/RukovodstvoVest";
import Sabor2023Screen from "./screens/news/Sabor2023Screen";
import Sabor35 from "./screens/news/Sabor35";
import SaborGeodetaScreen from "./screens/news/SaborGeodetaScreen";
import SaborGeodetaXXXIIIScreen from "./screens/news/SaborGeodetaXXXIIIScreen";
import SaborKopaonik from "./screens/news/SaborKopaonik";
import SaopstenjeVestScreen from "./screens/news/SaopstenjeVestScreen";
import SastanakSaMinistromScreen from "./screens/news/SastanakSaMinistromScreen";
import Sertifikacija from "./screens/news/Sertifikacija.js";
import SkopljePredavanje from "./screens/news/SkopljePredavanje";
import Trebinje2023 from "./screens/news/Trebinje2023";
import VestONeumu from "./screens/news/VestONeumu";
import VestOPorecu from "./screens/news/VestOPorecu";
import VestSaSabora from "./screens/news/VestSaSabora";
import VestZaSajt from "./screens/news/VestZaSajt";
import ZakonOPIScreen from "./screens/news/ZakonOPIScreen";
import AnketeScreen from "./screens/polls/AnketeScreen";
import PollsScreen from "./screens/polls/PollsScreen";
import AcceptUserScreen from "./screens/user/AcceptUserScreen";
import ForgotPassword from "./screens/user/ForgotPassword";
import Promote from "./screens/user/Promote";
import RequestAccountScreen from "./screens/user/RequestAccountScreen";
import ResetForgotPassword from "./screens/user/ResetForgotPassword";
import ShowUsersScreen from "./screens/user/ShowUsersScreen";

function App() {
  let scriptElements = [];
  const scripts = [
    "./assets/vendor/glightbox/js/glightbox.min.js",
    "./assets/vendor/isotope-layout/isotope.pkgd.min.js",
    "./assets/vendor/swiper/swiper-bundle.min.js",
    "./assets/vendor/php-email-form/validate.js",
  ];
  return (
    <div>
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route
          path="/login"
          element={
            <Template
              path={["Home", "Prijava"]}
              links={["/"]}
              title="Prijava"
              components={[<Login></Login>]}
            />
          }
        />
        <Route
          path="/glasanje"
          element={
            <Template
              path={["Home", "Glasanje"]}
              links={["/"]}
              title="Glasanje"
              components={[<PollsScreen></PollsScreen>]}
            />
          }
        ></Route>
        <Route
          path="/poll/:id"
          element={
            <Template
              path={["Home", "Odluka"]}
              links={["/"]}
              title="Odluka"
              components={[<Voting params={useParams()}></Voting>]}
            />
          }
        ></Route>
        <Route
          path="/promote"
          element={
            <Template
              path={["Promote"]}
              links={[]}
              title="Promote"
              components={[<Promote></Promote>]}
            ></Template>
          }
        ></Route>
        <Route
          path="/forgotpassword"
          element={
            <Template
              path={["Home", "Log In", "Zaboravljena Šifra"]}
              links={["/", "/login"]}
              title="Zaboravljena Šifra"
              components={[<ForgotPassword></ForgotPassword>]}
            ></Template>
          }
        ></Route>
        <Route
          path="/forgotpassword/:token"
          element={
            <Template
              path={["Resetovanje zaboravljene Šifre"]}
              links={[]}
              title="Resetovanje zaboravljene Šifre"
              components={[<ResetForgotPassword></ResetForgotPassword>]}
            ></Template>
          }
        ></Route>
        <Route
          path="/aktuelnosti/sastanak"
          element={
            <Template
              path={["Home", "Aktuelnost"]}
              links={["/"]}
              title="Aktuelnost"
              components={[<NewsScreen></NewsScreen>]}
            ></Template>
          }
        ></Route>
        <Route
          path="/istorijat"
          element={
            <Template
              path={["Home", "Istorijat"]}
              links={["/"]}
              title="Istorijat"
              components={[<IstorijatScreen></IstorijatScreen>]}
            ></Template>
          }
        ></Route>
        <Route
          path="/medjunarodna-saradnja"
          element={
            <Template
              path={["Home", "Međunarodna saradnja"]}
              links={["/"]}
              title="Međunarodna saradnja"
              components={[
                <MedjunarodnaSaradnjaScreen></MedjunarodnaSaradnjaScreen>,
              ]}
            ></Template>
          }
        ></Route>
        <Route
          path="/izdavastvo"
          element={
            <Template
              path={["Home", "Izdavaštvo"]}
              links={["/"]}
              title="Izdavaštvo"
              components={[<IzdavastvoScreen></IzdavastvoScreen>]}
            ></Template>
          }
        ></Route>
        <Route
          path="/clanovi"
          element={
            <Template
              path={["Home", "Članovi"]}
              links={["/"]}
              title="Članovi"
              components={[<ClanoviScreen></ClanoviScreen>]}
            ></Template>
          }
        ></Route>
        <Route
          path="/uslovi-koriscenja"
          element={
            <Template
              path={["Home", "Uslovi korišćenja"]}
              links={["/"]}
              title="Uslovi korišćenja"
              components={[<TermsofUse></TermsofUse>]}
            ></Template>
          }
        ></Route>
        <Route
          path="/aktuelnosti"
          element={
            <Template
              path={["Home", "Aktuelnosti"]}
              links={["/"]}
              title="Aktuelnosti"
              components={[<NewsListScreen></NewsListScreen>]}
            ></Template>
          }
        ></Route>
        <Route
          path="/ankete"
          element={
            <Template
              path={["Home", "Ankete"]}
              links={["/"]}
              title="Ankete"
              components={[<AnketeScreen></AnketeScreen>]}
            ></Template>
          }
        ></Route>
        <Route
          path="/vest-sajt"
          element={
            <Template
              path={["Home", "Aktuelnosti", "Ponovo sa Vama"]}
              links={["/", "/aktuelnosti"]}
              title="Aktuelnost"
              components={[<VestZaSajt></VestZaSajt>]}
            ></Template>
          }
        ></Route>
        <Route
          path="/pristupnica"
          element={
            <DownloadComponent
              link="/SGS_Pristupnica.doc"
              name="SGS_Pristupnica.doc"
            ></DownloadComponent>
          }
        ></Route>
        <Route
          path="/aktuelnosti/novo-rukovodstvo"
          element={
            <Template
              path={["Home", "Aktuelnosti"]}
              links={["/"]}
              title="Novo rukovodstvo"
              components={[<RukovodstvoVest></RukovodstvoVest>]}
            ></Template>
          }
        ></Route>
        <Route
          path="/rukovodstvo"
          element={
            <Template
              path={["Home", "Rukovodstvo"]}
              links={["/"]}
              title="Rukovodstvo"
              components={[<RukovodstvoScreen></RukovodstvoScreen>]}
            ></Template>
          }
        ></Route>
        <Route
          path="/pristup"
          element={
            <Template
              path={["Home", "Pristup"]}
              links={["/"]}
              title="Pristup"
              components={[<RequestAccountScreen></RequestAccountScreen>]}
            ></Template>
          }
        ></Route>
        <Route
          path="/acceptuser/:id"
          element={
            <Template
              path={["Home", "Accept User"]}
              links={["/"]}
              title="Accept User"
              components={[
                <AcceptUserScreen params={useParams()}></AcceptUserScreen>,
              ]}
            ></Template>
          }
        ></Route>
        <Route
          path="/aktuelnosti/predsednik"
          element={
            <Template
              path={["Home", "Aktuelnosti"]}
              links={["/", "/aktuelnosti"]}
              title="Obraćanje predsednika"
              components={[<PredsednikScreen></PredsednikScreen>]}
            ></Template>
          }
        ></Route>
        <Route
          path="/users"
          element={
            <Template
              path={["Home", "Korisnici"]}
              links={["/"]}
              title="Lista korisnika"
              components={[<ShowUsersScreen></ShowUsersScreen>]}
            ></Template>
          }
        ></Route>
        <Route
          path="/knjiga-utisaka"
          element={
            <Template
              path={["Home", "Knjiga utisaka"]}
              links={["/"]}
              title="Knjiga utisaka"
              components={[<PublicMessagesScreen></PublicMessagesScreen>]}
            ></Template>
          }
        ></Route>
        <Route
          path="/aktuelnosti/prvo-predavanje"
          element={
            <Template
              path={["Home", "Aktuelnosti"]}
              links={["/"]}
              title="Prvo predavanje"
              components={[<PredavanjeVestScreen></PredavanjeVestScreen>]}
            ></Template>
          }
        ></Route>
        <Route
          path="/aktuelnosti/saopstenje"
          element={
            <Template
              path={["Home", "Aktuelnosti"]}
              links={["/"]}
              title="Саопштење"
              components={[<SaopstenjeVestScreen></SaopstenjeVestScreen>]}
            ></Template>
          }
        ></Route>
        <Route
          path="/aktuelnosti/sabor-geodeta"
          element={
            <Template
              path={["Home", "Aktuelnosti"]}
              links={["/"]}
              title="XXXIII Сабор Геодета"
              components={[<SaborGeodetaScreen></SaborGeodetaScreen>]}
            ></Template>
          }
        ></Route>
        <Route
          path="/aktuelnosti/sabor-geodeta-XXXIII"
          element={
            <Template
              path={["Home", "Aktuelnosti"]}
              links={["/"]}
              title="XXXIII Сабор Геодета"
              components={[
                <SaborGeodetaXXXIIIScreen></SaborGeodetaXXXIIIScreen>,
              ]}
            ></Template>
          }
        ></Route>
        <Route
          path="/aktuelnosti/poseta-geodetskih-inzenjera"
          element={
            <Template
              path={["Home", "Aktuelnosti"]}
              links={["/"]}
              title=""
              components={[
                <PosetaGeodetskihInzenjeraScreen></PosetaGeodetskihInzenjeraScreen>,
              ]}
            ></Template>
          }
        ></Route>
        <Route
          path="/aktuelnosti/sabor-geodeta-srbije-2022"
          element={
            <Template
              path={["Home", "Aktuelnosti"]}
              links={["/"]}
              title="Sabor geodeta Srbije 2022."
              components={[<VestSaSabora></VestSaSabora>]}
            ></Template>
          }
        ></Route>
        <Route
          path="/aktuelnosti/poseta-udruzenju-geodeta-centralne-srbije"
          element={
            <Template
              path={["Home", "Aktuelnosti"]}
              links={["/"]}
              title="Poseta Udruženju geodeta centralne Srbije"
              components={[
                <RedovnaSednicaUpravnogOdboraScreen></RedovnaSednicaUpravnogOdboraScreen>,
              ]}
            ></Template>
          }
        ></Route>
        <Route
          path="/aktuelnosti/zakon-o-pi"
          element={
            <Template
              path={["Home", "Aktuelnosti"]}
              links={["/"]}
              title="Zakon O PI"
              components={[<ZakonOPIScreen></ZakonOPIScreen>]}
            ></Template>
          }
        ></Route>
        <Route
          path="/aktuelnosti/prisustvo-sekretara"
          element={
            <Template
              path={["Home", "Aktuelnosti"]}
              links={["/"]}
              title="Sekretar Saveza geodeta Srbije biće prisutan u prostorijama Saveza"
              components={[<PrisustvoSekretara></PrisustvoSekretara>]}
            ></Template>
          }
        ></Route>
        <Route
          path="/aktuelnosti/predavanje-skener"
          element={
            <Template
              path={["Home", "Aktuelnosti"]}
              links={["/"]}
              title="Stručno predavanje na temu Terestrički laserski skener i BIM"
              components={[<PredavanjeSkener></PredavanjeSkener>]}
            ></Template>
          }
        ></Route>
        <Route
          path="/aktuelnosti/predavanje-gnss"
          element={
            <Template
              path={["Home", "Aktuelnosti"]}
              links={["/"]}
              title="GNSS u geodeziji – tehnologija i primene"
              components={[<PredavanjeGNSS></PredavanjeGNSS>]}
            ></Template>
          }
        ></Route>
        <Route
          path="/aktuelnosti/sabor-2023"
          element={
            <Template
              path={["Home", "Aktuelnosti"]}
              links={["/"]}
              title="XXXIV Sabor Geodeta Srbije"
              components={[<Sabor2023Screen></Sabor2023Screen>]}
            ></Template>
          }
        ></Route>
        <Route
          path="/aktuelnosti/sabor-35"
          element={
            <Template
              path={["Home", "Aktuelnosti"]}
              links={["/"]}
              title="XXXV Sabor Geodeta Srbije"
              components={[<Sabor35></Sabor35>]}
            ></Template>
          }
        ></Route>
        <Route
          path="/aktuelnosti/katastar-bih"
          element={
            <Template
              path={["Home", "Aktuelnosti"]}
              links={["/"]}
              title="V. Kongres o katastru u Bosni i Hercegovini"
              components={[<KatastarBiH></KatastarBiH>]}
            ></Template>
          }
        ></Route>
        <Route
          path="/aktuelnosti/program-sabora-2023"
          element={
            <Template
              path={["Home", "Aktuelnosti"]}
              links={["/"]}
              title="XXXIV Sabor Geodeta Srbije"
              components={[<ProgramSabora2023></ProgramSabora2023>]}
            ></Template>
          }
        ></Route>
        <Route
          path="/aktuelnosti/sastanak-sa-ministrom"
          element={
            <Template
              path={["Home", "Aktuelnosti"]}
              links={["/"]}
              title="Sastanak rukovodstva SGS sa ministrom Goranom Vesićem"
              components={[
                <SastanakSaMinistromScreen></SastanakSaMinistromScreen>,
              ]}
            ></Template>
          }
        ></Route>
        <Route
          path="/aktuelnosti/sabor-kopaonik"
          element={
            <Template
              path={["Home", "Aktuelnosti"]}
              links={["/"]}
              title="ODRŽAN XXXIV SABOR GEODETA SRBIJE"
              components={[<SaborKopaonik></SaborKopaonik>]}
            ></Template>
          }
        ></Route>
        <Route
          path="/aktuelnosti/memorandum"
          element={
            <Template
              path={["Home", "Aktuelnosti"]}
              links={["/"]}
              title="Memorandum o međusobnoj saradnji"
              components={[<Memorandum></Memorandum>]}
            ></Template>
          }
        ></Route>
        <Route
          path="/aktuelnosti/vest-o-neumu"
          element={
            <Template
              path={["Home", "Aktuelnosti"]}
              links={["/"]}
              title="Poseta godišnjoj skupštini Geodetskog društva Herceg-Bosne kao i Kongresa o katastru"
              components={[<VestONeumu></VestONeumu>]}
            ></Template>
          }
        ></Route>
        <Route
          path="/aktuelnosti/rekovac"
          element={
            <Template
              path={["Home", "Aktuelnosti"]}
              links={["/"]}
              title="22. Sabor geodeta centralne Srbije - Priznanja za saradnju i uspeh"
              components={[<Rekovac2023></Rekovac2023>]}
            ></Template>
          }
        ></Route>

        <Route
          path="/aktuelnosti/poseta-simpoziju-ovlašćenih-inženjera-geodezije"
          element={
            <Template
              path={["Home", "Aktuelnosti"]}
              links={["/"]}
              title="Poseta 16. simpoziju ovlašćenih inženjera geodezije"
              components={[<VestOPorecu></VestOPorecu>]}
            ></Template>
          }
        ></Route>

        <Route
          path="/aktuelnosti/obavestenje-rgz-radionice"
          element={
            <Template
              path={["Home", "Aktuelnosti"]}
              links={["/"]}
              title="Obavestenje RGZ radionice"
              components={[<ObavestenjeRGZRadionice></ObavestenjeRGZRadionice>]}
            ></Template>
          }
        ></Route>
        <Route
          path="/aktuelnosti/trebinje-2023"
          element={
            <Template
              path={["Home", "Aktuelnosti"]}
              links={["/"]}
              title="Sporazum o Saradnji Geodetskih Udruženja Srbije i Republike Srpske"
              components={[<Trebinje2023></Trebinje2023>]}
            ></Template>
          }
        ></Route>
        <Route
          path="/aktuelnosti/obavestenje-povodom-sertifikacije"
          element={
            <Template
              path={["Home", "Aktuelnosti"]}
              links={["/"]}
              title="Obaveštenje povodom sertifikacije"
              components={[
                <ObavestenjePovodomSertifikacije></ObavestenjePovodomSertifikacije>,
              ]}
            ></Template>
          }
        ></Route>
        <Route
          path="/aktuelnosti/skoplje-predavanje"
          element={
            <Template
              path={["Home", "Aktuelnosti"]}
              links={["/"]}
              title="Predavanje u inženjerskoj komori Severne Makedonije"
              components={[<SkopljePredavanje></SkopljePredavanje>]}
            ></Template>
          }
        ></Route>
        <Route
          path="/aktuelnosti/clanstvo-eu-komisije"
          element={
            <Template
              path={["Home", "Aktuelnosti"]}
              links={["/"]}
              title="Članstvo u evropskoj komisiji geodeta (CLGE)"
              components={[<ClanstvoEuKomisiji></ClanstvoEuKomisiji>]}
            ></Template>
          }
        ></Route>
        <Route
          path="/aktuelnosti/kartografija-predavanje"
          element={
            <Template
              path={["Home", "Aktuelnosti"]}
              links={["/"]}
              title="Stručno predavanje na temu Web Kartografija: Od tradicionalnih karata do Web kartografskih aplikacija"
              components={[<PredavanjeKartografija></PredavanjeKartografija>]}
            ></Template>
          }
        ></Route>
        <Route
          path="/aktuelnosti/odbijanje-prodaje-licenci"
          element={
            <Template
              path={["Home", "Aktuelnosti"]}
              links={["/"]}
              title="Obaveštenje povodom odbijanja prodaje licenci za sertifikaciju"
              components={[<OdbijanjeProdajeLicenci></OdbijanjeProdajeLicenci>]}
            ></Template>
          }
        ></Route>
        <Route
          path="/aktuelnosti/sertifikacija"
          element={
            <Template
              path={["Home", "Aktuelnosti"]}
              links={["/"]}
              title="Obaveštenje povodom odbijanja prodaje licenci za sertifikaciju - novi razvoj događaja"
              components={[<Sertifikacija></Sertifikacija>]}
            ></Template>
          }
        ></Route>
      </Routes>
    </div>
  );
}

export default App;
