import React, {useEffect} from 'react';
import {Container, Row, Col} from 'react-bootstrap';

function IzdavastvoScreen() {
    return (
        <Container>
            <Row>
                <Col md={4}></Col>
                <Col md={4}><h2>Izdavačka delatnost</h2></Col>
                <Col md={4}></Col>
            </Row>
            <hr></hr>
            <Row>
                <Col md={12}>
                    <p style={{textAlign: "justify"}}>
                    Savez geodeta Srbije je i izdavač časopisa i zbornika radova. Časopis Saveza geodeta Srbije pod nazivom “Geodetski žurnal“ izlazi od 2003. godine i bavi se temama vezanim za geodetsku struku, ali i za sve struke koje su na neki način vezane za geodeziju i svim onim što geodezija obuhvata. Ustaljene tematske celine časopisa su aktivnosti, zakonodavstvo, događaji vezani za geodetsku struku, stručno-naučni radovi, intervjui sa kolegama, zanimljivosti i kultura. Jedan od ciljeva jeste ponovno oživljavanje izdavačke delatnosti Saveza.
                    </p>
                </Col>
            </Row>
        </Container>
    )
}

export default IzdavastvoScreen
