import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';

function PredavanjeVestScreen() {
    return (
        <Container>
            <Row>
                <div style={{textAlign: "center"}}>
                    <Col md={12}>
                        <h2>Prvo predavanje u organizaciji novog rukovodstva Saveza geodeta Srbije</h2>
                    </Col>
                </div>
            </Row>
            <Row>
                <Col md={3}>
                    <i>Savez geodeta Srbije</i>
                    <br></br>
                    <i>22.06.2022.</i>
                </Col>
                <Col md={6}></Col>
                <Col md={3}></Col>
            </Row>
            <hr></hr>
            <Row>
            <Col md={5}>
                    <div className="text-center" style = {{height: "auto", width: "100%"}}>
                        <img style = {{height: "auto", width: "100%"}} src="../assets/img/predavanja/sleganje-tla.jpg"></img>
                        <br></br>
                        <i></i>
                    </div>
                </Col>
                <Col md={7}>
                    <p style={{textAlign: "justify"}}>
                    Želite da naučite nešto novo i unapredite svoje poslovanje? Želite mogućnost da o aktuelnim temama razgovarate sa ekspertima iz raznih oblasti geodezije?
                    </p>
                    <p style={{textAlign: "justify"}}>
                    Savez geodeta Srbije organizuje prvo predavanje od početka rada Saveza pod novim rukovodstvom. Pozivaju se svi inženjeri i tehničari, pojedinci i organizacije, sadašnji i budući članovi udruženja da prisustvuju događaju koji će se održati u petak, 1. jula, u 13h, u zgradi Saveza inženjera i tehničara Srbije (Kneza Miloša 7a, Beograd, sprat 3).
                    </p>                    
                    <p style={{textAlign: "justify"}}>
                    Planirano obraćanje stručnoj javnosti ima za cilj predstavljanje i približavanje ideja i načina rada Saveza postojećim i novim članovima, kroz agendu koja obuhvata:
                        <ul>
                            <li>Predstavljanje Saveza - dr Mladen Šoškić dipl. inž. geod.</li>
                            <li>I predavanje <i>"Geodetsko osmatranje sleganja objekata i tla"</i> - dr Milutin Pejović, dipl. geod. inž.</li>
                            <li>II predavanje <i>"Primena UAV fotogrametrije u geodetskom premeru"</i> - Petar Bursać, dipl. geod. inž.</li>
                        </ul>
                    </p>
                    <p style={{textAlign: "justify"}}>
                    Dobrodošli ste da učestvujete u razradi ideja i aktivnosti koje Savez planira u svrhu pružanja podrške svojim članovima.
                    </p>
                </Col>
            </Row>
        </Container>
    )
}

export default PredavanjeVestScreen