import React from "react";
import { Carousel, Col, Container, Row } from "react-bootstrap";
import Slika1 from "../../assets/img/news/trebinje-2023/1.webp";
import Slika2 from "../../assets/img/news/trebinje-2023/2.webp";
import Slika3 from "../../assets/img/news/trebinje-2023/3.webp";
import Slika4 from "../../assets/img/news/trebinje-2023/4.webp";
import Slika5 from "../../assets/img/news/trebinje-2023/5.webp";
import Slika6 from "../../assets/img/news/trebinje-2023/6.webp";
import Slika7 from "../../assets/img/news/trebinje-2023/7.webp";

function Trebinje2023() {
  return (
    <Container>
      <Row>
        <div style={{ textAlign: "center" }}>
          <Col md={12}>
            <h2>
              Sporazum o Saradnji Geodetskih Udruženja Srbije i Republike Srpske
            </h2>
          </Col>
        </div>
      </Row>
      <Row>
        <Col md={3}>
          <i>Savez geodeta Srbije</i>
          <br></br>
          <i>12.12.2023.</i>
        </Col>
        <Col md={6}></Col>
        <Col md={3}></Col>
      </Row>
      <hr></hr>
      <Row>
        <Col md={1}></Col>
        <Col md={10}>
          <div
            className="text-center"
            style={{ height: "auto", width: "100%" }}
          >
            <img
              className="text-center"
              style={{ height: "auto", width: "50%" }}
              src={Slika6}
            ></img>
          </div>
        </Col>
        <Col md={1}></Col>
      </Row>
      <br></br>
      <Row>
        <Col md={2}></Col>
        <Col md={8}>
          <p style={{ textAlign: "justify" }}>
            Od 1.12. do 3.12. u Trebinju je održana skupština Društva geodetskih
            inženjera i geometara Republike Srpske gde je kao gost ovog bratskog
            udruženja prisustvovao predsednik Saveza geodeta Srbije, dr Mladen
            Šoškić. Ovom prilikom potpisan je i Sporazum o saradnji između naša
            dva udruženja kojim se, ionako bliske veze, produbljuju.
          </p>
          <p style={{ textAlign: "justify" }}>
            Veliko hvala domaćinima, Tatjani Sarajlić (predsednica Društva) i
            Draganu Stankoviću (direktor Uprave) na gostoprimstvu i
            konstruktivnim razgovororima o mogućnostima bliže saradnje na
            budućim projektima.
          </p>
          <p style={{ textAlign: "justify" }}>
            Bilo je jako korisno prisustvovati ovom izuzetno dobro organizovanom
            skupu i iz prve ruke videti kako to izgleda kada svi relevantni
            učesnici (državna uprava, strukovno udruženje, privatnici, školstvo)
            međusobno razgovaraju, polemišu i trude se da odgovore svim
            izazovima naše struke. Sigurno da i mi možemo mnogo toga da naučimo
            od naše braće preko Drine jer je jedino takva saradnja put ka
            uspehu, od kojeg će koristi imati svi, a najviše naša struka i
            država u celini.
          </p>
          <p style={{ textAlign: "justify" }}>
            Nakon protokola potpisivanja date su izjave za medije koje možete
            videti na:
          </p>
          <a
            href="http://www.srna.rs/novost/1156981/%D0%BF%D0%BE%D0%B4%D0%B8%D1%9B%D0%B8-%D1%83%D0%B3%D0%BB%D0%B5%D0%B4-%D0%B3%D0%B5%D0%BE%D0%B4%D0%B5%D1%82%D1%81%D0%BA%D0%B5-%D1%81%D1%82%D1%80%D1%83%D0%BA%D0%B5"
            target="_blannk"
            style={{ cursor: "pointer" }}
          >
            SRNA - Novinska Agencija Republike Srpske
          </a>
          <br></br>
          <a
            href="https://lat.rtrs.tv/vijesti/vijest.php?id=536185"
            target="_blank"
            style={{ cursor: "pointer" }}
          >
            RTRS
          </a>
        </Col>
        <Col md={2}></Col>
      </Row>
      <br></br>
      <Row>
        <Col md={2}></Col>
        <Col md={8}>
          <Carousel>
            <Carousel.Item>
              <img className="d-block w-100" src={Slika1} alt="First slide" />
              <a
                className="carousel-control-prev"
                role="button"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon bi bi-chevron-left"
                  aria-hidden="true"
                />
              </a>
              <a
                className="carousel-control-next"
                role="button"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon bi bi-chevron-right"
                  aria-hidden="true"
                />
              </a>
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={Slika2} alt="Second slide" />
              <a
                className="carousel-control-prev"
                role="button"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon bi bi-chevron-left"
                  aria-hidden="true"
                />
              </a>
              <a
                className="carousel-control-next"
                role="button"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon bi bi-chevron-right"
                  aria-hidden="true"
                />
              </a>
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={Slika3} alt="Tird slide" />
              <a
                className="carousel-control-prev"
                role="button"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon bi bi-chevron-left"
                  aria-hidden="true"
                />
              </a>
              <a
                className="carousel-control-next"
                role="button"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon bi bi-chevron-right"
                  aria-hidden="true"
                />
              </a>
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={Slika4} alt="Tird slide" />
              <a
                className="carousel-control-prev"
                role="button"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon bi bi-chevron-left"
                  aria-hidden="true"
                />
              </a>
              <a
                className="carousel-control-next"
                role="button"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon bi bi-chevron-right"
                  aria-hidden="true"
                />
              </a>
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={Slika5} alt="Tird slide" />
              <a
                className="carousel-control-prev"
                role="button"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon bi bi-chevron-left"
                  aria-hidden="true"
                />
              </a>
              <a
                className="carousel-control-next"
                role="button"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon bi bi-chevron-right"
                  aria-hidden="true"
                />
              </a>
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={Slika7} alt="Tird slide" />
              <a
                className="carousel-control-prev"
                role="button"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon bi bi-chevron-left"
                  aria-hidden="true"
                />
              </a>
              <a
                className="carousel-control-next"
                role="button"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon bi bi-chevron-right"
                  aria-hidden="true"
                />
              </a>
            </Carousel.Item>
          </Carousel>
        </Col>
        <Col md={2}></Col>
      </Row>
    </Container>
  );
}

export default Trebinje2023;
