import React from 'react'
import { Row } from 'react-bootstrap';

function TermsofUse() {
    return (
        <div>
            <h1>Uslovi korišćenja website-a</h1>
            <hr></hr>
            <br></br>
            <Row>
                <h2>Uslov</h2>
                <p>Pristupanjem ovoj veb stranici pristajete da vas obavezuju Uslovi korišćenja, važeći zakoni i propisi i njihova usklađenost. Ako se ne slažete sa bilo kojim od navedenih uslova i odredbi, zabranjeno vam je korišćenje ili pristup ovoj veb lokaciji. Materijali sadržani na ovoj veb lokaciji zaštićeni su odgovarajućim zakonom o autorskim pravima i žigovima.</p>
            </Row>
            <br></br>
            <Row>
                <h2>Koristite Licencu</h2>
                <ol>
                    <li>
                        <p>a. Dozvoljeno je privremeno preuzimanje jednog duplikata materijala (podataka ili programa) na veb sajtu Savez geodeta Srbije, samo za individualnu i neposlovnu upotrebu. Ovo je dozvola za korišćenje licence, a ne razmena vlasništva i prema ovoj dozvoli ne smete:</p>
                        <ol>
                            <li>modifikovati ili kopirati materijale;</li>
                            <li>koristiti materijale za bilo koju komercijalnu upotrebu, ili za bilo koju javnu prezentaciju (poslovnu ili van poslovanja);</li>
                            <li>pokušaj dekompilacije ili obnove bilo kog proizvoda ili materijala koji se nalaze na veb sajtu Savez geodeta Srbije;</li>
                            <li>ukloniti bilo koju autorsku ili drugu restriktivnu dokumentaciju iz materijala;</li>
                            <li>ili da prenesete materijale nekome drugom ili ih čak "prekopirate" na drugom serveru.</li>
                        </ol>
                    </li>
                    <li>Ova dozvola može posledično biti ukinuta ako zanemarite bilo koje od ovih ograničenja i Savez geodeta Srbije može ukinuti kad god to bude smatrao. Nakon ukidanja dozvole ili kada vam prestane dozvola za gledanje, morate uništiti sve preuzete materijale u vašem vlasništvu, bilo u elektronskom ili štampanom obliku.</li>
                </ol>
            </Row>
            <br></br>
            <Row>
                <h2>Izjava o odricanju odgovornosti</h2>
                <ol>
                    <li>Materijali na veb sajtu Savez geodeta Srbije dati su „takvi kakvi jesu“. Savez geodeta Srbije ne daje nikakve garancije, saopštava ih ili sugeriše, te se na taj način odriče i poništava sve pojedine garancije, uključujući nesmetano, izvedene garancije ili stanja prodajnosti, podobnosti za određeni razlog ili neovlašćavanje licence ili drugu povredu prava. Dalje, Savez geodeta Srbije ne garantuje niti daje bilo kakve izjave u vezi sa preciznošću, verovatnim rezultatima ili nepokolebljivim kvalitetom upotrebe materijala na svojoj Internet stranici ili se uopšte identifikuje sa takvim materijalima ili na bilo kojim odredištima povezanim sa ovim veb sajtom.</li>
                </ol>
            </Row>
            <br></br>
            <Row>
                <h2>Ograničenja</h2>
                <p>Savez geodeta Srbije ili njegovi dobavljači ni u jednom slučaju ne bi trebalo da podležu bilo kakvoj šteti (računajući, bez ograničenja, šteti zbog gubitka podataka ili koristi ili zbog poslovnog ometanja), koja bi proizašla iz upotrebe ili nemoći korišćenja materijala na Internet stranici Savez geodeta Srbije , bez obzira na mogućnost da je Savez geodeta Srbije ili agentu odobrenom od Savez geodeta Srbije usmeno ili pismeno saopšteno o verovatnoći takve štete. Budući da nekoliko delokruga ne dozvoljava ograničenja na pretpostavljene garancije ili prepreke obavezivanja za teške ili slučajne štete, ova ograničenja možda neće praviti razliku za vas.</p>
            </Row>
            <br></br>
            <Row>
                <h2>Izmene i dopune</h2>
                <p>Materijali koji se prikazuju na veb sajtu Savez geodeta Srbije mogu sadržati tipografske ili fotografske greške. Savez geodeta Srbije ne garantuje da je bilo koji materijal na njegovom veb sajtu tačan, završen ili aktualan. Savez geodeta Srbije može izvršiti poboljšanja materijala sadržanih na svom veb sajtu kad god to bude potrebno bez obaveštenja. Savez geodeta Srbije se, opet, nije obavezao da će ažurirati materijale.</p>
            </Row>
            <br></br>
            <Row>
                <h2>Linkovi</h2>
                <p>Savez geodeta Srbije nije proverio većinu veb sajtova ili linkova povezanih sa njegovim veb sajtom i nije odgovoran za suštinu bilo koje takve povezane veb stranice. Uključivanje bilo koje veze ne podrazumeva podršku veb sajta Savez geodeta Srbije. Korišćenje bilo koje takve povezane lokacije je na sopstveni rizik korisnika.</p>
            </Row>
            <br></br>
            <Row>
                <h2>Izmene uslova korišćenja veb sajta</h2>
                <p>Savez geodeta Srbije može ažurirati ove uslove korišćenja za svoju veb stranicu kad god to bude potrebno bez obaveštenja. Korišćenjem ove stranice pristajete da vas obavezuju trenutni oblici ovih Uslova i odredbi korišćenja.</p>
            </Row>
            <br></br>
            <Row>
                <h2>Merodavno pravo</h2>
                <p>Bilo koji slučaj koji se identifikuje sa veb sajtom Savez geodeta Srbije treba da bude regulisan zakonima države Srbije Savez geodeta Srbije, bez poštovanja odredbi zakona.Opšti uslovi koji se primenjuju na upotrebu veb stranice.</p>
                <p>General Terms and Conditions applicable to Use of a Web Site.</p>
            </Row>
            <br></br>
            <Row>
                <h2>Pravila o privatnosti</h2>
                <p>Vaša privatnost je presudna za nas. Isto tako, ovu politiku smo izgradili sa krajnjim ciljem da vidite kako prikupljamo, koristimo, prenosimo i otkrivamo i koristimo pojedinačne podatke. Sledeći su nacrti naše politike privatnosti:</p>
                <ul>
                    <li>Pre ili u vreme prikupljanja ličnih podataka, identifikovaćemo svrhe u koje se informacije prikupljaju.</li>
                    <li>Prikupljaćemo i koristimo individualne podatke pojedinačno sa ciljem da udovoljimo onim razlozima koje smo naveli i u druge dobre svrhe, osim ako ne dobijemo saglasnost dotične osobe ili ako to zahteva zakon.</li>
                    <li>Samo ćemo držati individualne podatke u dužini neophodnoj za zadovoljenje tih razloga.</li>
                    <li>Individualne podatke prikupljaćemo legalnim i razumnim sredstvima i, prema potrebi, informacijama ili pristankom dotičnog pojedinca.</li>
                    <li>Lični podaci bi trebalo da budu važni zbog razloga zbog kojih se koriste i, u meri koja je bitna iz tih razloga, trebalo bi da budu tačni, dovršeni i ažurirani.</li>
                    <li>Zaštitićemo pojedinačne podatke merama bezbednosti od nesreće ili provale, kao i od neodobrenog pristupa, otkrivanja, umnožavanja, upotrebe ili promene.</li>
                    <li>Klijentima ćemo odmah omogućiti pristup našim smernicama i procedurama za upravljanje pojedinačnim podacima</li>
                    <br></br>
                    <p>Fokusirani smo na vođenje našeg poslovanja prema ovim standardima sa određenim krajnjim ciljem da garantujemo da je privatnost pojedinačnih podataka sigurna i održavana.</p>
                </ul>
            </Row>
        </div>
    )
}

export default TermsofUse
