import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import bulat from "../../assets/img/news/predavanja-gnss/bulat.jpeg";
import ZoranNedeljkovic from "../../assets/img/news/predavanja-gnss/zoran-nedeljkovic.jpeg";

function PredavanjeGNSS() {
  return (
    <Container>
      <Row>
        <div style={{ textAlign: "center" }}>
          <Col md={12}>
            <h2>GNSS u geodeziji – tehnologija i primene</h2>
          </Col>
        </div>
      </Row>
      <Row>
        <Col md={3}>
          <i>Savez geodeta Srbije</i>
          <br></br>
          <i>8.6.2023. u 15 časova.</i>
        </Col>
        <Col md={6}></Col>
        <Col md={3}></Col>
      </Row>
      <hr></hr>
      <Row>
        <Col md={6}>
          <br></br>
          <div
            className="text-center"
            style={{ height: "auto", width: "100%" }}
          >
            <img
              className="text-center"
              style={{ height: "auto", width: "40%" }}
              src={bulat}
            ></img>
            <p>
              Prof. dr Vladimir Bulatović,<br></br> dipl.geod.inž.
            </p>
            <p>
              Univerzitet u Novom Sadu,<br></br> Fakultet tehničkih nauka;
              GeoSolutions (CEO).
            </p>
          </div>
        </Col>
        <Col md={6}>
          <div
            className="text-center"
            style={{ height: "auto", width: "100%" }}
          >
            <img
              className="text-center"
              style={{ height: "auto", width: "40%" }}
              src={ZoranNedeljkovic}
            ></img>
            <p>
              Zoran Nedeljković,<br></br> dipl. geod.inž.
            </p>
            <p>Atlas Sistem doo (CEO)</p>
          </div>
        </Col>
      </Row>
      <br></br>
      <Row>
        <h3>Opis:</h3>
        <div className="text-justify">
          <p>
            Motiv za organizaciju ovog predavanja je svakako velika primena GNSS
            tehnologije u geodeziji. GNSS je svojom pojavom napravio pravu
            revoluciju u svetu Geodezije i globalno na polju pozicioniranja. Sa
            razvojem tehnologije, širile su se i mogućnosti primene. Tako je
            danas GNSS prisutan u realizaciji većine geodetskih poslova. Ovo
            predavanje ima za cilj da odgovori na pitanja, kao što su: Šta je
            GNSS i kako on funkcioniše? Koje su metode primene i šta nam one
            omogućavaju? Na koje koordinatne sisteme se odnose rezultati? Koja
            su očekivanja, a koje mogućnosti GNSS tehnologije? Kako funkcioniše
            i šta nam pruža mreža permanentnih GNSS stanica? Odgovore na ova
            pitanja i još mnogo toga, pružiće nam ljudi koji imaju veliko
            teorijsko i praktično znanje o GNSS tehnologiji i koji su prisutni u
            svetu GNSS-a od njegovih početaka primene u našoj zemlji.
          </p>
        </div>
      </Row>
      <br></br>
      <Row>
        <h4>Predavanje se sastoji od dva dela:</h4>
        <ul style={{ paddingLeft: "5%" }}>
          <li>
            <strong>
              Prvi deo: GNSS – koncepti i primene Predavač: Zoran Nedeljković,
              dipl.geod.inž. Sadržaj:
            </strong>{" "}
            <br></br> Opis pojmova i koncepti, Metode primene (Statika, Single
            Base RTK, PPK, Mrežni RTK), Koordinatni sistemi, predrasude i zamke
            u primeni.{" "}
          </li>
          <br></br>
          <li>
            <strong>
              Drugi deo: Mreže GNSS permanentnih stanica Predavač: Prof. dr
              Vladimir Bulatović, dipl.geod.inž. Sadržaj:
            </strong>{" "}
            <br></br> Metode preciznog pozicioniranja, Koordinatni sistemi,
            Standardi, Servisi, Koncepti mreža GNSS permanentnih stanica,
            Oblasti primene.
          </li>
        </ul>
      </Row>
      <br></br>
      <Row>
        <h3>Biografije predavača:</h3>
        <div className="text-justify">
          <p style={{ paddingTop: "20px" }}>
            <strong>Vladimir Bulatović</strong> rođen je 1975. godine u
            Subotici. Diplomirao je 2001. godine na Građevinskom fakultetu -
            geodetski odsek. Magistarske studije završio je na FON-u smer za
            informacione sisteme 2007. godine. Doktorirao je na FTN-u 2011.
            godine. Radio je u Republičkom geodetskom zavodu, a od 2003 zaposlen
            je na FTN-u u Novom Sadu gde radi kao redovni profesor na predmetima
            Osnove GNSS sistema, Mreže permanentnih stanica, Integrisani sistemi
            premera, Račun Izravnanja, Precizna geodetska merenja. Kao gostujući
            profesor radi u Podgorici. Kao konsultant radio je u firmi GeoGIS
            Consultants od osnivanja, a od 2016. godine vodi sopstvenu firmu
            GeoSolutions. Oblast interesovanja: geodezija, GNSS tehnologija,
            sistem integracija, IoT, informacioni sistemi, batimetrija.
          </p>
          <br></br>
          <p>
            <strong>Zoran Nedeljković</strong> je geodeta koji se tehnologijom
            GNSS bavi od 1996. godine. Sa preko 100 projekata u oblasti GNSS,
            Zoran je u Sektoru za Osnovne geodetske radove, RGZ-a učestvovao u
            projektovanju i realizaciji Referentnog okvira SREF/YUREF, servisa
            AGROS i bio deo tima koji je izradio idejni projekat novog visinskog
            referentnog okvira RNM. Tokom rada na Građevinskom fakultetu je
            istraživao uticaj narušene geometrije satelitske konstelacije na
            merenja i radio na metrološkom etaloniranju GNSS prijemnika. Osnivač
            je i vlasnik firme Atlas Sistem u kojoj razvija mrežni NTRIP servis
            365gps, bavi se konsultantskim radom, realizacijom različitih
            geodetskih projekata u oblasti GNSS i distribucijom GNSS opreme
            brendova: Javad, Tersus, Emlid.
          </p>
        </div>
      </Row>
    </Container>
  );
}

export default PredavanjeGNSS;
