import React, {useState} from 'react'
import { Container, Button, Form, Row, Col, Alert, Spinner } from 'react-bootstrap'
import {url, regex} from '../../constants/constants';
import axios from 'axios'

function ForgotPassword() {
    const [email, setEmail] = useState("")
    const [error, setError] = useState();
    const [working, setWorking] = useState(false);
    const [message, setMessage] = useState();


    async function getForgotPasswordEmail(){
        setWorking(true);
        if(!regex.test(email)){
            setError("Loš format email adrese.");
            setWorking(false);
            return;
        }
        try{
            const {data} = await axios.post(url + "api/sendforgotpasswordemail", {email: email});
            setMessage("Poslat vam je link za resetovanje šifre na vašu email adresu.");
        }catch(e){
            setError(e.response.data)
        }
        setWorking(false);
    }

    return (
        <Container>
            <Row>
            <Col md={3}></Col>
            <Col md={6}>
                <Form>
                    <Form.Group>
                        <h2>Pronadji Svoj Email</h2>
                        <Form.Text>Molimo vas unesite vašu email adresu</Form.Text>
                        <p></p>
                        <Form.Control type="email" placeholder="Email adresa" onChange={(e)=>{setEmail(e.target.value)}}/>
                    </Form.Group>
                    <br></br>
                    {error && 
                        <Alert variant="danger">
                            {error}
                        </Alert>
                    }
                    {message &&
                        <Alert variant="success">
                            {message}
                        </Alert>
                    }
                    <br></br>
                    <Button variant='success' onClick={getForgotPasswordEmail}>{
                        working ? <Spinner animation="border" /> : "Pošalji"
                    }</Button>
                </Form>
            </Col>
            <Col md={3}></Col>
            </Row>
        </Container>
    )
}

export default ForgotPassword
