import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';

function PosetaGeodetskihInzenjeraScreen() {
  return (
    <Container>
        <Row>
            <div style={{textAlign: "center"}}>
                <Col md={12}>
                    <h2>Poseta godišnjoj skupštini Društva geodetskih inženjera i geometara Republike Srpske</h2>
                </Col>
            </div>
        </Row>
        <Row>
            <Col md={3}>
                <i>Savez geodeta Srbije</i>
                <br></br>
                <i>30.9.2022.</i>
            </Col>
            <Col md={6}></Col>
            <Col md={3}></Col>
        </Row>
        <hr></hr>
        <Row>
            <Col md={5}>
                <br></br>
                <div className='text-center' style = {{height: "auto", width: "100%"}}>
                    <img className='text-center' style = {{height: "auto", width: "70%"}} src="../assets/img/poseta-inzenjera/02.webp"></img>
                </div>
            </Col>
            <Col md={7}>
                <p></p>
                <p style={{textAlign: "justify"}}>
                Savez geodeta Srbije imao je posebnu čast da prisustvuje godišnjoj skupštini društva geodetskih inženjera i geometara Republike Srpske.</p>
                <p style={{textAlign: "justify"}}>Skup je bio organizovan u Laktašima od 23.9. do 25.9.2022 godine.</p>
                <p style={{textAlign: "justify"}}>Zahvaljujući pozivu društva geodetskih inženjera i geometara Republike Srpske imali smo zadovoljstvo da prisustvujemo veoma interesantnim stručnim predavanjima i svim ostali sadržajima koji su bili odlično organizovani.</p>
                <p style={{textAlign: "justify"}}>U ime Saveza geodeta Srbije, kao gost, u uvodnom delu skupštine prisutnima se obratio predsednik SGS-a dr Mladen Šoškić, dipl.inž.geod.</p>
            </Col>
        </Row>
        <br></br>
        <Row>
            <Col md={5}></Col>
            <Col md={7}>
                <div className='text-center' style = {{height: "auto", width: "100%", display: "flex"}}>
                    <img style = {{height: "auto", width: "50%", paddingRight: "10px"}} src="../assets/img/poseta-inzenjera/01.webp"></img>
                    <img style = {{height: "auto", width: "50%"}} src="../assets/img/poseta-inzenjera/03.webp"></img>
                </div>
            </Col>
        </Row>
    </Container>
  )
}

export default PosetaGeodetskihInzenjeraScreen