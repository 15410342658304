import React from 'react';
import {Row, Col, Container, Table} from 'react-bootstrap';
import radniDeoSabora from '../../assets/img/news/vest-sa-sabora/radni-deo-sabora.jpeg';
import svecanaVecera from '../../assets/img/news/vest-sa-sabora/svecana-vecera.jpeg';

function VestSaSabora() {
  return (
    <Container>
            <Row>
                <div style={{textAlign: "center"}}>
                    <Col md={12}>
                        <h2>Sabor geodeta Srbije 2022.</h2>
                    </Col>
                </div>
            </Row>
            <Row>
                <Col md={3}>
                    <i>Savez geodeta Srbije</i>
                    <br></br>
                    <i>06.10.2022.</i>
                </Col>
                <Col md={6}></Col>
                <Col md={3}></Col>
            </Row>
            <hr></hr>
            <Row>
                <Col md={5}>
                    <div className="text-center" style = {{height: "auto", width: "100%"}}>
                        <img style = {{height: "auto", width: "100%"}} src={radniDeoSabora}></img>
                        <br></br>
                        <i></i>
                    </div>
                </Col>
                <Col md={7}>
                    <p style={{textAlign: "justify"}}>
                        Prvog vikenda oktobra, u „Zepter hotelu Drina” u Bajinoj Bašti, održan je Sabor geodeta Srbije, 33. put po redu. 
                    </p>
                    <p style={{textAlign: "justify"}}>
                        Prijemom gostiju u hotel, u petak u poslepodnevnim časovima , započeta je tradicija godišnjeg okupljanja, druženja i razmene iskustava geodeta iz cele Srbije koja je do kraja provedena u prijatnom ambijentu i dobrom raspoloženju.
                    </p>                    
                    <p style={{textAlign: "justify"}}>
                        U subotu, na početku radnog dela Sabora, skupu su se najpre obratili gosti iz regiona, gospođa Tatjana Sarajlić iz Društva geodetskih inženjera i geometara Republike Srpske, i predstavnik delegacije inženjera Naučno-tehničkog saveza za geodeziju i uređenje zemljišta Bugarske, gospodin V. I. Jovev. 
                        Nakon pozdravne reči gostiju, članovi rukovodstva zahvalili su se svim učesnicima na prisustvu  i podsetili na ciljeve koje je Savez postavio, pozvavši kolege članove da uzmu aktivno učešće u radu Saveza.  
                    </p>
                    <p style={{textAlign: "justify"}}>
                        Tema radnog dela Sabora bila je "Savremena praksa i izazovi geodezije u infrastrukturnim projektima", tokom kojeg su slušaoce sa primerima dobre prakse upoznali panelisti: dipl.inž.geod. Uglješa Brajković, dipl.inž.geod. Marina Blagojević, dipl.inž.geod. Predrag Čorda, dr Zoran Sušić, dipl.inž.geod. Izložena predavanja podstakla su diskusiju o aktuelnim problemima u oblasti inženjerske geodezije, i najavila formiranje radnih tela koja bi razmotrila mogućnosti uvođenja promena.
                    </p>
                    <p style={{textAlign: "justify"}}>
                        U prijemnim prostorijama hotela gosti su imali mogućnost da se upoznaju sa asortimanom geodetske opreme izlagača:  Livona, Vekom, Geo oprema i Atlas.
                    </p>
                </Col>
                <Col md={12}>
                    <p style={{textAlign: "justify"}}>
                        Tokom dana održana su i sportska takmičenja u šahu, stonom tenisu, malom fudbalu, basketu, pikadu i plesu, a vreme za slobodne aktivnosti učesnici su koristili da se priključe nekom od organizovanih izleta u okolini Bajine Bašte.
                    </p>
                    <p style={{textAlign: "justify"}}>
                        Za vreme svečane večere pobednicima sportskih takmičenja uručeni su pehari, dok su zahvalnice dodeljene organizacijama i pojedincima koji su doprineli da se Savez osnaži, a tradicija okupljanja održi i ove godine. 
                    </p>
                    <p style={{textAlign: "justify"}}>
                        Predsednik Saveza najavio je da Sabor sledeće godine dolazi u izmenjenom formatu. Očekuju se noviteti po pitanju organizacije kako radnog tako i zabavnog dela programa.
                    </p>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <Table bordered hover>
                        <thead>
                            <tr>
                                <th>Pobednici sportskih takmičenja</th>
                                <th>Dobitnici zahvalnica za podršku radu Saveza geodeta Srbije</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>Pikado (muškarci):</th>
                                <th>Organizacije:</th>
                            </tr>
                            <tr>
                                <td>1. Pavličić Vojin, Jugotrade doo</td>
                                <td>Geotaur DOO, Beograd</td>
                                
                            </tr>
                            <tr>
                                <td>2. Petar Jelača, Absolut documents VS</td>
                                <td>Premer Savković DOO, Beograd</td>
                                
                            </tr>
                            <tr>
                                <td>3. Aleksandar Pavlović, VGI</td>
                                <td>Slaviša Ilić PR SDC Professional, Beograd</td>
                            </tr>
                            <tr>
                                <th>Pikado (žene):</th>
                                <td>ABA Professional DOO, Beograd</td>
                            </tr>
                            <tr>
                                <td>1. Smiljković Vesna, NO</td>
                                <td>Geosystem DOO, Beograd</td>
                            </tr>
                            <tr>
                                <td>2. Boncić Biljana, VGI</td>
                                <td>Geokrulj DOO, Beograd</td>
                            </tr>
                            <tr>
                                <td>3. Lidija Stevanović GB Gaus</td>
                                <td>Geovizija DOO, Pančevo</td>
                            </tr>
                            <tr>
                                <th>Stoni tenis (muškarci):</th>
                                <td>GEO OPREMA DOO, Jelašnica</td>
                            </tr>
                            <tr>
                                <td>1. Branković Radoje, VGI</td>
                                <td>GEORAD DOO, Pančevo</td>
                            </tr>
                            <tr>
                                <td>2. Božanić Aleksandar, VGI</td>
                                <td>MapSoft DOO, Beograd</td>
                            </tr>
                            <tr>
                                <td>3. Vladimir Jović, Geo-projekt SM</td>
                                <td>Grinič DOO, Niš</td>
                            </tr>
                            <tr>
                                <th>Stoni tenis (žene):</th>
                                <td>GEODETSKI BIRO GEODAVS, Sudurlica</td>
                            </tr>
                            <tr>
                                <td>1. Miladinović Vesna, JOS RGZ</td>
                                <td>CO-DESIGNING DOO, Beograd</td>
                            </tr>
                            <tr>
                                <td>2. Jovana Kuka, VGI</td>
                                <td>CIP, Beograd</td>
                            </tr>
                            <tr>
                                <td>3. Iva Bilalović, GP Gaus</td>
                                <td>Institut za puteve AD, Beograd</td>
                            </tr>
                            <tr>
                                <th>Šah:</th>
                                <td>A-Survey, Beograd</td>
                            </tr>
                            <tr>
                                <td>1. Stanojević Aleksandar, Geo oprema</td>
                                <th>Pojedinci:</th>
                            </tr>
                            <tr>
                                <td>2. Janković Aleksandar, Geo-projekt SM</td>
                                <td>dipl.inž.geod. Stanislav Vasiljević</td>
                            </tr>
                            <tr>
                                <td>3. Željko Tadić, Elektroistok - projektni biro</td>
                                <td>dipl.inž.geod. Milica Jovanović</td>
                            </tr>
                            <tr>
                                <th>Basket:</th>
                                <td></td>
                            </tr>
                            <tr>
                                <td>VGI</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Geo-projekt SM</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>GB Gaus</td>
                                <td></td>
                            </tr>
                            <tr>
                                <th>Mali fudbal:</th>
                                <td></td>
                            </tr>
                            <tr>
                                <td>GB Gaus</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>VGI</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Geo oprema</td>
                                <td></td>
                            </tr>
                            <tr>
                                <th>Ples:</th>
                                <td></td>
                            </tr>
                            <tr>
                                <td>1. Radoje Banković i Vesna Mladenović, VGI</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>2. Filip Filipović, Rio Tinto i Lidija Stevanović, GB Gaus</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>3. Stanislav Vasiljević i Milica Jovanović, Geotaur</td>
                                <td></td>
                            </tr>
                            
                        </tbody>
                    </Table>
                </Col>
                <Col md={6}>
                    <img src={svecanaVecera} style={{width: "100%", height: "auto"}}></img>
                </Col>
            </Row>
        </Container>
  )
}

export default VestSaSabora