import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";

function Sabor35() {
  return (
    <Container>
      <Row>
        <div style={{ textAlign: "center" }}>
          <Col md={12}>
            <h2>XXXV Sabor Geodeta Srbije</h2>
          </Col>
        </div>
      </Row>
      <Row>
        <Col md={3}>
          <i>Savez geodeta Srbije</i>
          <br></br>
          <i>19.06.2024.</i>
        </Col>
        <Col md={6}></Col>
        <Col md={3}></Col>
      </Row>
      <hr></hr>
      <Row>
        <Col md={5}>
          <br></br>
          <div
            className="text-center"
            style={{ height: "auto", width: "100%" }}
          >
            <img
              style={{ height: "auto", width: "100%", paddingTop: "0px" }}
              src="../assets/img/XXXVSabor2.webp"
            ></img>
            <br></br>
            <i></i>
          </div>
        </Col>
        <Col md={7}>
          <p></p>
          <p style={{ textAlign: "justify" }}>Poštovane kolege,</p>
          <p style={{ textAlign: "justify" }}>
            Ovim putem vas pozivamo na XXXV po redu Sabor geodeta Srbije u
            organizaciji Saveza geodeta Srbije,{" "}
            <b>uz podršku Inženjerske komore Srbije</b>, koji će se održati od
            18. do 20. oktobra u hotelu Đerdap u Kladovu. Dođite da zajedno sa
            ostalim kolegama provedemo kvalitetno vreme u učenju, druženju,
            istraživanju savremenih tehnologija kao i u sjajnoj zabavi i sportu.
          </p>
          <p style={{ textAlign: "justify" }}>
            Potrudićemo se da na Saboru čujete mnoge korisne i inspirativne
            sadržaje iz naše struke. Zato će i ove godine radni deo Sabora biti
            aktuelan i biće posvećen novim mogućnostima i izazovima sa kojima se
            naša struka suočava. U okviru radnog dela imaćemo zanimljive
            predavače koji će na najbolji mogući način izložiti svoje viđenje
            geodezije danas. Takođe, pored prezentacija imaćete priliku da
            učestvujete u radionicama, panel diskusiji I dodatnim zanimljivim
            sadržajima koji će biti održani u sklopu Sabora geodeta Srbije.
          </p>
          <p style={{ textAlign: "left" }}>
            Više detalja o visini kotizacije ćemo objaviti narednih dana, pa vas
            molimo da pratite naš sajt i društvene mreže. Za firme će biti
            omogućeno plaćanje na rate, kao i različiti paketi sa privilegijama
            poput prezentacije projekata, poslovanja i opreme.{" "}
          </p>
        </Col>
      </Row>
      <Row
        style={{ paddingTop: "40px", paddingLeft: "0px" }}
        className="justify-content-evenly"
      >
        <Col md={5}>
          <div
            className="text-center d-flex"
            style={{
              height: "auto",
              width: "100%",
              paddingBottom: "10px",
              alignItems: "center",
            }}
          >
            <div style={{ textAlign: "justify" }}>
              <img
                style={{ height: "auto", width: "12%", paddingBottom: "10px" }}
                src="../assets/img/icons/kokteli4.png"
                alt="Wine Glasses"
              />
              <p style={{ fontWeight: "bold" }}>Druženje i zabava</p>
              <p>
                Ove godine ćemo vam prirediti nezaboravno druženje i zabavu.
                Zato su sportski i zabavni program neizostavni deo Sabora. Prve
                večeri je organizovano druženje dobrodošlice u diskoteci hotela.
                Narednog dana imaćete priliku da obiđete turistički sadržaj
                Kladova, a uveče ćete moći da uživate pored Dunava uz svečanu
                večeru u restoranu hotela i družite sa ostalim kolegama.
              </p>
            </div>
          </div>
          <div
            className="text-center d-flex"
            style={{
              height: "auto",
              width: "100%",
              paddingBottom: "20px",
              alignItems: "center",
            }}
          >
            <div style={{ textAlign: "justify", paddingBottom: "20px" }}>
              <img
                style={{ height: "auto", width: "16%", paddingBottom: "4px" }}
                src="../assets/img/icons/sport.png"
                alt="Wine Glasses"
              />
              <p style={{ fontWeight: "bold" }}>Sport i rekreacija</p>
              <p>
                Sportska takmičenja su neizostavni deo Sabora. Prijavite ekipu
                za takmičenje u fudbalu, basketu, odbojci, stonom tenisu, pikadu
                ili šahu.
              </p>
            </div>
          </div>
          <div
            className="text-center d-flex"
            style={{
              height: "auto",
              width: "100%",
              paddingBottom: "40px",
              alignItems: "center",
            }}
          >
            <div style={{ textAlign: "justify" }}>
              <img
                style={{ height: "auto", width: "13%", paddingBottom: "12px" }}
                src="../assets/img/icons/panel.png"
                alt="Wine Glasses"
              />
              <p style={{ fontWeight: "bold" }}>Panel diskusije</p>
              Panel diskusije omogućavaju dinamičnu razmenu ideja i iskustava
              među profesionalcima, što vodi ka dubljem razumevanju kompleksnih
              tema.
            </div>
          </div>
        </Col>
        <Col md={5}>
          <div
            className="text-center d-flex"
            style={{
              height: "auto",
              width: "100%",
              paddingBottom: "80px",
              alignItems: "center",
            }}
          >
            <div style={{ textAlign: "justify" }}>
              <img
                style={{ height: "auto", width: "10%", paddingBottom: "12px" }}
                src="../assets/img/icons/presentation.png"
                alt="Wine Glasses"
              />
              <p style={{ fontWeight: "bold" }}>Predavanja</p>
              Planirana predavanja pružiće vam priliku da čujete najnovija
              dostignuća i istraživanja od vodećih stručnjaka u oblasti
              geodezije. Povećajte svoje znanje i ostanite u korak sa savremenim
              trendovima i tehnologijama koje transformišu našu struku.
            </div>
          </div>
          <div
            className="text-center d-flex"
            style={{
              height: "auto",
              width: "100%",
              paddingBottom: "40px",
              alignItems: "center",
            }}
          >
            <div style={{ textAlign: "justify" }}>
              <img
                style={{ height: "auto", width: "12%", paddingBottom: "12px" }}
                src="../assets/img/icons/radionice.png"
                alt="Wine Glasses"
              />
              <p style={{ fontWeight: "bold" }}>
                Edukativne radionice i prezentacije
              </p>
              Sticanje praktičnog znanja i veština kroz interaktivne sesije,
              omogućavajući vam da odmah primenite naučeno u svom radu.
              Iskoristite priliku da se direktno upoznate sa novim alatima i
              metodama.
            </div>
          </div>
          <div
            className="text-center d-flex"
            style={{
              height: "auto",
              width: "100%",
              paddingBottom: "40px",
              alignItems: "center",
            }}
          >
            <div style={{ textAlign: "justify" }}>
              <img
                style={{ height: "auto", width: "12%", paddingBottom: "18px" }}
                src="../assets/img/icons/oprema.png"
                alt="geodesy equipment icon"
              />
              <p style={{ fontWeight: "bold" }}>
                Predstavljanje geodetske opreme
              </p>
              <p>
                Svi značajni prodavci geodetske opreme izložiće sve novitete iz
                svoje ponude u holu hotela.
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Sabor35;
