import React, { useEffect } from "react";
import { Alert, Carousel } from "react-bootstrap";
import "../assets/css/custom.css";
import "../assets/css/style.css";
import "../assets/vendor/animate.css/animate.min.css";
import "../assets/vendor/bootstrap-icons/bootstrap-icons.css";
import "../assets/vendor/bootstrap/css/bootstrap.min.css";
import "../assets/vendor/boxicons/css/boxicons.min.css";
import "../assets/vendor/glightbox/css/glightbox.min.css";
import "../assets/vendor/swiper/swiper-bundle.min.css";

import bgSlide1 from "../assets/img/slide/slide-1.jpg";
import bgSlide2 from "../assets/img/slide/slide-2.jpg";
import bgSlide3 from "../assets/img/slide/slide-3.jpg";

import { useSearchParams } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import messages from "../messages.json";

function HomeScreen() {
  useEffect(() => {
    /*for (let i=0; i<scripts.length; i++){
      const script = document.createElement('script');
      script.src = scripts[i];
      script.async = true;
      document.body.appendChild(script);
      scriptElements.push(script)
    }*/
    const script = document.createElement("script");
    script.src = "./assets/js/main.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const [searchParams, setSearchParams] = useSearchParams();
  const success = searchParams.get("success");
  const message = searchParams.get("message");
  let messageExists = message in messages;

  return (
    <div>
      <div style={{ textAlign: "center" }}>
        {success &&
          messageExists &&
          (success == "true" ? (
            <Alert variant="success">{messages[message]}</Alert>
          ) : (
            <Alert variant="danger">{messages[message]}</Alert>
          ))}
      </div>
      <Header></Header>
      {/* ======= Hero Section ======= */}
      <section id="hero">
        <ol className="carousel-indicators" id="hero-carousel-indicators" />
        <div className="carousel-inner" role="listbox"></div>
        <Carousel>
          <Carousel.Item
            style={{
              backgroundImage: `url(${bgSlide2})`,
              backgroundPosition: "bottom",
            }}
          >
            {/*<img className="d-block w-100" src="assets/img/slide/slide-3.jpg" alt="First slide" />*/}
            <div className="carousel-container">
              <div className="container">
                <h2 className="animate__animated animate__fadeInDown">
                  Obaveštenje povodom odbijanja prodaje licenci za sertifikaciju
                </h2>
                <p className="animate__animated animate__fadeInUp">
                  Kao što ste već upoznati, Savez geodeta Srbije se već oglašavao i preduzimao
                  akcije povodom situacije u vezi sertifikacije i implementacije servisa digitalnog
                  geodetskog elaborata. U poslednjih nedelju dana došli smo do novih informacija
                  koje su u najmanju ruku alarmantne i koje zahtevaju hitnu i odlučnu reakciju.
                </p>
                <a
                  href="/aktuelnosti/odbijanje-prodaje-licenci"
                  className="btn-get-started animate__animated animate__fadeInUp scrollto"
                >
                  Saznaj više
                </a>
              </div>
            </div>
            <a className="carousel-control-prev" role="button" data-bs-slide="prev">
              <span className="carousel-control-prev-icon bi bi-chevron-left" aria-hidden="true" />
            </a>
            <a className="carousel-control-next" role="button" data-bs-slide="next">
              <span className="carousel-control-next-icon bi bi-chevron-right" aria-hidden="true" />
            </a>
          </Carousel.Item>
          <Carousel.Item
            style={{
              backgroundImage: `url(${bgSlide1})`,
              backgroundPosition: "bottom",
            }}
          >
            {/*<img className="d-block w-100" src="assets/img/slide/slide-2.jpg" alt="First slide" />*/}
            <div className="carousel-container">
              <div className="container">
                <h2 className="animate__animated animate__fadeInDown">
                  Stručno predavanje na temu Web Kartografija: Od tradicionalnih karata do Web
                  kartografskih aplikacija
                </h2>
                <p className="animate__animated animate__fadeInUp">
                  Savez geodeta Srbije ima zadovoljstvo da u saradnji sa Prof. Dr Milanom
                  Kilibardom, diplomiranim inženjerom geodezije, organizuje stručno predavanje na
                  temu Web Kartografija: Od tradicionalnih karata do Web kartografskih aplikacija.
                </p>
                <a
                  href="/aktuelnosti/kartografija-predavanje"
                  className="btn-get-started animate__animated animate__fadeInUp scrollto"
                >
                  Saznaj više
                </a>
              </div>
            </div>
            <a className="carousel-control-prev" role="button" data-bs-slide="prev">
              <span className="carousel-control-prev-icon bi bi-chevron-left" aria-hidden="true" />
            </a>
            <a className="carousel-control-next" role="button" data-bs-slide="next">
              <span className="carousel-control-next-icon bi bi-chevron-right" aria-hidden="true" />
            </a>
          </Carousel.Item>
          <Carousel.Item
            style={{
              backgroundImage: `url(${bgSlide3})`,
              backgroundPosition: "bottom",
            }}
          >
            {/*<img className="d-block w-100" src="assets/img/slide/slide-1.jpg" alt="First slide" />*/}
            <div className="carousel-container">
              <div className="container">
                <h2 className="animate__animated animate__fadeInDown">
                  Članstvo u evropskoj komisiji geodeta (CLGE)
                </h2>
                <p className="animate__animated animate__fadeInUp">
                  {" "}
                  Savez geodeta Srbije primljen je u članstvo Evropske komisije geodeta, CLGE
                  (Comité de Liaison des Géomètres Européens) kao punopravni član (Principal
                  Member). Na skupštini CLGE održanoj od 18. do 20. aprila 2024. godine u Cavtatu,
                  izglasan je prijem Saveza geodeta Srbije, kao krovne organizacije geodeta u
                  Srbiji, u punopravno članstvo. Za prijem su bili predstavnici svih zemalja osim
                  predstavnika samozvane države Kosovo koji je bio protiv.{" "}
                </p>
                <a
                  href="/aktuelnosti/clanstvo-eu-komisije"
                  className="btn-get-started animate__animated animate__fadeInUp scrollto"
                >
                  Saznaj više
                </a>
              </div>
            </div>
            <a className="carousel-control-prev" role="button" data-bs-slide="prev">
              <span className="carousel-control-prev-icon bi bi-chevron-left" aria-hidden="true" />
            </a>
            <a className="carousel-control-next" role="button" data-bs-slide="next">
              <span className="carousel-control-next-icon bi bi-chevron-right" aria-hidden="true" />
            </a>
          </Carousel.Item>
        </Carousel>
      </section>
      {/* End Hero */}
      <main id="main">
        {/* ======= Featured Services Section ======= */}
        <section id="featured-services" className="featured-services section-bg">
          <div className="container">
            <div className="row no-gutters">
              <div className="col-lg-4 col-md-6">
                <div className="icon-box">
                  <div className="icon">
                    <i className="bi bi-laptop" />
                  </div>
                  <h4 className="title">
                    <a href>Obrazovanje</a>
                  </h4>
                  <p className="description">
                    Usavršavanje geodetskih inženjera i organizovanje odgovarajućih oblika
                    permanentnog obrazovanja.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="icon-box">
                  <div className="icon">
                    <i className="bi bi-briefcase" />
                  </div>
                  <h4 className="title">
                    <a href>Etika</a>
                  </h4>
                  <p className="description">Negovanje i razvijanje etike inženjerskog poziva</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="icon-box">
                  <div className="icon">
                    <i className="bi bi-calendar4-week" />
                  </div>
                  <h4 className="title">
                    <a href>Publikacija</a>
                  </h4>
                  <p className="description">
                    Publikacija naučnih i stručnih radova, časopisa i drugih publikacija od interesa
                    za geodetsku struku i tehničku inteligenciju
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Featured Services Section */}
        {/* ======= About Us Section ======= */}
        <section id="about" className="about">
          <div className="container">
            <div className="section-title">
              <h2>O nama</h2>
            </div>
            <div className="row">
              <div className="col-lg-6 order-1 order-lg-2">
                <img src="assets/img/about.jpg" className="img-fluid" alt="" />
              </div>
              <div
                className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content"
                style={{ marginTop: "10%" }}
              >
                <p style={{ textAlign: "center" }}>
                  Savez geodeta Srbije (SGS) je dobrovolјna, nevladina, nedobitna, stručno-naučna
                  organizacija geodeta u Republici Srbiji, zasnovana na slobodi udruživanja radi
                  ostvarivanja zajedničkih i opštih cilјeva struke, sa tradicijom dugom preko 150
                  godina. SGS neguje saradnju i sa drugim stručnim, naučno-istraživačkim,
                  obrazovnim, privrednim i sličnim organizacijama, na osnovu uzajamnog poštovanja,
                  uvažavanja i samostalnosti u radu u interesu realizacije zajedničkih i opštih
                  cilјeva.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* End About Us Section */}
        {/* ======= Ciljevi ======= */}
        <section id="ciljevi" className="about">
          <div className="container">
            <div className="section-title">
              <h2>Ciljevi</h2>
            </div>
            <div className="row" style={{ textAlign: "center" }}>
              <div className="col-lg-6 order-1 order-lg-2">
                <img src="assets/img/goal3.jpg" className="img-fluid" alt="" />
              </div>
              <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
                <p style={{ textAlign: "left", marginTop: "2vh" }}>
                  Učešće u pisanju zakonske regulative (zakoni, podzakonska akta, pravilnici…) koja
                  je vezana za geodetsku struku. Svaki akt koji na bilo koji način dotiče geodeziju
                  mora biti donešen uz aktivno učešće SGS-a, kao relevantnog predstavnika struke.
                </p>
                <p style={{ textAlign: "left", marginTop: "2vh" }}>
                  Organizacija raznih vidova stručnih skupova. Savremene okolnosti u kojima radimo
                  zahtevaju konstatno usavršavanje, praćenje novih tehnologija i trendova kako kod
                  nas tako i u svetu. Planiramo da pod okriljem SGS-a organizujemo brojne
                  konferencije, seminare, savetovanja i obuke što bi omogućilo svim članovima da
                  budu u toku i stiču nova znanja iz oblasti koje njih najviše interesuju. Ti
                  skupovi ne bi služili samo sticanju novih znanja, već i upoznavanju i povezivanju
                  nas međusobno. Kontakti ostvareni na skupovima mogu se pokazati značajnim u
                  poslovima koje ćemo u budućnosti raditi.
                </p>
                <p style={{ textAlign: "left", marginTop: "2vh" }}>
                  Organizacija savetovanje u sklopu permanentnog (celoživotnog) obrazovanja, model
                  ovog obrazovanja i načina sticanja i obnavljanja licenci je trenutno u izradi, te
                  SGS teži da isprati ove promene, gde će za svoje članove organizovati adekvatne
                  skupove.
                </p>
              </div>
            </div>
            <div className="row" style={{ textAlign: "center", paddingTop: "4vh" }}>
              <div className="col-lg-6 order-1 order-lg-2">
                <p style={{ textAlign: "left", marginTop: "1vh" }}>
                  Okuplјanje i organizovanje geodetskih inženjera i tehničara Srbije radi
                  ostvarivanja njihovih interesa, uvećanja njihovog stručnog znanja, obezbeđenja
                  odgovarajućeg statusa u zajednici na bazi njihovog stručnog doprinosa u
                  privrednom, ekonomskom, naučno tehničkom i ukupnom razvoju Republike Srbije.
                </p>
                <p style={{ textAlign: "left", marginTop: "2vh" }}>
                  Da neguje i razvija etiku inženjerskog poziva.
                </p>
                <p style={{ textAlign: "left", marginTop: "2vh" }}>
                  Organizacija sabora geodeta. Tradicija organizacije sabora je jedna od najlepših
                  tradicija našeg saveza koju nameravamo da održimo i koju ljubomorno čuvamo.
                </p>
                <p style={{ textAlign: "left", marginTop: "2vh" }}>
                  Podsticanje, organizovanje naučno-stručnih skupova, objavlјivanje naučnih i
                  stručnih radova, časopisa i drugih publikacija od interesa za geodetsku struku i
                  tehničku inteligenciju.
                </p>
                <p style={{ textAlign: "left", marginTop: "2vh" }}>
                  Da organizuje permanentno godišnje izdanje svojih publikacija i stručnih sadržaja.
                </p>
                <p style={{ textAlign: "left", marginTop: "2vh" }}>
                  Da pravovremeno obezbedi svom članstvu naučne, tehnološke i poslovne informacije
                  neophodne radi omogućavanja njihovog savetodavnog učešća u procesu odlučivanja kod
                  nadležnih organa.
                </p>
              </div>
              <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
                <img src="assets/img/goal2.jpg" className="img-fluid" alt="" />
              </div>
            </div>
            <div
              className="col-lg-12 pt-4 pt-lg-0 order-2 order-lg-1 content"
              style={{ paddingTop: "2%" }}
            >
              <ul>
                <p style={{ textAlign: "left" }}>
                  {" "}
                  SGS će se zalagati za učešće u formiranju zakonskih propisa i uslova za sticanje i
                  ostvarivanje profesionalnih prava, odnosno ovlašćenja i licenci za rad u struci
                  geodetskih inženjera.
                </p>
                <p style={{ textAlign: "left" }}>
                  {" "}
                  Da uspostavlјa oblike povezivanja i saradnje sa državnim organima i da te veze
                  održava i unapređuje.
                </p>
                <p style={{ textAlign: "left" }}>
                  {" "}
                  Da uspostavlјa i neguje saradnju sa udruženjima, naučno-istraživačkim i obrazovnim
                  organizacijama drugih struka.
                </p>
                <p style={{ textAlign: "left" }}>
                  {" "}
                  Razmatranje i davanje stručnih mišlјenja o planovima, programima, analizama i
                  drugim aktima važnim za razvoj tehnike, tehnologije i proizvodnje u Republici
                  Srbiji.
                </p>
                <p style={{ textAlign: "left" }}>
                  {" "}
                  Podsticanje i pomaganje onih aktivnosti i inicijativa koje su usmerene na očuvanje
                  životne sredine i uređenje prostora, uštedu i racionalizaciju potrošnje svih vrsta
                  energije i dr.
                </p>
              </ul>
            </div>
          </div>
        </section>

        <section id="vesti" className="portfolio">
          <div className="container">
            <div className="section-title">
              <h2>Geodetski radovi</h2>
            </div>
            <div className="row">
              <div className="col-lg-12 d-flex justify-content-center">
                <ul id="portfolio-flters">
                  <li data-filter="*" className="filter-active">
                    Sve
                  </li>
                  <li data-filter=".filter-app">Katastar nepokretnosti i državni premer</li>
                  <li data-filter=".filter-card">Primenjena geodezija</li>
                  <li data-filter=".filter-web"></li>
                </ul>
              </div>
            </div>
            <div className="row portfolio-container">
              <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                <div className="portfolio-wrap">
                  <img src="assets/img/portfolio/inzenjerska.jpg" className="img-fluid" alt="" />
                  <div className="portfolio-info">
                    <h4>Inženjerska geodezija</h4>
                    <p>
                      Inženjerska geodezija je grana geodezije koja se bavi obavljanjem geodetskih
                      radova u sklopu svih inženjerskih struka poput građevine, arhitekture,
                      mašinstva, rudarstva, urbanizma i dr.
                    </p>
                    <div className="portfolio-links">
                      <a
                        href="assets/img/portfolio/inzenjerska.jpg"
                        data-gallery="portfolioGallery"
                        className="portfolio-lightbox"
                        title="App 1"
                      >
                        <i className="bx bx-plus" />
                      </a>
                      <a href="portfolio-details.html" title="More Details">
                        <i className="bx bx-link" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                <div className="portfolio-wrap">
                  <img src="assets/img/portfolio/katastar.jpg" className="img-fluid" alt="" />
                  <div className="portfolio-info">
                    <h4>Katastar nepokretnosti</h4>
                    <p>
                      Katastar nepokretnosti je osnovni i javni registar o nepokretnostima i
                      stvarnim pravima na njima.
                    </p>
                    <div className="portfolio-links">
                      <a
                        href="assets/img/portfolio/katastar.jpg"
                        data-gallery="portfolioGallery"
                        className="portfolio-lightbox"
                        title="Web 3"
                      >
                        <i className="bx bx-plus" />
                      </a>
                      <a href="portfolio-details.html" title="More Details">
                        <i className="bx bx-link" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                <div className="portfolio-wrap">
                  <img src="assets/img/portfolio/Premer.jpg" className="img-fluid" alt="" />
                  <div className="portfolio-info">
                    <h4>Državni premer</h4>
                    <p>
                      Državni premer jeste termin koji objedinjuje geodetske poslove u oblastima
                      katastarskog premera, komasacionog premera, topografskog premera, premera
                      vodova i premera državne granice.
                    </p>
                    <div className="portfolio-links">
                      <a
                        href="assets/img/portfolio/Premer.jpg"
                        data-gallery="portfolioGallery"
                        className="portfolio-lightbox"
                        title="App 2"
                      >
                        <i className="bx bx-plus" />
                      </a>
                      <a href="portfolio-details.html" title="More Details">
                        <i className="bx bx-link" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                <div className="portfolio-wrap">
                  <img src="assets/img/portfolio/portfolio-6.jpg" className="img-fluid" alt="" />
                  <div className="portfolio-info">
                    <h4>Katastar vodova</h4>
                    <p>
                      Katastar vodova predstavlja registar svih nadzemnih i podzemnih vodova,
                      poslovi koji se tiču katastra vodova jesu poslovi premera, osnivanja i
                      održavanja.
                    </p>
                    <div className="portfolio-links">
                      <a
                        href="assets/img/portfolio/portfolio-6.jpg"
                        data-gallery="portfolioGallery"
                        className="portfolio-lightbox"
                        title="Card 2"
                      >
                        <i className="bx bx-plus" />
                      </a>
                      <a href="portfolio-details.html" title="More Details">
                        <i className="bx bx-link" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                <div className="portfolio-wrap">
                  <img src="assets/img/portfolio/portfolio-5.jpg" className="img-fluid" alt="" />
                  <div className="portfolio-info">
                    <h4>Fotogrametrija</h4>
                    <p>
                      Fotogrametrija je nauka prikupljanja prostornih podataka o objektima i
                      životnoj sredini kroz proces snimanja, interpretacije i merenja fotografske
                      slike.{" "}
                    </p>
                    <div className="portfolio-links">
                      <a
                        href="assets/img/portfolio/portfolio-5.jpg"
                        data-gallery="portfolioGallery"
                        className="portfolio-lightbox"
                        title="Web 2"
                      >
                        <i className="bx bx-plus" />
                      </a>
                      <a href="portfolio-details.html" title="More Details">
                        <i className="bx bx-link" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                <div className="portfolio-wrap">
                  <img src="assets/img/portfolio/komasacija.jpg" className="img-fluid" alt="" />
                  <div className="portfolio-info">
                    <h4>Komasacija</h4>
                    <p>
                      Komasacija je agrarna mera koju sprovodi država, a čija je suština u
                      ukrupnjavanju parcela pojedinačnih vlasnika poljoprivrednog zemljišta, odnosno
                      u sprovođenju novog koncepta zemljišne teritorije, uz regulisanje
                      imovinsko-pravnih odnosa na njoj.
                    </p>
                    <div className="portfolio-links">
                      <a
                        href="assets/img/portfolio/komasacija.jpg"
                        data-gallery="portfolioGallery"
                        className="portfolio-lightbox"
                        title="App 3"
                      >
                        <i className="bx bx-plus" />
                      </a>
                      <a href="portfolio-details.html" title="More Details">
                        <i className="bx bx-link" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                <div className="portfolio-wrap">
                  <img src="assets/img/portfolio/portfolio-7.jpg" className="img-fluid" alt="" />
                  <div className="portfolio-info">
                    <h4>Eksproprijacija</h4>
                    <p>
                      Prema definiciji , eksproprijacija predstavlja prelaz privatne svojine u
                      društvenu (i državnu) u tzv. opštem, tj. javnom interesu uz adekvatnu naknadu.
                    </p>
                    <div className="portfolio-links">
                      <a
                        href="assets/img/portfolio/portfolio-7.jpg"
                        data-gallery="portfolioGallery"
                        className="portfolio-lightbox"
                        title="Card 1"
                      >
                        <i className="bx bx-plus" />
                      </a>
                      <a href="portfolio-details.html" title="More Details">
                        <i className="bx bx-link" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                <div className="portfolio-wrap">
                  <img src="assets/img/portfolio/portfolio-8.jpg" className="img-fluid" alt="" />
                  <div className="portfolio-info">
                    <h4>Lasersko skeniranje</h4>
                    <p>
                      Lasersko skeniranje predstavlja proces prikupljanja prostornih podataka
                      korišćenjem laserskih skenera sa zemlje ili iz vazduha.
                    </p>
                    <div className="portfolio-links">
                      <a
                        href="assets/img/portfolio/portfolio-8.jpg"
                        data-gallery="portfolioGallery"
                        className="portfolio-lightbox"
                        title="Card 3"
                      >
                        <i className="bx bx-plus" />
                      </a>
                      <a href="portfolio-details.html" title="More Details">
                        <i className="bx bx-link" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                <div className="portfolio-wrap">
                  <img src="assets/img/portfolio/portfolio-9.jpg" className="img-fluid" alt="" />
                  <div className="portfolio-info">
                    <h4>Satelitska geodezija</h4>
                    <p>
                      Javlja se kao neizostavan deo svih geodetskih radova koji uključuju primenu
                      GPS tehnologije.
                    </p>
                    <div className="portfolio-links">
                      <a
                        href="assets/img/portfolio/portfolio-9.jpg"
                        data-gallery="portfolioGallery"
                        className="portfolio-lightbox"
                        title="Web 3"
                      >
                        <i className="bx bx-plus" />
                      </a>
                      <a href="portfolio-details.html" title="More Details">
                        <i className="bx bx-link" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Portfolio Section */}
        {/* ======= Team Section ======= */}
        {/*<section id="team" className="team section-bg">
          <div className="container">
            <div className="section-title">
              <h2>Team</h2>
              <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                <div className="member">
                  <img src="assets/img/team/team-1.jpg" alt="" />
                  <h4>Walter White</h4>
                  <span>Chief Executive Officer</span>
                  <p>
                    Magni qui quod omnis unde et eos fuga et exercitationem. Odio veritatis perspiciatis quaerat qui aut aut aut
                  </p>
                  <div className="social">
                    <a href><i className="bi bi-twitter" /></a>
                    <a href><i className="bi bi-facebook" /></a>
                    <a href><i className="bi bi-instagram" /></a>
                    <a href><i className="bi bi-linkedin" /></a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                <div className="member">
                  <img src="assets/img/team/team-2.jpg" alt="" />
                  <h4>Sarah Jhinson</h4>
                  <span>Product Manager</span>
                  <p>
                    Repellat fugiat adipisci nemo illum nesciunt voluptas repellendus. In architecto rerum rerum temporibus
                  </p>
                  <div className="social">
                    <a href><i className="bi bi-twitter" /></a>
                    <a href><i className="bi bi-facebook" /></a>
                    <a href><i className="bi bi-instagram" /></a>
                    <a href><i className="bi bi-linkedin" /></a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
                <div className="member">
                  <img src="assets/img/team/team-3.jpg" alt="" />
                  <h4>William Anderson</h4>
                  <span>CTO</span>
                  <p>
                    Voluptas necessitatibus occaecati quia. Earum totam consequuntur qui porro et laborum toro des clara
                  </p>
                  <div className="social">
                    <a href><i className="bi bi-twitter" /></a>
                    <a href><i className="bi bi-facebook" /></a>
                    <a href><i className="bi bi-instagram" /></a>
                    <a href><i className="bi bi-linkedin" /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>*/}
        {/* End Team Section */}
        {/* ======= Contact Section ======= */}
        <section id="contact" className="contact">
          <div className="container">
            <div className="section-title">
              <h2>Kontakt</h2>
            </div>
            <div className="row">
              <div className="col-lg-5 d-flex align-items-stretch">
                <div className="info">
                  {/*<div className="address">
                    <i className="bi bi-geo-alt" />
                    <h4>Location:</h4>
                    <p>A108 Adam Street, New York, NY 535022</p>
                  </div>*/}
                  <div className="email">
                    <i className="bi bi-envelope" />
                    <h4>Email:</h4>
                    <p>office@savezgeodeta.rs</p>
                  </div>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d431.6827999479623!2d20.4650558!3d44.8096243!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a7aa58b53c3ed%3A0xf00bb45cec405aa1!2sKneza%20Milo%C5%A1a%207a%2C%20Beograd%2011000!5e1!3m2!1sen!2srs!4v1638887130544!5m2!1sen!2srs"
                    frameBorder={0}
                    style={{ border: 0, width: "100%", height: "390px" }}
                    allowFullScreen
                  />
                </div>
              </div>
              <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
                <form
                  action="forms/contact.php"
                  method="post"
                  role="form"
                  className="php-email-form"
                >
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label htmlFor="name">Ime i prezime</label>
                      <input type="text" name="name" className="form-control" id="name" required />
                    </div>
                    <div className="form-group col-md-6 mt-3 mt-md-0">
                      <label htmlFor="name">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group mt-3">
                    <label htmlFor="name">Tema</label>
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      id="subject"
                      required
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label htmlFor="name">Poruka</label>
                    <textarea
                      className="form-control"
                      name="message"
                      rows={10}
                      required
                      defaultValue={""}
                    />
                  </div>
                  <div className="my-3">
                    <div className="loading">Učitavanje</div>
                    <div className="error-message" />
                    <div className="sent-message">Vaša poruka je upravo poslata. Hvala vam!</div>
                  </div>
                  <div className="text-center">
                    <button type="submit">Pošalji poruku</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        {/* End Contact Section */}
        <Footer></Footer>
      </main>
      {/* End #main */}
      <a href="#" className="back-to-top d-flex align-items-center justify-content-center">
        <i className="bi bi-arrow-up-short" />
      </a>
      {/* Vendor JS Files */}
      {/* Template Main JS File */}
    </div>
  );
}

export default HomeScreen;
