import React, { useEffect, useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import {url} from '../constants/constants';
import {useParams} from 'react-router-dom';

function EditUserRequestModal(props) {
    const {id} = useParams();

    const [name, setName] = useState();
    const [father, setFather] = useState();
    const [surname, setSurname] = useState();
    const [phone, setPhone] = useState();
    const [email, setEmail] = useState();
    const [strucnaSprema, setStrucnaSprema] = useState();
    const [radnoMesto, setRadnoMesto] = useState();
    const [startDate, setStartDate] = useState();
    const [birthCity, setBirthCity] = useState();
    const [city, setCity] = useState();
    const [address, setAddress] = useState();
    const [idNumber, setIdNumber] = useState();
    const [idCity, setIdCity] = useState();
    const [companyRole, setCompanyRole] = useState();

    useEffect(() => {
        if(!name){
            setName(props.user.name);
            setFather(props.user.fatherName);
            setSurname(props.user.surname);
            setPhone(props.user.phone);
            setEmail(props.user.email);
            setStrucnaSprema(props.user.profQualLevel);
            setRadnoMesto(props.user.currPosition);
            setBirthCity(props.user.birthCity);
            setCity(props.user.city);
            setAddress(props.user.address);
            setIdNumber(props.user.idNumber);
            setIdCity(props.user.idCity);
            setCompanyRole(props.user.companyRole);
            setStartDate(props.user.birthDate);
        }
    })

    async function editRequest(){
        const {data} = await axios.post(url + 'api/edituserrequest', {requestId: id, name: name, fatherName: father, surname: surname, phone: phone, birthDate: startDate, email: email, profQualLevel: strucnaSprema, currPosition: radnoMesto, birthCity: birthCity, address: address, city: city, idNumber: idNumber, idCity: idCity, companyRole: companyRole}, {withCredentials: true});
        if(data){
            window.location.reload();
        } else{
            
        }
    }

  return (
    <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header>
        <Modal.Title>Izmena korisničkog zahteva</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Ime</Form.Label>
            <Form.Control
                type="text"
                value={name}
                onChange={(e)=>setName(e.target.value)}
            />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Ime oca</Form.Label>
            <Form.Control
                type="text"
                value={father}
                onChange={(e)=>setFather(e.target.value)}
            />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Prezime</Form.Label>
            <Form.Control
                type="text"
                value={surname}
                onChange={(e)=>setSurname(e.target.value)}
            />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Datum rođenja</Form.Label>
            <Form.Control
                type="text"
                value={startDate}
                onChange={(e)=>setStartDate(e.target.value)}
            />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Mesto rođenja</Form.Label>
            <Form.Control
                type="text"
                value={birthCity}
                onChange={(e)=>setBirthCity(e.target.value)}
            />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Adresa stanovanja</Form.Label>
            <Form.Control
                type="text"
                value={address}
                onChange={(e)=>setAddress(e.target.value)}
            />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Mesto stanovanja</Form.Label>
            <Form.Control
                type="text"
                value={city}
                onChange={(e)=>setCity(e.target.value)}
            />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Broj lične karte</Form.Label>
            <Form.Control
                type="text"
                value={idNumber}
                onChange={(e)=>setIdNumber(e.target.value)}
            />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Mesto izdavanje lične karte</Form.Label>
            <Form.Control
                type="text"
                value={idCity}
                onChange={(e)=>setIdCity(e.target.value)}
            />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Email addresa</Form.Label>
            <Form.Control
                type="email"
                value={email}
                onChange={(e)=>setEmail(e.target.value)}
            />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Broj telefona</Form.Label>
            <Form.Control
                type="text"
                value={phone}
                onChange={(e)=>setPhone(e.target.value)}
            />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Stepen stručne spreme</Form.Label>
            <Form.Control
                type="text"
                value={strucnaSprema}
                onChange={(e)=>setStrucnaSprema(e.target.value)}
            />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Naziv kompanije/organizacije</Form.Label>
            <Form.Control
                type="text"
                value={radnoMesto}
                onChange={(e)=>setRadnoMesto(e.target.value)}
            />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Opis radnog mesta (pozicija u kompaniji/organizaciji)</Form.Label>
            <Form.Control
                type="text"
                value={companyRole}
                onChange={(e)=>setCompanyRole(e.target.value)}
            />
            </Form.Group>
        </Form>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
            Close
        </Button>
        <Button variant="primary" onClick={() => {editRequest()}}>
            Save Changes
        </Button>
        </Modal.Footer>
    </Modal>
  )
}

export default EditUserRequestModal