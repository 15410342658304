import React from 'react'
import { Row, Col } from 'react-bootstrap'
import "../assets/css/newsPriview.css"

function NewsPreview(props) {
    return (
        <div>
            <div className='main-div'>
                <Row>
                <img src={props.img} style={{height: "250px", objectFit: "fill"}}></img>
                </Row>
                <br></br>
                <Row>
                <h4 className='title-text'>{props.text}</h4>
                </Row>
                <Row>
                    <p style={{overflowY: "hidden", maxHeight: "100px"}}>{props.desc}</p>
                </Row>
                <div style={{position: "absolute", bottom: "0", textAlign: "left", width: "100%"}}>
                    <Row>
                        <Col md={4}>
                            <p>{props.date}</p>
                        </Col>
                        <Col md={4} />
                        <Col md={4}>
                            <a href={props.link}>Saznaj više</a>
                        </Col>
                    </Row>
                    <hr></hr> 
                </div>
            </div>
        </div>
    )
}

export default NewsPreview
