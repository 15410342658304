import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";

function ProgramSabora2023() {
  return (
    <Container>
      <Row>
        <div style={{ textAlign: "center" }}>
          <Col md={12}>
            <h2>XXXIV Sabor Geodeta Srbije</h2>
          </Col>
        </div>
      </Row>
      <Row>
        <Col md={3}>
          <i>Savez geodeta Srbije</i>
          <br></br>
          <i>23.08.2023.</i>
        </Col>
        <Col md={6}></Col>
        <Col md={3}></Col>
      </Row>
      <hr></hr>
      <Row>
        <Col md={5}>
          <br></br>
          <div className="text-center" style={{ height: "auto", width: "100%" }}>
            <img
              style={{ height: "auto", width: "100%", paddingTop: "40px" }}
              src="../assets/img/savez-geodeta-srbije-2.jpeg"
            ></img>
            <br></br>
            <i></i>
          </div>
        </Col>
        <Col md={7}>
          <p></p>
          <p style={{ textAlign: "justify" }}>Poštovane kolege,</p>
          <p style={{ textAlign: "justify" }}>
            Pozivamo vas na Sabor geodeta Srbije u organizaciji Saveza geodeta Srbije, koji će se
            održati od 15. do 17. septembra u hotelu Junior u Brzeću. Dođite da zajedno sa ostalim
            kolegama provedemo kvalitetno vreme u druženju, zabavi i sportu.
          </p>
          <p style={{ textAlign: "justify" }}>
            Potrudili smo se da na Saboru čujete i nešto korisno i inspirativno iz naše struke. Zato
            je tu i radni deo Sabora, koji je ovog puta posvećen novim mogućnostima i izazovima sa
            kojima se naša struka suočava. U okviru radnog dela imaćemo zanimljive predavače koji će
            izložiti svoje viđenje geodezije danas.
          </p>
          <p style={{ textAlign: "left" }}>
            Ovde možete preuzeti{" "}
            <a href="../assets/media/Prijava-za-rezervaciju-hotela.doc" target="_blank">
              Prijavu za Sabor.
            </a>
          </p>
          <p style={{ textAlign: "left" }}>
            Ovde možete preuzeti{" "}
            <a href="../assets/media/Program-sabora.pdf" target="_blank">
              Program Sabora.
            </a>
          </p>
          <p style={{ textAlign: "left" }}>vidimo se!</p>
        </Col>
      </Row>
      <Row style={{ paddingTop: "40px" }} className="justify-content-evenly">
        <Col md={5}>
          <div
            className="text-center d-flex"
            style={{
              height: "auto",
              width: "100%",
              paddingBottom: "40px",
              alignItems: "center",
            }}
          >
            <div style={{ textAlign: "justify" }}>
              <img
                style={{ height: "auto", width: "10%", paddingBottom: "20px" }}
                src="../assets/img/icons/wine-glasses.png"
                alt="Wine Glasses"
              />
              <p style={{ fontWeight: "bold" }}>Druženje i zabava</p>
              <p>
                Sve više vremena posvećujemo poslu, a sve manje druženju i zabavi. Zato su sportski
                i zabavni program neizostavni deo Sabora. Prve večeri je organizovano druženje
                dobrodošlice u diskoteci hotela, a druge večeri svečana večera za sve učesnike
                Sabora.
              </p>
            </div>
          </div>
          <div
            className="text-center d-flex"
            style={{
              height: "auto",
              width: "100%",
              paddingBottom: "40px",
              alignItems: "center",
            }}
          >
            <div style={{ textAlign: "justify" }}>
              <img
                style={{ height: "auto", width: "10%", paddingBottom: "20px" }}
                src="../assets/img/icons/presentation.png"
                alt="Wine Glasses"
              />
              <p style={{ fontWeight: "bold" }}>Predavanja</p>
              <p>
                Stručna predavanja biće posvećena sveobuhvatnoj integraciji geodezije, gde će biti
                reči o:
              </p>
              <ul style={{ textAlign: "left", paddingTop: "0px" }}>
                <li style={{ textAlign: "left", paddingBottom: "10px" }}>
                  integracija geodezije sa ostalim strukama,
                </li>
                <li style={{ textAlign: "left", paddingBottom: "10px" }}>
                  integracija različitih izvora podataka i mernih tehnologija,
                </li>
                <li style={{ textAlign: "left", paddingBottom: "10px" }}>
                  integracija podataka u informacione sisteme i servise,
                </li>
                <li style={{ textAlign: "left", paddingBottom: "10px" }}>
                  integracija mladih kadrova u savremenu privrednu praksu.
                </li>
              </ul>
            </div>
          </div>
        </Col>
        <Col md={5}>
          <div
            className="text-center d-flex"
            style={{
              height: "auto",
              width: "100%",
              paddingBottom: "40px",
              alignItems: "center",
            }}
          >
            <div style={{ textAlign: "justify" }}>
              <img
                style={{ height: "auto", width: "10%", paddingBottom: "20px" }}
                src="../assets/img/icons/running.png"
                alt="Wine Glasses"
              />
              <p style={{ fontWeight: "bold" }}>Sport i rekreacija</p>
              <p>
                Sportska takmičenja su neizostavni deo Sabora. Prijavite ekipu za takmičenje u
                fudbalu, basketu, odbojci, stonom tenisu, pikadu ili šahu.
              </p>
            </div>
          </div>
          <div
            className="text-center d-flex"
            style={{
              height: "auto",
              width: "100%",
              paddingBottom: "40px",
              alignItems: "center",
            }}
          >
            <div style={{ textAlign: "justify" }}>
              <img
                style={{ height: "auto", width: "10%", paddingBottom: "20px" }}
                src="../assets/img/icons/tacheometer.png"
                alt="Wine Glasses"
              />
              <p style={{ fontWeight: "bold" }}>Predstavljanje geodetske opreme</p>
              <p>
                Svi značajni prodavci geodetske opreme izložiće sve novitete iz svoje ponude u holu
                hotela.
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default ProgramSabora2023;
