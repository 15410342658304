import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Slika1 from "../../assets/img/news/odbijanje-prodaje-licenci/N0.webp";

export default function OdbijanjeProdajeLicenci() {
  return (
    <Container>
      <Row>
        <div style={{ textAlign: "center" }}>
          <Col md={12}>
            <h2>Obaveštenje povodom odbijanja prodaje licenci za sertifikaciju</h2>
          </Col>
        </div>
      </Row>
      <Row>
        <Col md={3}>
          <i>Savez geodeta Srbije</i>
          <br></br>
          <i>16.07.2024.</i>
        </Col>
        <Col md={6}></Col>
        <Col md={3}></Col>
      </Row>
      <hr></hr>
      <Row>
        <Col md={1}></Col>
        <Col md={10}>
          <div className="text-center" style={{ height: "auto", width: "100%" }}>
            <img
              className="text-center"
              style={{ height: "auto", width: "50%" }}
              src={Slika1}
            ></img>
          </div>
        </Col>
        <Col md={1}></Col>
      </Row>
      <br></br>
      <Row>
        <Col md={2}></Col>
        <Col md={8}>
          <p style={{ textAlign: "justify" }}>Poštovane kolege,</p>
          <p style={{ textAlign: "justify" }}>
            Kao što ste već upoznati, Savez geodeta Srbije se već oglašavao i preduzimao akcije
            povodom situacije u vezi sertifikacije i implementacije servisa digitalnog geodetskog
            elaborata. U poslednjih nedelju dana došli smo do novih informacija koje su u najmanju
            ruku alarmantne i koje zahtevaju hitnu i odlučnu reakciju.
          </p>
          <p style={{ textAlign: "justify" }}>
            Naime, neke geodetske organizacije, koje su prošle proces setrifikacije, odbijene su
            prilikom zahteva za kupovinu aktivacije VPN naloga za pristup resursima Republičkog
            geodetskog zavoda (dalje: RGZ) za potrebe rada sa Modulom za geodetske organizacije.
            Odbijanje se desilo od strane privatne firme koja jedina za te potrebe ima ugovor sa
            RGZ, sa obrazloženjem da su od RGZ dobile spisak članica Geoudruženja i striktno
            uputstvo da licence mogu da prodaju isključivo i samo njima. Nadalje se navodi da u
            slučaju zahteva geodetske organizacije van tog udruženja, ta geodetska organizacija
            treba da se obrati tom udruženju radi obaveštenja o daljim koracima, pri čemu je
            priložen i kontakt mejl Geoudruženja.
          </p>
          <p style={{ textAlign: "justify" }}>
            Imajući u vidu da je pomenuto udruženje obično dobrovoljno udruženje građana i da
            člansvo u njemu ne može biti osnov za ostavrenje bilo kakvih prava u skladu sa važećom
            zakonskom regulativom, te da RGZ kao posebna republička organizacija i državni organ ne
            može samoinijacitivno, obligacionim poslom, bez zakonskog pravnog osnova prenositi svoje
            nadležnosti na bilo koje udruženje, smatramo da se ovakvim postupcima, u najmanju ruku,
            grubo krše osnovna prava na rad, zagarantovana Ustavom i zakonima naše države. Nadalje,
            ističemo da se radi o evidentnom, eklatantnom i neprimerenom pritisku i uceni na
            celokupnu geodetsku struku i sve naše kolege, a na štetu svih nas kao i građana i države
            Srbije.
          </p>
          <p style={{ textAlign: "justify" }}>
            Želimo da obavestimo stručnu javnost da je Savez geodeta Srbije, po ovom pitanju i u
            ovoj pravnoj stvari, već preduzeo određene korake, u skladu sa zakonom i svojim
            mogućnostima i da će u budućnosti nastaviti to da čini, a sve u cilju sprečavanja
            nezakonitog rada RGZ i diskriminacije naših kolega. Želimo da naglasimo, da kao krovna i
            jedina organizacija svih geodeta, Savez geodeta Srbije želi da digne glas i spreči bilo
            kakve negativne pojave u našoj struci koje štete ugledu i statusu struke. Ucena i
            diskriminacija ne sme da postoji bez obzira da li je neko član ovog ili onog udruženja
            građana i cilj nam je da pomognemo svim kolega bili oni članovi Saveza geodeta Srbije
            ili ne.
          </p>
          <p style={{ textAlign: "justify" }}>
            Zato pozivamo RGZ da povuče i stavi van snage tabelu sa spiskom i striktnim uputstvom da
            se licence prodaju samo određenim geodetskim organizacijama, te da profesionalno obavlja
            poslove koje su mu, kao državnom organu, zakonom stavljeni u nadležnost. Takođe pozivamo
            sve geodetske organizacije koje su odbijene od strane privatne firme prilikom nabavke
            aktivacije VPN naloga za pristup resursima RGZ, da nam se obrate kako bismo preduzeli
            sve zakonske i zdravorazumske korake u cilju sprečavanja ucene i diskriminacije
            geodetskih organizacija i kršenja osnovnog prava na rad zagarantovanog Ustavom i
            zakonima Republike Srbije.
          </p>
        </Col>
        <Col md={2}></Col>
      </Row>
    </Container>
  );
}
