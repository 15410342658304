import {useState, useEffect} from 'react'
import axios from 'axios'
import { url } from "../constants/constants";
import { Modal, Button, Form, FormGroup, ProgressBar, Tabs, Tab, Badge, Container, Alert, Spinner} from "react-bootstrap";
import { useTimer } from 'react-timer-hook';

function ConfirmMailModal(props) {

    const [code, setCode] = useState("");
    const [error, setError] = useState();
    const [resendAvailable, setResendAvailable] = useState(true);
    const [working, setWorking] = useState(false);
    const [sentCode, setSentCode] = useState(false);

    const dateObj = new Date();

    const {
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        resume,
        restart,
      } = useTimer({ dateObj , onExpire: () => {setResendAvailable(true); pause()} });
    

    function restartTimer(){
        const time = new Date();
        time.setSeconds(time.getSeconds() + 60);
        restart(time);
    }

    async function vote(){

    }

    async function sendCode(){
        restartTimer();
        const {data} = await axios.get(url + "api/sendemailcode/", {withCredentials: true});
        setSentCode(true);
        setError();
    }

    async function confirmCode(){
        setWorking(true);
        try{
            const {data} = await axios.post(url + "api/checkemailcode", {code: code}, {withCredentials: true});
            props.success();
            props.onHide();
            setWorking(false);
            return;
        } catch(err){
            setError(err.response.data);
            setSentCode(false);
            setWorking(false);
            return;
        }   
    }

    useEffect(()=>{
        pause();
    }, [])

    return (
        <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            Potvrdite Identitet
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {error && 
                <Alert variant="danger">{error}</Alert>
            }
            {sentCode &&
                <Alert variant="success">Poslat Vam je verifikacioni kod na email.</Alert>
            }
            <Container>
                <Form>
                    <p>Nakon što pritisnete dugme "Pošalji kod", verifikacioni kod će Vam biti poslat na email koji se koristi za potvrdu identiteta.</p>
                    <div style={{display: "flex"}}>
                        <Form.Control type="text" placeholder="Unesi kod" onChange={(e)=>{setCode(e.target.value)}}></Form.Control>
                        <Button variant="success" disabled={!resendAvailable} onClick={()=>{sendCode(); setResendAvailable(false)}}>{isRunning ? minutes + ":" + seconds : "Pošalji kod"}</Button>
                    </div>
                    <br></br>
                    <Button variant="success" onClick={()=>{confirmCode()}}>{working ? <Spinner animation="border" /> : "Prihvati"}</Button>
                </Form>
            </Container>
        </Modal.Body>
        </Modal>
    )
}

export default ConfirmMailModal
