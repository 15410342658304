import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Slika1 from "../../assets/img/news/predavanje-kartografija/1.webp";

function PredavanjeKartografija() {
  return (
    <Container>
      <Row>
        <div style={{ textAlign: "center" }}>
          <Col md={12}>
            <h2>
              Stručno predavanje na temu Web Kartografija: Od tradicionalnih
              karata do Web kartografskih aplikacija
            </h2>
          </Col>
        </div>
      </Row>
      <Row>
        <Col md={3}>
          <i>Savez geodeta Srbije</i>
          <br></br>
          <i>21.05.2024.</i>
        </Col>
        <Col md={6}></Col>
        <Col md={3}></Col>
      </Row>
      <hr></hr>
      <Row>
        <Col md={1}></Col>
        <Col md={10}>
          <div
            className="text-center"
            style={{ height: "auto", width: "100%" }}
          >
            <img
              className="text-center"
              style={{ height: "auto", width: "50%" }}
              src={Slika1}
            ></img>
          </div>
        </Col>
        <Col md={1}></Col>
      </Row>
      <br></br>
      <Row>
        <Col md={2}></Col>
        <Col md={8}>
          <p style={{ textAlign: "justify" }}>
            Savez geodeta Srbije ima zadovoljstvo da u saradnji sa Prof. Dr
            Milanom Kilibardom, diplomiranim inženjerom geodezije, organizuje
            stručno predavanje na temu Web Kartografija: Od tradicionalnih
            karata do Web kartografskih aplikacija.
          </p>
          <p style={{ textAlign: "justify" }}>
            Predavanje će se održati u utorak, 28.05. 2024. godine u
            prostorijama Saveza geodeta Srbije na adresi Kneza Miloša 7a u
            Beogradu, s početkom u 13 časova.
          </p>
          <p style={{ textAlign: "justify" }}>
            Ovo predavanje je otvoreno za sve zainteresovane, bez naknade za
            ulaz.
          </p>
          <p style={{ textAlign: "justify" }}>Dobrodošli!</p>
          <p style={{ textAlign: "justify" }}>Sadržaj predavanja:</p>
          <ol>
            <li>Web kartografija - uvod</li>
            <ul>
              <li>Terminologija</li>
              <li>Značajni događaji u razvoju Web kartografije</li>
              <li>Razvoj Web kartografije</li>
              <li>Vizija budućnosti Web kartografskih aplikacija</li>
            </ul>
            <li>Web kartografski servisi - uvod</li>
            <ul>
              <li>Tipična arhitektura Web kartografske aplikacije</li>
              <li>WMS, WFS, WCS, WPS</li>
            </ul>
            <li>Uvod u OpenLayers (web mapping client)</li>
            <li>
              Primer Web kartografske aplikacije razvijene od kompanije GiLAB.
            </li>
          </ol>
          <p style={{ textAlign: "justify" }}>
            Biografija:<br></br> Dr Milan Kilibarda je redovni profesor na
            Katedri za geodeziju i geoinformatiku, Građevinskog fakulteta
            Univerziteta u Beogradu. Njegova istraživačka delatnost usmerena je
            ka razvoju i unapređenju geoinformatičkih servisa primenom
            savremenih tehnologija i kreiranju novih kartografskih proizvoda.
            Aktivan je učesnik istraživačkih projekata vezanih za primenu
            mašinskog učenja nad podacima daljinske detekcije sa primenama u
            poljoprivredi, mapiranju zemljišta, mapiranju korišćenja/pokrivanja
            zemljišta, i modeliranju klimatskih promenljivih. Njegov glavni
            doprinos u naučnoj oblasti odnosi se na metodologiju
            prostorno-vremenskog kartiranja klimatskih promenljivih na globalnom
            nivou u visokoj prostorno-vremenskoj rezoluciji. Šef je Laboratorije
            za razvoj geoprostornih tehnologija otvorenog koda u Beogradu. Jedan
            je od osnivača i kreatora novog studijskog programa Geoinformatika
            na Građevinskom fakultetu. Kao profesor, odgovoran je za predmete
            vezane za Kartografiju, Geostatistiku i Prostorno-vremensko
            modeliranje geoprostornih promenljivih. Autor je udžbenika
            “Geovizualizacijai Web kartografija” i “Matematička kartografija“.
            Osnivač je kompanije GiLAB.
          </p>
        </Col>
        <Col md={2}></Col>
      </Row>
    </Container>
  );
}

export default PredavanjeKartografija;
