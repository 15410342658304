import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';

function RukovodstvoVest() {
    return (
        <Container>
            <Row>
                <div style={{textAlign: "center"}}>
                    <Col md={12}>
                        <h2>Novo rukovodstvo Saveza Geodeta Srbije</h2>
                    </Col>
                </div>
            </Row>
            <Row>
                <Col md={3}>
                    <i>Savez Geodeta Srbije</i>
                    <br></br>
                    <i>05.03.2022.</i>
                </Col>
                <Col md={6}></Col>
                <Col md={3}></Col>
            </Row>
            <hr></hr>
            <Row>
            <Col md={4}>
                    <div className="text-center" style = {{height: "auto", width: "100%"}}>
                        <img style = {{height: "auto", width: "100%"}} src="../assets/img/rukovodstvo.jpg"></img>
                        <br></br>
                        <i></i>
                    </div>
                </Col>
                <Col md={8}>
                    <p style={{textAlign: "justify"}}>
                    Na izbornoj skupštini Saveza Geodeta Srbije, održanoj 05.03.2022. izabrano je novo rukovodstvo ove asocijacije.
                    </p>
                    <p style={{textAlign: "justify"}}>
                    Za novog predsednika saveza je izabran doc. dr. Mladen Šoškić, dok je potpredsedsenik doc. dr. Milutin Pejović, a sekretar Kosta Mirković, diplomirani pravnik.
                    </p>
                    <p style={{textAlign: "justify"}}>
                    Pored predsednika, potpredsenika i sekretara Saveza izabrani su i ostali članovi upravnog odbora i to:
                        <ul>
                            <li>Zoran Jovanović - ispred Udruženja geodeta Centralne Srbije.</li>
                            <li>Velimir Jović - ispred Udruženja geodeta Timočke krajine - Zaječar.</li>
                            <li>Milan Vučićević ispred Udruženja geodeta Mačvanskog i Kolubarskog okruga - Šabac.</li>
                            <li>Miodrag Karić - ispred Udruženja geodeta Južni Banat - Pančevo.</li>
                            <li>Zoran Stojanović - ispred Udruženja geodeta Beograd</li>
                        </ul>
                    </p>
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <p style={{textAlign: "justify"}}>
                    Za članove Nadzornog odbora Saveza izabrani su:
                        <ul>
                            <li>Vladimir Jovanović</li>
                            <li>Vladimir Bulatović</li>
                            <li>Stanislav Vasiljević</li>
                        </ul>
                    </p>
                    <p style={{textAlign: "justify"}}>
                    Upravni odbor saveza će izvršiti izbor nacionalnih delegata Saveza Geodeta Srbije u međunarodnim komisijama FIG-a
                    </p>
                </Col>
                <Col md={4}>
                    <p style={{textAlign: "justify"}}>
                    Za članove suda časti izabrani su:
                        <ul>
                            <li>Slaviša Ilić</li>
                            <li>Milan Arsenović</li>
                            <li>Miloš Vidulović</li>
                        </ul>
                    </p>
                </Col>
                <Col md={4}>
                <div className="text-center" style = {{height: "auto", width: "100%"}}>
                        <img style = {{height: "auto", width: "100%"}} src="../assets/img/rukovodstvoVest.jpg"></img>
                        <br></br>
                        <i></i>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default RukovodstvoVest