import React, {useEffect, useState} from 'react';
import { Row } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';

function Biography(props) {

    const [text, setText] = useState(props.text);
    const [viewMore, setViewMore] = useState(false);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        if(text.length > 480){
            setText(text.substring(0, 480) + '...');
            setViewMore(true);
        }
    }, [])

  return (
    <div>
        <div className="biography-container">
            <Row>
                <img className="biography-image" src={props.img}></img>
            </Row>
            <Row>
                <div className="biography-info">
                    <h4 className="biography-name"><strong>{props.name}</strong></h4>
                    <h5 className="biography-position"><strong>{props.position}</strong></h5>
                    <h6 className="biography-title"><strong>{props.title}</strong></h6>
                </div>
            </Row>
            <Row>
                <p className="biography-text">
                    {text} 
                    {viewMore &&
                        <a href="#" onClick={()=>{handleShow()}}>Pogledaj više</a>
                    }
                </p>
            </Row>
        </div>
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>{props.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p style={{textAlign: "justify"}}>{props.text}</p>
            </Modal.Body>
      </Modal>
    </div>
  )
}

export default Biography