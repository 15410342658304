import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import "../../assets/css/predsednik.css";

function PredsednikScreen() {
  return (
    <Container>
        <Row>
            <Col md={2}></Col>
            <Col md={8}>
                <div style={{width: "100%", textAlign: "center"}}>
                    <img id="logo" src="/assets/img/logo.png" style = {{height: "auto", width: "23%"}}></img>
                </div>
            </Col>
            <Col md={2}></Col>
        </Row>
        <br></br>
        <Row>
            <Col md={3}></Col>
            <Col md={6}>
                <div style={{textAlign: "center", width: "100%"}}>
                    <h2 style={{fontSize: "24px"}}>Обраћање председника Савеза геодета Србије др Младена Шошкића</h2>
                </div>
            </Col>
            <Col md={3}></Col>
        </Row>
        <br></br>
        <Row>
            <Col md={2}>
            </Col>
            <Col md={8}>
                <div id="predsednik">
                    <img id="predsednik-img" src="/assets/img/mladen-img.jpg" style = {{height: "auto", width: "29%"}}></img>
                    <h5>Поштоване колеге,</h5>
                    <br></br>
                    <p>Желим да вас обавестим да је изабрано ново руководство Савеза геодета Србије (СГС) које је одлучно у намери да интензивира активности на унапређењу положаја наше струке. СГС има вишедеценијску традицију и представља једно од најстаријих струковних удружења у нашој земљи. У прошлости наш савез је прошао кроз бројна искушења, успоне и падове али је опстао што нам омогућава да започнемо нову еру деловања савеза за коју се надамо да ће бити успешна и на корист и задовољство свих геодета Србије, без обзира на то где они живели и радили.</p>
                </div>
            </Col>
            <Col md={2}>
            </Col>
        </Row>
        <br></br>
        <Row>
            <Col md={2}></Col>
            <Col md={8}>
                <div class="below-predsednik" style={{textAlign: "left", width: "100%"}}>
                    <p style={{textAlign: "justify"}}>СГС је збир свих нас који га чинимо, није ничији појединачно и сви заједно подједнако доприносимо његовој снази и значају. На чело СГС-а су дошли нови људи, са новим визијама и савременим идејама и што је најважније, са веровањем да геодезија као струка треба да буде на нивоу на којем заслужује да буде, раме уз раме са свим осталим техничким струкама. Стога, основни циљеви новог руководства у блиској будућности су подизање угледа наше струке, њене видљивости, препознатљивости и значаја.</p>
                </div>
            </Col>
            <Col md={2}></Col>
        </Row>
        <br></br>
        <Row>
            <Col md={2}></Col>
            <Col md={8}>
                <div class="below-predsednik" style={{textAlign: "left", width: "100%"}}>
                    <p style={{textAlign: "justify"}}>Све наведене циљеве и активности које ће пратити наш рад, не можемо да остваримо без активног учешћа што већег броја вас, наших колега. Једино ако представљамо значајан број људи из струке ми можемо да имамо снагу да остваримо ово што смо наумили. Са друге стране, ви ћете као пуноправни чланови СГС-а доприносити формулисању и усмеравању нашег деловања.</p>
                </div>
            </Col>
            <Col md={2}></Col>
        </Row>
        <br></br>
        <Row>
            <Col md={2}></Col>
            <Col md={8}>
                <div class="below-predsednik" style={{textAlign: "left", width: "100%"}}>
                    <p style={{textAlign: "justify"}}>Учлањењем у Савез Геодета Србије добијате могућност да активно утичете на развој струке, као и на свој лични развој кроз могућност учествовања на семинарима, панелима и скуповима који ће се бавити актуелностима из наше струке, као и у радним телима СГС-а која ће бити формирана за потребе учешћа у комисијама за писање правне регулативе. Поред тога, сваки члан ће моћи да изнесе своје предлоге, своје виђење проблема и начине њиховог решавања.</p>
                </div>
            </Col>
            <Col md={2}></Col>
        </Row>
        <br></br>
        <Row>
            <Col md={2}></Col>
            <Col md={8}>
                <div class="below-predsednik" style={{textAlign: "left", width: "100%"}}>
                    <p style={{textAlign: "justify"}}>Зато вас позивам да постанете чланови СГС-а како бисмо заједничким снагама могли да подигнемо углед наше струке, укажемо на њен значај и решимо добар део проблема који нас муче. Није битно где радите, да ли у приватним геодетским организација, јавним предузећима, грађевинским фирмама, државним управама, школству или било где друго, нити да ли сте техничари, инжењери, мастери или доктори наука. Циљ нам је да СГС буде организација која је сачињена од свих геодета без обзира где они радили и коју стручну спрему имали. Једино се тако може бранити струка и подизати њен углед.</p>
                </div>
            </Col>
            <Col md={2}></Col>
        </Row>
        <br></br>
        <Row>
            <Col md={2}></Col>
            <Col md={8}>
                <div class="below-predsednik" style={{textAlign: "left", width: "100%"}}>
                    <p style={{textAlign: "justify"}}><strong>Активности које планирамо да покренемо да бисмо остварили наведене циљеве су:</strong></p>
                    <ul>
                        <li><strong>Учешће у писању законске регулативе</strong> (закони, подзаконска акта, правилници...) која је везана за нашу струку. Сваки акт који на било који начин дотиче гедезију мора бити донешен уз активно учешће СГС-а, као релевантног представника струке. Поред тога, СГС је члан Савеза инжењера и техничара Србије што омогућава да и кроз ово удружење имамо приступ радним групама за писање правне регулативе.</li>
                        <li><strong>Организација разних видова стручних скупова.</strong> Савремене околности у којима радимо захтевају константно усавршавање, праћење нових технологија и трендова како код нас тако и у свету. Планирамо да под окриљем СГС-а организујемо бројне конференције, семинаре, саветовања и обуке што би омогућило свим члановима да буду у току и стичу нова знања из области које њих највише интересују. Ти скупови не би служили само стицању нових знања, већ и упознавању и повезивању нас међусобно. Контакти остварени на скуповима могу се показати значајним у пословима које ћемо у будућности радити.</li>
                        <li><strong>Организација саветовања у склопу перманентног (целоживотног) образовања.</strong> Као што многи од вас знају, у току је доношење правилника који се бави перманентним образовањем од стране надлежног министарства. То ће подразумевати и обавезу стицања одређених поена у циљу обнављања лиценци. СГС ће, за своје чланове, организовати такве скупове што ће омогућити свима да на ефикасан и лак начин дођу до потребних поена за обнављање лиценци.</li>
                        <li><strong>Учешће у свим телима, форумима и комисијама који се баве било којим аспектом наше струке.</strong> Нпр. тренутно, постоји иницијатива у Инжењерској комори Србије да се формирају ценовници за разне врсте геодетских радова и техничке документације. СГС намерава да узме активно учешће у тој и сличним комисијама јер сматрамо да је неопходно да се у једном тако значајном питању чује и глас струке.</li>
                        <li><strong>Организација сабора геодета.</strong> Традиција организације сабора је једна од најлепших традиција нашег савеза коју намеравамо да одржимо и коју љубоморно чувамо. Ми смо једина инжењерска струка која има ову врсту окупљања и по којој смо чувени. Сабор је много више од самог дружења и одржавања предавања. Ту се ми геодете упознајемо, размењујемо искуства и, у пријатној атмосфери, чинимо много добрих ствари за нас појединачно и струку у целини.</li>
                        <li><strong>Чланство у ФИГ-и (Међународна организација геодета).</strong> СГС је једина чланица ФИГ-а из наше земље. Чланство у овој организацији је јако битно за све нас и даје нам препознатљивост у међународним оквирима. Оно нам омогућава да будемо у току са развојем наше струке али и ствара обавезу да знања и препоруке ФИГ-е преносимо колегама у Србији. Поред тога, чланством у ФИГ-и стварамо контакте са европским и светским удружењима чија помоћ може бити драгоцена у остваривању наших циљева.</li>
                    </ul>
                </div>
            </Col>
            <Col md={2}></Col>
        </Row>
        <br></br>
        <Row>
            <Col md={2}></Col>
            <Col md={8}>
                <div class="below-predsednik" style={{textAlign: "left", width: "100%"}}>
                    <p style={{textAlign: "justify"}}>Познато вам је, да би савез функционисао, потребна су финансијска средства која се могу прикупити путем чланарине и донација. Све геодете, без обзира на то где раде и које су стручне спреме, су добродошли да се учлане у СГС без плаћања чланарина до краја текуће године. Са друге стране, очекујемо од геодетских организација да финансијски помогну СГС тако што ће се као правна лица учланити и плаћати надокнаду у складу са њиховом снагом и бројем запослених. Свим геодетским организацијама, на њихов захтев, доставићемо уговор на потпис. Појединачним колегама, на њихов захтев, доставићемо приступницу о учлањењу.</p>
                </div>
            </Col>
            <Col md={2}></Col>
        </Row>
        <br></br>
        <Row>
            <Col md={2}></Col>
            <Col md={8}>
                <div class="below-predsednik" style={{textAlign: "left", width: "100%"}}>
                    <h4>Спремни за приступање Савезу?</h4>
                    <p style={{textAlign: "justify"}}>Захтев за чланством за свега неколико тренутака можете поднети кликом на линк испод:</p>
                </div>
                <div style={{textAlign: "center", width: "100%"}}>
                    <a href='https://savezgeodeta.rs/pristup' target="_blank"><Button id="btn-savez" variant='success' style={{height: "70px", width: "50%"}}>Постани члан Савеза геодета Србије</Button></a>
                </div>
                <br></br>
                <div class="below-predsednik" style={{textAlign: "left", width: "100%"}}>
                    <p style={{textAlign: "justify"}}>Ново руководство СГС-а чине: др Младен Шошкић, дипл. геод. инж. (председник), др Милутин Пејовић, дипл. геод. инж. (потпредседник) и мр Коста Мирковић, дипл. правник (секретар). Поред новог руководства, изабрани су и други органи Савеза геодета Србије, као што су Управни одбор, Надзорни одбор и чланови Суда части.</p>
                    <br></br>
                    <p style={{textAlign: "justify"}}>За било каква питања, сугестије, предлоге као и детаље о начину учлањења стојимо вам на располагању. Можете нас контактирати на наведени е-маил. За све информације о новом руководству и свим актуелностима и активностима Савеза посетите наш нови сајт Савеза Геодета Србије: <a href='https://savezgeodeta.rs/' target="_blank">https://savezgeodeta.rs/</a></p>
                </div>
            </Col>
            <Col md={2}></Col>
        </Row>
        <br></br>
        <Row>
            <Col md={2}></Col>
            <Col md={8}>
                <div class="below-predsednik" style={{textAlign: "left", width: "100%"}}>
                    <p style={{textAlign: "justify"}}>Београд, 18.05.2022.</p>
                    <p style={{textAlign: "justify"}}>године</p>
                </div>
            </Col>
            <Col md={2}></Col>
        </Row>
        <br></br>
        <Row>
            <Col md={2}></Col>
            <Col md={8}>
                <div style={{textAlign: "center", width: "100%"}}>
                    <img id="predsednik-potpis" src="/assets/img/predsednik-potpis.png" style = {{height: "auto", width: "20%"}}></img>
                    <p></p>
                    <p style={{textDecoration: "underline"}}><strong>др Младен Шошкић, дипл. геод. инж.</strong></p>
                    <p><strong>Председник савеза геодета Србије,</strong></p>
                </div>
            </Col>
            <Col md={2}></Col>
        </Row>
    </Container>
  )
}

export default PredsednikScreen