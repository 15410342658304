import React from "react";
import { Row, Col, Container, Table } from "react-bootstrap";
import Slika00 from "../../assets/img/news/sabor-kop/00.jpg";
import Slika01 from "../../assets/img/news/sabor-kop/01.jpg";
import Slika02 from "../../assets/img/news/sabor-kop/02.jpg";
import Slika03 from "../../assets/img/news/sabor-kop/03.jpg";
import Slika04 from "../../assets/img/news/sabor-kop/04.jpg";
import Slika05 from "../../assets/img/news/sabor-kop/05.jpg";
import Slika06 from "../../assets/img/news/sabor-kop/06.jpg";
import Slika07 from "../../assets/img/news/sabor-kop/07.jpg";
import { Carousel } from "react-bootstrap";

function SaborKopaonik() {
  return (
    <Container>
      <Row>
        <div style={{ textAlign: "center" }}>
          <Col md={12}>
            <h2>ODRŽAN XXXIV SABOR GEODETA SRBIJE</h2>
          </Col>
        </div>
      </Row>
      <Row>
        <Col md={3}>
          <i>Savez geodeta Srbije</i>
          <br></br>
          <i>21.09.2023.</i>
        </Col>
        <Col md={6}></Col>
        <Col md={3}></Col>
      </Row>
      <hr></hr>
      <Row>
        <Col md={1}></Col>
        <Col md={10}>
          <div
            className="text-center"
            style={{ height: "auto", width: "100%" }}
          >
            <img
              className="text-center"
              style={{ height: "auto", width: "70%" }}
              src={Slika00}
            ></img>
          </div>
        </Col>
        <Col md={1}></Col>
      </Row>
      <br></br>
      <Row>
        <Col md={2}></Col>
        <Col md={8}>
          <p style={{ textAlign: "justify" }}>
            Sabor je otvorio Mr Kosta Mirković, dipl. pravnik, sekretar Saveza,
            koji je posle pozdrava svih prisutnih, posebno pozdravio goste iz
            inostranstva, i to: predsednika Komore trgovaca, ovlašćenih geodeta
            i trgovačkih društava za geodetske radove Republike Makedonije, iz
            Republike Severne Makedonije, g-dina Mr Nikolu Ribaroskog i
            predsednika Komore privatnih geodetskih firmi iz Republike Bugarske,
            g-dina Cvetana Georgijeva.
          </p>
          <p style={{ textAlign: "justify" }}>
            Od 15. do 17. septembra 2023. godine, u mestu Brzeće na Kopaoniku, u
            hotelu Junior, u organizaciji Saveza geodeta Srbije, održan je XXXIV
            Sabor geodeta Srbije. Prijemom gostiju u hotel, u poslepodnevnim
            časovima (petak), započeta je tradicija godišnjeg okupljanja,
            druženja i razmene iskustava geodeta iz Srbije i iz regije, koja je
            do kraja provedena u izuzetno prijatnom ambijentu i odličnom
            raspoloženju. Drugog dana (subota) rad Sabora započeo je sportskim
            takmičenjima i nastavljen je radnim delom - svečanim otvaranjem
            Sabora, pozdravnom rečju rukovodstva Saveza i gostiju.
          </p>
        </Col>
        <Col md={2}></Col>
      </Row>
      <br></br>
      <Row>
        <Col md={1}></Col>
        <Col md={10}>
          <div
            className="text-center"
            style={{ height: "auto", width: "100%" }}
          >
            <img
              className="text-center"
              style={{ height: "auto", width: "70%" }}
              src={Slika04}
            ></img>
          </div>
        </Col>
        <Col md={1}></Col>
      </Row>
      <br></br>
      <Row>
        <Col md={2}></Col>
        <Col md={8}>
          <p style={{ textAlign: "justify" }}>
            Potom se prisutnima na Saboru obratio predsednik Saveza Doc. dr
            Mladen Šoškić, dipl.geod.inž., koji je posle pozdravne reči,
            taksativno prezentovao koje je sve ciljeve Savez realizovao i
            zadatke izvršio i koji zadaci u budućnosti predstoje Savezu za
            izvršenje. Posebno je apostrofirao održani sastanak sa ministrom
            građevinarstva, saobraćaja i infrastrukture u vezi postignutog
            dogovora potrebe, kao i sadržaja i forme izrade geodetskog snimka,
            koji će raditi geodetske firme, u postupku prikupljanja
            dokumentacije za dobijanje privremenog priključka na nelegalne
            objekte.
          </p>
          <p style={{ textAlign: "justify" }}>
            Pozdravnom rečju prisutnoma na Saboru obratili su se i gosti iz
            inostranstva.
          </p>
          <p style={{ textAlign: "justify" }}>
            Stručni deo radnog dela Sabora vodio je Doc. dr Milutin Pejović,
            dipl.geod.inž.
          </p>
          <p style={{ textAlign: "justify" }}>
            Tema radnog dela Sabora bila je „Integracija geodezije i drugih
            struka“ u okviru koje su izuzetno stručni i zanimljivi predavači
            pojedinačno izlagali i prezentovali odabrane teme, i to:
          </p>
        </Col>
        <Col md={2}></Col>
      </Row>
      <br></br>
      <Row>
        <Col md={2}></Col>
        <Col md={8}>
          <Table bordered hover>
            <thead>
              <tr>
                <th>Tema:</th>
                <th>Predavač:</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Integracija struka i podataka</th>
                <th>Prof. dr Branislav Bajat, dipl.geod.inž.</th>
              </tr>
              <tr>
                <td>
                  Uloga geodete u projektovanju složenih hidrotehničkih objekata
                </td>
                <td>Mileta Bojović, dipl.geod.inž.</td>
              </tr>
              <tr>
                <td>Geodezija i precizna poljoprivreda – primeri iz prakse</td>
                <td>Ljubiša Adžemović, dipl.geod.inž.</td>
              </tr>
              <tr>
                <td>Oblikovanje svih faza projekata</td>
                <td>Marko Panić, dipl.geod.inž.</td>
              </tr>
              <tr>
                <td>
                  Integracija geodetskog stručnjaka u savremenu geodetsku praksu
                </td>
                <td>Vladimir Jovanović, dipl.geod.inž.</td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col md={2}></Col>
      </Row>
      <br></br>
      <Row>
        <Col md={2}></Col>
        <Col md={8}>
          <p style={{ textAlign: "justify" }}>
            Posle završetka radnog dela Sabora, nastavljeno je sa sportskim
            takmičenjima u šahu, stonom tenisu, malom fudbalu, basketu, pikadu i
            dr. sportovima, a vreme za slobodne aktivnosti učesnici su koristili
            za šetnju u prirodi.
          </p>
          <p style={{ textAlign: "justify" }}>
            U holu hotela gosti su imali priliku i mogućnost da se upoznaju sa
            asortimanom geodetske opreme Izlagača: Livon, Vekom i Atlas.
          </p>
          <p style={{ textAlign: "justify" }}>
            Za vreme svečane večere pobednicima sportskih takmičenja uručeni su
            pehari, dok su diplome dodeljene organizacijam i pojedincima koji su
            doprineli da se Savez ojača i osnaži, a tradicija okupljanja održi i
            ove godine.
          </p>
          <p style={{ textAlign: "justify" }}>
            Na kraju, predsednok Saveza najavio je da će se Sabor održati i
            sledeće godine, te da već ima prijavljenih kandidata za njegovu
            organizaciju i omasovljenje.
          </p>
        </Col>
        <Col md={2}></Col>
      </Row>
      <br></br>
      <Row>
        <Col md={2}></Col>
        <Col md={8}>
          <h4>
            Sledi tabela sa rezultatima sportskog takmičenja, kao i fotografije.
          </h4>
          <Table bordered hover>
            <tbody>
              <tr>
                <th>Pikado (muškarci)</th>
                <td>
                  <ol>
                    <li>Miroslav Crnobrnić, Geo-Kom, Beograd</li>
                    <li>Marko Stojanović, VGI, Beograd</li>
                    <li>Ivan Garić, VGI, Beograd</li>
                  </ol>
                </td>
              </tr>
              <tr>
                <th>Pikado (žene)</th>
                <td>
                  <ol>
                    <li>Lidija Stevanović, Gaus Geo-kom, Beograd</li>
                    <li>Gordana Mijajlović, Map-Soft, Beograd</li>
                    <li>Gordana Ilić Milovanović, Map-Soft, Beograd</li>
                  </ol>
                </td>
              </tr>
              <tr>
                <th>Stoni tenis (muškarci)</th>
                <td>
                  <ol>
                    <li>Banković Radoje, VGI, Beograd</li>
                    <li>Dragan Marković, GU za GZ i Invesicije,Novi Sad</li>
                    <li>Marko Stojanović, VGI, Beograd</li>
                  </ol>
                </td>
              </tr>
              <tr>
                <th>Stoni tenis (žene)</th>
                <td>
                  <ol>
                    <li>Miladinović Vesna, VGI</li>
                    <li>Gordana Mijajlović, Map-Soft,Beograd</li>
                    <li>Iva Bilalović, Gaus geo-kom, Beograd</li>
                  </ol>
                </td>
              </tr>
              <tr>
                <th>Šah (muškarci)</th>
                <td>
                  <ol>
                    <li>Božidar Krekić, Energoprojekt</li>
                    <li>Marinković Ljubo, GR „Marinković“</li>
                    <li>Željko Tadić, Elektroistok – projektni biro</li>
                  </ol>
                </td>
              </tr>
              <tr>
                <th>Šah (žene)</th>
                <td>
                  <ol>
                    <li>Jovana Kuka, VGI</li>
                    <li>Karović Ivana, Evrogeomatika</li>
                    <li>Otašević Aleksandra, MO</li>
                  </ol>
                </td>
              </tr>
              <tr>
                <th>Basket</th>
                <td>
                  <ol>
                    <li>Građevinski fakultet,Beograd</li>
                    <li>VGI 1, Beograd</li>
                    <li>Evrogeomatika -EGM , Beograd</li>
                  </ol>
                </td>
              </tr>
              <tr>
                <th>Mali fudbal</th>
                <td>
                  <ol>
                    <li>VGI,Beograd</li>
                    <li>Gaus geo-kom, Beograd</li>
                    <li>MapSoft, Beograd</li>
                  </ol>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col md={2}></Col>
      </Row>
      <br></br>
      <Row>
        <Col md={2}></Col>
        <Col md={8}>
          <Carousel>
            <Carousel.Item>
              <img className="d-block w-100" src={Slika02} alt="First slide" />
              <a
                className="carousel-control-prev"
                role="button"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon bi bi-chevron-left"
                  aria-hidden="true"
                />
              </a>
              <a
                className="carousel-control-next"
                role="button"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon bi bi-chevron-right"
                  aria-hidden="true"
                />
              </a>
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={Slika03} alt="Second slide" />
              <a
                className="carousel-control-prev"
                role="button"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon bi bi-chevron-left"
                  aria-hidden="true"
                />
              </a>
              <a
                className="carousel-control-next"
                role="button"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon bi bi-chevron-right"
                  aria-hidden="true"
                />
              </a>
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={Slika01} alt="Tird slide" />
              <a
                className="carousel-control-prev"
                role="button"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon bi bi-chevron-left"
                  aria-hidden="true"
                />
              </a>
              <a
                className="carousel-control-next"
                role="button"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon bi bi-chevron-right"
                  aria-hidden="true"
                />
              </a>
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={Slika05} alt="Fourth slide" />
              <a
                className="carousel-control-prev"
                role="button"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon bi bi-chevron-left"
                  aria-hidden="true"
                />
              </a>
              <a
                className="carousel-control-next"
                role="button"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon bi bi-chevron-right"
                  aria-hidden="true"
                />
              </a>
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={Slika06} alt="Fifth slide" />
              <a
                className="carousel-control-prev"
                role="button"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon bi bi-chevron-left"
                  aria-hidden="true"
                />
              </a>
              <a
                className="carousel-control-next"
                role="button"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon bi bi-chevron-right"
                  aria-hidden="true"
                />
              </a>
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={Slika07} alt="Sixth slide" />
              <a
                className="carousel-control-prev"
                role="button"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon bi bi-chevron-left"
                  aria-hidden="true"
                />
              </a>
              <a
                className="carousel-control-next"
                role="button"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon bi bi-chevron-right"
                  aria-hidden="true"
                />
              </a>
            </Carousel.Item>
          </Carousel>
        </Col>
        <Col md={2}></Col>
      </Row>
    </Container>
  );
}

export default SaborKopaonik;
