import React, {useState, useEffect, useRef} from 'react'
import { Container, Pagination, Form, Button, Alert, Spinner } from 'react-bootstrap'
import PublicMessage from '../components/PublicMessage'
import { useSearchParams} from 'react-router-dom';
import axios from 'axios';
import { url } from '../constants/constants';

function PublicMessagesScreen() {
    const [pages, setPages] = useState();
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState(parseInt(searchParams.get('page')) || 1);
    const [numberOfPages, setNumberOfPages] = useState(0);
    const [messages, setMessages] = useState();
    const [error, setError] = useState();
    const [inputMessage, setInputMessage] = useState();
    const [working, setWorking] = useState(false);
    const [workingDelete, setWorkingDelete] = useState(false);
    const [role, setRole] = useState("user");
    const textAreaRef = useRef(null);

    async function getMessages() {
        try {
            const {data} = await axios.get(`${process.env.REACT_APP_API_URL}api/getmessages?page=${currentPage}`, {withCredentials: true});
            setMessages(data.messages);
            setNumberOfPages(data.numberOfPages);
        } catch (error) {
            setError(error.message);
        }
    }
    async function getRole(){
        const {data} = await axios.get(url + 'api/getrole', {withCredentials: true});
        setRole(data);
    }

    async function deleteMessage(id){
        try{
            setWorkingDelete(true);
            const {data} = await axios.post(url + "api/deletemessage/", {messageId: id},{withCredentials: true});
            window.location.reload();
            setWorkingDelete(false);
        } catch(e){
            console.log(e);
            setError(e.message);
            setWorkingDelete(false);
        }
    }

    async function sendMessage(){
        if(!inputMessage){
            return;
        }
        try {
            setWorking(true);
            const {data} = await axios.post(`${url}api/sendmessage`, {message: inputMessage},{withCredentials: true});
            getMessages();

            textAreaRef.current.value = '';

            setWorking(false);
        } catch (error) {
            setError(error.message);
            setWorking(false);
        }
    }

    async function checkLogIn(){
        const {data} = await axios.get(url + 'api/isauth', {withCredentials: true})
        if(data == false){
            window.location.href="/";
        }
    }

    useEffect(() => {
        getRole();
        if(!messages) {
            getMessages();
        }
        if(numberOfPages > 0 && !pages){
            let pagesList = [];
            for(let i = 1; i <= numberOfPages; i++){
                pagesList.push(i);
            }
            setPages(pagesList);
        }
        
    }, [numberOfPages]);


    return (
        <Container>
            {error &&
                <Alert variant="danger">{error}</Alert>
            }
            {messages &&
                messages.map(message => {
                    return <PublicMessage key={message._id} firstName={message.firstName} lastName={message.lastName} message={message.message} date={message.date} time={message.time} role={role} working={workingDelete} deleteFunction={deleteMessage} messageId={message._id}/>    
                })
            }
            <hr></hr>
            {pages && 
            <div style={{float: "right"}}>
                <Pagination size="sm">
                    {
                        pages.map(page => {
                            return (
                                <Pagination.Item key={page} active={page === currentPage} onClick={() => window.location.href= "/beleznica?page="+page}>
                                    {page}
                                </Pagination.Item>
                            )
                        })
                    }
                </Pagination>
            </div>
            }
            <Form>
                <Form.Control ref={textAreaRef} as="textarea" rows="3" placeholder={role===false ? "Morate biti član da bi ste mogli da šaljete poruke." : "Poruka..."} onChange={(e) => setInputMessage(e.target.value)} disabled={role === false ? true : false}></Form.Control>
                <Button variant="success" style={{marginTop: 5, float: "right"}} onClick={sendMessage} disabled={role === false ? true : false}>{working ? <Spinner animation="border" /> : "Pošalji" }</Button>
            </Form>
        </Container>
    )
}

export default PublicMessagesScreen