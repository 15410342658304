import React, {useState} from 'react'
import '../assets/css/voting.css'

function VotingOption(props) {
    return (
        <div style={{paddingBottom: "10px"}}>
            <div className={props.classProp} onClick={()=> {props.callback(props.index, props.k)}}>
                {props.answer}
            </div>
        </div>
    )
}

export default VotingOption
