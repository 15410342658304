import React from 'react'
import { Row, Col, Container } from 'react-bootstrap';
import prisustvoSekretara from "../../assets/img/prisustvo-pravnika.webp"

function PrisustvoSekretara() {
  return (
    <Container>
        <Row>
            <div style={{textAlign: "center"}}>
                <Col md={12}>
                    <h2>Sekretar Saveza geodeta Srbije biće prisutan u prostorijama Saveza</h2>
                </Col>
            </div>
        </Row>
        <Row>
            <Col md={3}>
                <i>Savez geodeta Srbije</i>
                <br></br>
                <i>11.04.2023.</i>
            </Col>
            <Col md={6}></Col>
            <Col md={3}></Col>
        </Row>
        <hr></hr>
        <Row>
            <Col md={5}>
                <br></br>
                <div className='text-center' style = {{height: "auto", width: "100%"}}>
                    <img className='text-center' style = {{height: "auto", width: "70%"}} src={prisustvoSekretara} alt='Sekretar Saveza geodeta Srbije biće prisutan u prostorijama Saveza'></img>
                </div>
            </Col>
            <Col md={7}>
                <p></p>
                <p style={{textAlign: "justify"}}>
                Sekretar Saveza geodeta Srbije, diplomirani pravnik Kosta Mirković, biće prisutan u prostorijama Saveza (Kneza Miloša 9, sprat 4, Beograd),svakog četvrtka od 11 do 13 časova.</p>
                <p style={{textAlign: "justify"}}>Ukoliko imate bilo kakva pitanja, nedoumice ili želite da razgovarate o nekom aspektu rada Saveza, dobrodošli ste.</p>
            </Col>
        </Row>
    </Container>
  )
}

export default PrisustvoSekretara