import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';

function SaborGeodetaXXXIIIScreen() {
  return (
    <div>
        <Row>
                <div style={{textAlign: "center"}}>
                    <Col md={12}>
                        <h2>XXXIII САБОР ГЕОДЕТА СРБИЈЕ</h2>
                    </Col>
                </div>
            </Row>
            <Row>
                <Col md={3}>
                    <i>Савез геодета Србије</i>
                    <br></br>
                    <i>08.7.2022.</i>
                </Col>
                <Col md={6}></Col>
                <Col md={3}></Col>
            </Row>
            <hr></hr>
        <title> SGS XXXIII-Glavna tema Sabora </title>
        {/*[if !mso]><!*/}
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        {/*<![endif]*/}
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <style type="text/css" dangerouslySetInnerHTML={{__html: "\n    #outlook a {\n      padding: 0;\n    }\n\n    body {\n      margin: 0;\n      padding: 0;\n      -webkit-text-size-adjust: 100%;\n      -ms-text-size-adjust: 100%;\n    }\n\n    table,\n    td {\n      border-collapse: collapse;\n      mso-table-lspace: 0pt;\n      mso-table-rspace: 0pt;\n    }\n\n    img {\n      border: 0;\n      height: auto;\n      line-height: 100%;\n      outline: none;\n      text-decoration: none;\n      -ms-interpolation-mode: bicubic;\n    }\n\n    p {\n      display: block;\n      margin: 13px 0;\n    }\n  " }} />
        {/*[if mso]>
        <noscript>
        <xml>
        <o:OfficeDocumentSettings>
          <o:AllowPNG/>
          <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
        </xml>
        </noscript>
        <![endif]*/}
        {/*[if lte mso 11]>
        <style type="text/css">
          .mj-outlook-group-fix { width:100% !important; }
        </style>
        <![endif]*/}
        {/*[if !mso]><!*/}
        <link href="https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700" rel="stylesheet" type="text/css" />
        <style type="text/css" dangerouslySetInnerHTML={{__html: "\n    @import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700);\n  " }} />
        {/*<![endif]*/}
        <style type="text/css" dangerouslySetInnerHTML={{__html: "\n    @media only screen and (min-width:480px) {\n      .mj-column-per-20 {\n        width: 20% !important;\n        max-width: 20%;\n      }\n\n      .mj-column-per-100 {\n        width: 100% !important;\n        max-width: 100%;\n      }\n\n      .mj-column-per-90 {\n        width: 90% !important;\n        max-width: 90%;\n      }\n    }\n  " }} />
        <style media="screen and (min-width:480px)" dangerouslySetInnerHTML={{__html: "\n    .moz-text-html .mj-column-per-20 {\n      width: 20% !important;\n      max-width: 20%;\n    }\n\n    .moz-text-html .mj-column-per-100 {\n      width: 100% !important;\n      max-width: 100%;\n    }\n\n    .moz-text-html .mj-column-per-90 {\n      width: 90% !important;\n      max-width: 90%;\n    }\n  " }} />
        <style type="text/css" dangerouslySetInnerHTML={{__html: "\n    @media only screen and (max-width:480px) {\n      table.mj-full-width-mobile {\n        width: 100% !important;\n      }\n\n      td.mj-full-width-mobile {\n        width: auto !important;\n      }\n    }\n  " }} />
        <div style={{backgroundColor: '#f6f8f9'}}>
          {/*[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]*/}
          <div style={{margin: '0px auto', maxWidth: '600px'}}>
            <table align="center" border={0} cellPadding={0} cellSpacing={0} role="presentation" style={{width: '100%'}}>
              <tbody>
                <tr>
                  <td style={{direction: 'ltr', fontSize: '0px', padding: '10px 0 20px 0', textAlign: 'center'}}>
                    {/*[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr></tr></table><![endif]*/}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/*[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="#FFFFFF" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]*/}
          <div style={{background: '#FFFFFF', backgroundColor: '#FFFFFF', margin: '0px auto', maxWidth: '600px'}}>
            <table align="center" border={0} cellPadding={0} cellSpacing={0} role="presentation" style={{background: '#FFFFFF', backgroundColor: '#FFFFFF', width: '100%'}}>
              <tbody>
                <tr>
                  <td style={{direction: 'ltr', fontSize: '0px', padding: '20px 50px 0 20px', textAlign: 'center'}}>
                    {/*[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:106px;" ><![endif]*/}
                    <div className="mj-column-per-20 mj-outlook-group-fix" style={{fontSize: '0px', textAlign: 'left', direction: 'ltr', display: 'inline-block', verticalAlign: 'top', width: '100%'}}>
                      <table border={0} cellPadding={0} cellSpacing={0} role="presentation" style={{verticalAlign: 'top'}} width="100%">
                        <tbody>
                          <tr>
                            <td align="center" style={{fontSize: '0px', padding: '10px', wordBreak: 'break-word'}}>
                              <table border={0} cellPadding={0} cellSpacing={0} role="presentation" style={{borderCollapse: 'collapse', borderSpacing: '0px'}}>
                                <tbody>
                                  <tr>
                                    <td style={{width: '86px'}}>
                                      <img alt="Sabor logo" height="auto" src="https://bucket.mlcdn.com/a/3737/3737873/images/96db1c3665e43c5db3e3e46aef71c33a0bf23c76.jpeg" style={{border: 0, display: 'block', outline: 'none', textDecoration: 'none', height: 'auto', width: '100%', fontSize: '13px'}} width={86} />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {/*[if mso | IE]></td><td class="" style="vertical-align:top;width:530px;" ><![endif]*/}
                    <div className="mj-column-per-100 mj-outlook-group-fix" style={{fontSize: '0px', textAlign: 'left', direction: 'ltr', display: 'inline-block', verticalAlign: 'top', width: '100%'}}>
                      <table border={0} cellPadding={0} cellSpacing={0} role="presentation" style={{verticalAlign: 'top'}} width="100%">
                        <tbody>
                        </tbody>
                      </table>
                    </div>
                    {/*[if mso | IE]></td></tr></table><![endif]*/}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/*[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="#FFFFFF" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]*/}
          <div style={{background: '#FFFFFF', backgroundColor: '#FFFFFF', margin: '0px auto', maxWidth: '600px'}}>
            <table align="center" border={0} cellPadding={0} cellSpacing={0} role="presentation" style={{background: '#FFFFFF', backgroundColor: '#FFFFFF', width: '100%'}}>
              <tbody>
                <tr>
                  <td style={{direction: 'ltr', fontSize: '0px', padding: '0px 20px', textAlign: 'center'}}>
                    {/*[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:560px;" ><![endif]*/}
                    <div className="mj-column-per-100 mj-outlook-group-fix" style={{fontSize: '0px', textAlign: 'left', direction: 'ltr', display: 'inline-block', verticalAlign: 'top', width: '100%'}}>
                      <table border={0} cellPadding={0} cellSpacing={0} role="presentation" style={{verticalAlign: 'top'}} width="100%">
                        <tbody>
                          <tr>
                            <td align="center" style={{fontSize: '0px', padding: '0px 40px', wordBreak: 'break-word'}}>
                              <div style={{fontFamily: 'Ubuntu, Helvetica, Arial, sans-serif', fontSize: '28px', fontWeight: 700, lineHeight: '150%', textAlign: 'center', color: '#000000'}}>XXXIII САБОР ГЕОДЕТА СРБИЈЕ</div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {/*[if mso | IE]></td></tr></table><![endif]*/}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/*[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="#FFFFFF" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]*/}
          <div style={{background: '#FFFFFF', backgroundColor: '#FFFFFF', margin: '0px auto', maxWidth: '600px'}}>
            <table align="center" border={0} cellPadding={0} cellSpacing={0} role="presentation" style={{background: '#FFFFFF', backgroundColor: '#FFFFFF', width: '100%'}}>
              <tbody>
                <tr>
                  <td style={{direction: 'ltr', fontSize: '0px', padding: '5px', textAlign: 'center'}}>
                    {/*[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr></tr></table><![endif]*/}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/*[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" bgcolor="#FFFFFF" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]*/}
          <div style={{background: '#FFFFFF', backgroundColor: '#FFFFFF', margin: '0px auto', maxWidth: '600px'}}>
            <table align="center" border={0} cellPadding={0} cellSpacing={0} role="presentation" style={{background: '#FFFFFF', backgroundColor: '#FFFFFF', width: '100%'}}>
              <tbody>
                <tr>
                  <td style={{direction: 'ltr', fontSize: '0px', padding: '0 20px 30px 20px', textAlign: 'center'}}>
                    {/*[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:504px;" ><![endif]*/}
                    <div className="mj-column-per-90 mj-outlook-group-fix" style={{fontSize: '0px', textAlign: 'left', direction: 'ltr', display: 'inline-block', verticalAlign: 'top', width: '100%'}}>
                      <table border={0} cellPadding={0} cellSpacing={0} role="presentation" style={{verticalAlign: 'top'}} width="100%">
                        <tbody>
                          <tr>
                            <td align="center" style={{fontSize: '0px', padding: '10px 0px 0 10px', paddingTop: '10px', wordBreak: 'break-word'}}>
                              <div style={{fontFamily: 'Ubuntu, Helvetica, Arial, sans-serif', fontSize: '16px', fontWeight: 400, lineHeight: '24px', textAlign: 'center', color: '#6f6f6f'}}>"Zepter hotel Drina", Бајина Башта, 30. 9. - 2. 10. 2022. Пријаве су и даље отворене. <br />Овим путем информишемо Вас о теми Сабора и подсећамо да <br />резервишете своје место на време.</div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {/*[if mso | IE]></td></tr></table><![endif]*/}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/*[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><v:rect style="width:600px;" xmlns:v="urn:schemas-microsoft-com:vml" fill="true" stroke="false"><v:fill origin="0, -0.5" position="0, -0.5" src="https://bucket.mlcdn.com/a/3737/3737873/images/0bf1f7033bf6a94883a5bc8a6a28980ba8e689ff.jpeg" type="frame" size="1,1" aspect="atleast" /><v:textbox style="mso-fit-shape-to-text:true" inset="0,0,0,0"><![endif]*/}
          <div style={{background: 'url(https://bucket.mlcdn.com/a/3737/3737873/images/0bf1f7033bf6a94883a5bc8a6a28980ba8e689ff.jpeg) center top / cover no-repeat', backgroundPosition: 'center top', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', margin: '0px auto', maxWidth: '600px'}}>
            <div style={{lineHeight: 0, fontSize: 0}}>
              <table align="center" background="https://bucket.mlcdn.com/a/3737/3737873/images/0bf1f7033bf6a94883a5bc8a6a28980ba8e689ff.jpeg" border={0} cellPadding={0} cellSpacing={0} role="presentation" style={{background: 'url(https://bucket.mlcdn.com/a/3737/3737873/images/0bf1f7033bf6a94883a5bc8a6a28980ba8e689ff.jpeg) center top / cover no-repeat', backgroundPosition: 'center top', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', width: '100%'}}>
                <tbody>
                  <tr>
                    <td style={{direction: 'ltr', fontSize: '0px', padding: '0px', textAlign: 'center'}}>
                      {/*[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]*/}
                      <div className="mj-column-per-100 mj-outlook-group-fix" style={{fontSize: '0px', textAlign: 'left', direction: 'ltr', display: 'inline-block', verticalAlign: 'top', width: '100%'}}>
                        <table border={0} cellPadding={0} cellSpacing={0} role="presentation" style={{verticalAlign: 'top'}} width="100%">
                          <tbody>
                            <tr>
                              <td align="center" style={{fontSize: '0px', padding: '80px 15px 0px 15px', wordBreak: 'break-word'}}>
                                <div style={{fontFamily: 'Ubuntu, Helvetica, Arial, sans-serif', fontSize: '28px', fontWeight: 700, lineHeight: '150%', textAlign: 'center', color: '#FFFFFF'}}>Савремена пракса и изазови геодезије у инфраструктурним пројектима</div>
                              </td>
                            </tr>
                            <tr>
                              <td align="center" style={{fontSize: '0px', padding: '20px 15px 0px 15px', wordBreak: 'break-word'}}>
                                <div style={{fontFamily: 'Ubuntu, Helvetica, Arial, sans-serif', fontSize: '14px', fontWeight: 400, lineHeight: '150%', textAlign: 'center', color: '#FFFFFF'}}>Главна тема Сабора</div>
                              </td>
                            </tr>
                            <tr>
                              <td align="center" vertical-align="middle" style={{fontSize: '0px', padding: '10px 25px', paddingTop: '20px', paddingBottom: '100px', wordBreak: 'break-word'}}>
                                <table border={0} cellPadding={0} cellSpacing={0} role="presentation" style={{borderCollapse: 'separate', lineHeight: '100%'}}>
                                  <tbody><tr>
                                      <td align="center" bgcolor="#09c269" role="presentation" style={{border: 'none', borderRadius: '4px', cursor: 'auto', msoPaddingAlt: '15px 15px', background: '#09c269'}} valign="middle">
                                        <a href="https://savezgeodeta.rs/aktuelnosti/sabor-geodeta" style={{display: 'inline-block', background: '#09c269', color: '#ffffff', fontFamily: 'Ubuntu, Helvetica, Arial, sans-serif', fontSize: '16px', fontWeight: 700, lineHeight: '22px', margin: 0, textDecoration: 'none', textTransform: 'none', padding: '15px 15px', msoPaddingAlt: '0px', borderRadius: '4px'}} target="_blank"> Пријавите се </a>
                                      </td>
                                    </tr>
                                  </tbody></table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      {/*[if mso | IE]></td></tr></table><![endif]*/}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/*[if mso | IE]></td></tr></table><![endif]*/}
        </div>
      </div>
  )
}

export default SaborGeodetaXXXIIIScreen