import React, {useEffect, useState} from 'react'
import VotingOption from './VotingOption'

function VotingOptions(props) {
    const [selected, setSelected] = useState([])
    const [k, setK] = useState();

    useEffect(()=>{
        let temp =[];
        for(let i = 0; i < props.options.length; i++){
            temp.push("voting-unselected");
        }
        setK(temp);
        
    }, [])


    function callback(index){
        if(props.type == "one"){
            for(let i = 0; i < k.length; i++){
                k[i] = "voting-unselected";
            }
            k[index] = "voting-selected";
            setSelected(index)
            props.setSelected(index);
        } else if(props.type == "many"){
            if(selected.includes(index)){
                selected.pop(selected.indexOf(index));
                k[index] = "voting-unselected";
            } else{
                selected.push(index);
                k[index] = "voting-selected";
            }
            setSelected([...selected]);
            props.setSelected([...selected]);
        }
    }


    return (
        <div style={{textAlign: "center"}}>
            {k && props.options && props.options.map((option, index) => {
                return(
                    <VotingOption answer={option} index={index} classProp={k[index]} callback={callback}></VotingOption>
                );
            }) }
        </div>
    )
}

export default VotingOptions
