import React, {useEffect} from 'react';
import { saveAs } from "file-saver";

function DownloadComponent(props) {
    const saveFile = () => {
        saveAs(
          props.link, props.name
        );
    };
    useEffect(()=>{
        saveFile();
    }, [])
    return (
        <div>
        </div>
    )
}

export default DownloadComponent
