import React from 'react'
import '../assets/css/custom.css'
import '../assets/vendor/animate.css/animate.min.css';
import '../assets/vendor/bootstrap/css/bootstrap.min.css';
import '../assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../assets/vendor/boxicons/css/boxicons.min.css';
import '../assets/vendor/glightbox/css/glightbox.min.css';
import '../assets/vendor/swiper/swiper-bundle.min.css';
import '../assets/css/style.css';
function Footer() {
    return (
        <div>
            {/* ======= Footer ======= */}
            <footer id="footer">
                <div className="container">
                <h3>SGS</h3>
                <a href="/uslovi-koriscenja">Pravila korišćenja</a>
                <br></br>
                <br></br>
                <div className="social-links">
                    <a href="https://www.facebook.com/savezgeodetasrbije/" className="facebook" target="_blank"><i className="bi bi-facebook" /></a>
                    <a href="https://www.instagram.com/savezgeodeta/" className="instagram" target="_blank"><i className="bi bi-instagram" /></a>
                    <a href="https://www.linkedin.com/company/savez-geodeta-srbije/" className="linkedin" target="_blank"><i className="bi bi-linkedin" /></a>
                </div>
                <div className="copyright">
                    © Copyright <strong><span>SGS</span></strong>. All rights reserved.
                </div>
                <div className="credits">
                    {/* All the links in the footer should remain intact. */}
                    {/* You can delete the links only if you purchased the pro version. */}
                    {/* Licensing information: https://bootstrapmade.com/license/ */}
                    {/* Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/green-free-one-page-bootstrap-template/ */}
                    Developed by <a href="https://geotaur.com/">Geotaur</a>
                </div>
                </div>
            </footer>{/* End Footer */}
        </div>
    )
}

export default Footer
