import React, {useState, useEffect} from 'react'
import {Tabs, Tab, Form, Button, Container, Col, Row} from 'react-bootstrap';
import '../assets/css/forms.css';
import axios from 'axios';
import {url, regex} from '../constants/constants';
import {Alert, Spinner} from 'react-bootstrap';

function Login() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [error, setError] = useState();
    const [working, setWorking] = useState(false);
    
    async function checkLogIn(){
        const {data} = await axios.get(url + 'api/isauth', {withCredentials: true})
        if(data == true){
            window.location.href="/";
        }
    }

    async function login(){
        setWorking(true);
        if(!regex.test(email)){
            setError("Invalid email address.");
            setWorking(false);
            return;
        }
        try{
            const {data} = await axios.post(url + "api/login", {email: email, password: password}, {withCredentials: true});
            //history.push("/");
            window.location.href = "/"
        } catch(err) {
            setError(err.response.data);
            console.log(err.response);
            setWorking(false);
        }
        
    }

    useEffect(()=>{
        checkLogIn();
    }, [])

    async function register(){
        setWorking(true);
        if(!regex.test(email)){
            setError("Loš format email adrese.");
            setWorking(false);
            return;
        }
        if(!(password === repeatPassword)){
            setError("Šifre se ne podudaraju.");
            setWorking(false);
            return;
        }
        try{
            const {data} = await axios.post(url + "api/register", {name: name, email: email, password: password}, {withCredentials: true});
            window.location.href = "/"
        } catch(err){
            setError(err.response.data);
            setWorking(false);
        }
    }
    return (
        <Container>

            <Row>
                <Col md={3}></Col>
                    <Col md={6}>
                        <Tabs fill defaultActiveKey="login" id="uncontrolled-tab-example">
                            <Tab eventKey="login" title="Prijavljivanje"> 
                            <br></br>
                            <Form>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Email adresa</Form.Label>
                                    <Form.Control type="email" placeholder="Email adresa" onChange={(e)=>{setEmail(e.target.value)}} />
                                </Form.Group>
                                <br></br>
                                <Form.Group controlId="formBasicPassword">
                                    <Form.Label>Šifra</Form.Label>
                                    <Form.Control type="password" placeholder="Šifra" onChange={(e)=>{setPassword(e.target.value)}} />
                                </Form.Group>
                                <br></br>
                                {error && 
                                <Alert variant="danger">
                                    {error}
                                </Alert>
                                }
                                <br></br>
                                <Button variant="success" type="submit" onClick={(e)=>{e.preventDefault(); login()}}>
                                    {working ? <Spinner animation="border" /> : "Prijavi se"}
                                </Button>
                                <p></p>
                                <Form.Group>
                                    <Form.Label>{<a href="/forgotpassword">Zaboravili ste lozinku?</a>}</Form.Label>
                                </Form.Group>
                                </Form>
                            </Tab>
                        </Tabs>
                    </Col>
                <Col md={3}></Col>
            </Row>
        </Container>
    )
}

export default Login
