import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import slikaSabor from "../../assets/img/memorandum-1.jpg";
import slikaSabor2 from "../../assets/img/memorandum-2.jpg";

function Memorandum() {
  return (
    <Container>
      <Row>
        <div style={{ textAlign: "center" }}>
          <Col md={12}>
            <h2>Memorandum o međusobnoj saradnji</h2>
          </Col>
        </div>
      </Row>
      <Row>
        <Col md={3}>
          <i>Savez geodeta Srbije</i>
          <br></br>
          <i>25.09.2023. u 14 časova.</i>
        </Col>
      </Row>
      <hr></hr>
      <br></br>
      <Row>
        <Col>
          <p style={{ textAlign: "justify" }}>
            Dana 16.09.2023. godine, na XXXIV Saboru geodeta Republike Srbije, u
            mestu Brzeće na Kopaoniku, potpisan je MEMORANDUM o međusobnoj
            saradnji, između Saveza geodeta Srbije (dalje. Savez) i Komore
            trgovaca, ovlašćenih geodeta i trgovačkih društava za geodetske
            radove Republike Makedonije (dalje: Komora). Memorandum su
            potpisali, ispred Saveza, predsednik g.-din Doc. dr. Mladen Šoškić,
            dipl.geod.inž., i ispred Komore, predsednik g-din Mr Nikola
            Ribaroski.
          </p>
          <p style={{ textAlign: "justify" }}>
            Memorandumom su strane potpisnice postavile temelje da mogu
            potsticati i razvijati aktivnosti saradnje na različite načine iz
            oblasti rada dve institucije, u skladu sa njihovim zajedničkim
            interesima.
          </p>
        </Col>
      </Row>
      <Row>
        <Col md={10}>
          <p style={{ textAlign: "justify" }}>
            Ostvarivanje ciljeva od zajedničkog interesa, međusobnom saradnjom,
            u okviru svoje delatnosti, moguće je:
          </p>
          <p style={{ textAlign: "justify" }}>
            <ul style={{ paddingLeft: "5%" }}>
              <li>
                kontinuiranom saradnjom i koordinacijom između struka i stručnih
                timova Saveza i Komore u procesu izrade nacrta dokumenata ili
                mera za razvoj geodetske struke u R. Srbiji i R. Severnoj
                Makedoniji;
              </li>
              <li>
                organizovanjem i sprovođenjem aplikativnih projekata i međusobne
                razmene informacija;
              </li>
              <li>
                razmenom iskustava i najbolje prakse kroz posete predstavnika
                Saveza i Komore na stručnim događajima koji će se baviti temama
                od zajedničkog interesa;
              </li>
              <li>
                podsticanjem umrežavanja geodetskih stručnjaka i geodetskih
                firmi iz obe zemlje u cilju razmene iskustava, tehnika i
                tehnologija, uz korišćenje najbolje prakse, kao i zajedničkog
                nastupa za realizaciju različitih projekata na tržištima R.
                Srbije i R. Severne Makedonije, kao i na trećim tržištima
                (tržištima trećih zemalja);
              </li>
              <li>
                edukativnim aktivnostima i organizovanjem stručnih skupova,
                savetovanja, seminara i kurseva, po potrebi, za članove obe
                institucije;
              </li>
              <li>
                učešćem u zajedničkim projektima iz oblasti zajedničkih
                interesa;
              </li>
              <li>konsultativnim aktivnostima;</li>
              <li>
                podsticanjem procesa pristupa na oba tržišta rada, kroz
                izjednačavanje i međusobno priznavanje svih vrsta licenci,
                ovlašćenja itd., koji su potrebni za rad svim geodetskim
                stručnjacima i geodetskim organizacijama i društvima iz obe
                zemlje;
              </li>
              <li>
                organizovanjem i učešćem na naučnim i stručnim skupovima,
                konferencijama, simpozijumima i drugim formama i oblicima
                ogranizovanih manifestacija;
              </li>
              <li>
                druge oblasti iz zajedničkog interesa na kojima se zasniva
                saradnja.
              </li>
            </ul>
          </p>
        </Col>
      </Row>
      <Col>
        <p style={{ textAlign: "justify" }}>
          Memorandumom je definisano da će se zajedničke aktivnosti i projekti
          realizovati na inicijativu i po pozivu jedne od potpisnica
          memoranduma. Ovaj memorandum ne ograničava niti striktno određuje
          način i vidove saradnje, već usmerava sferu rada i stvara ambijent za
          realizaciju zajedničkih interesa. Međusobna saradnja, primenom ovog
          memoranduma, strane potpisnice će sprovoditi i realizovati
          profesionalno, u duhu dobre poslovne saradnje, i prevashodno
          prijateljski.
        </p>
      </Col>
      <Col>
        <div className="mb-4" style={{ height: "100%", width: "auto" }}>
          <img
            className="text-center"
            style={{ height: "auto", width: "100%" }}
            src={slikaSabor2}
            alt="sabor-grupa"
          ></img>
        </div>
      </Col>
      <Col>
        <div className="mb-4" style={{ height: "100%", width: "auto" }}>
          <img
            className="text-center"
            style={{ height: "auto", width: "100%" }}
            src={slikaSabor}
            alt="sabor-grupa"
          ></img>
        </div>
      </Col>
    </Container>
  );
}

export default Memorandum;
