import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import SertifikacijaSlika from "../../assets/img/news/obavestenje-povodom-sertifikacije/obavestenje-sertifikacija.webp";

function ObavestenjePovodomSertifikacije() {
  return (
    <Container>
      <Row>
        <div style={{ textAlign: "center" }}>
          <Col md={12}>
            <h2>
              Obaveštenje povodom sertifikacije i implementacije servisa digitalnog geodetskog
              elaborata
            </h2>
          </Col>
        </div>
      </Row>
      <Row>
        <Col md={3}>
          <i>Savez geodeta Srbije</i>
          <br></br>
          <i>08.02.2024.</i>
        </Col>
        <Col md={6}></Col>
        <Col md={3}></Col>
      </Row>
      <hr></hr>
      <Row>
        <Col md={1}></Col>
        <Col md={10}>
          <div className="text-center" style={{ height: "auto", width: "100%" }}>
            <img
              className="text-center"
              style={{ height: "auto", width: "50%" }}
              src={SertifikacijaSlika}
            ></img>
          </div>
        </Col>
        <Col md={1}></Col>
      </Row>
      <br></br>
      <Row>
        <Col md={2}></Col>
        <Col md={8}>
          <p style={{ textAlign: "justify" }}>Poštovane kolege,</p>
          <p style={{ textAlign: "justify" }}>
            Savez geodeta Srbije ima potrebu da informše svoje članstvo i celokupnu stručnu javnost
            u vezi novonastale situacije u vezi sertifikacije i implementacije servisa digitalnog
            geodetskog elaborata.
          </p>
          <p style={{ textAlign: "justify" }}>
            Savez geodeta Srbije je krovno stručno udruženje geodeta u Srbiji, sa najdužom
            tradicijom i u sadašnjem obliku postoji od 1951. godine, mada vezuje svoje korene čak za
            sredinu 19. veka. Pored toga, Savez geodeta Srbije je po brojnosti članstva
            najreprezentativnije udruženje geodeta u Srbiji. Naše članstvo obuhvata kolege koje rade
            u privatnim firmama svih formi, državnim preduzećima, javnom sektoru, školstvu itd.
            Polazeći od te činjenice smatramo da smo itekakako kompetentni da učestvujemo u svim
            aktuelnim dešavanjima u struci.
          </p>
          <p style={{ textAlign: "justify" }}>
            Želimo da istaknemo da u potpunosti podržavamo svako unapređenje i modernizaciju radnih
            procesa u struci i smatramo ih itekako neophodnim kako bi naša struka držala korak sa
            aktuelnim vremenom. U tom kontekstu želimo da istaknemo da pozdravljamo uvođenje
            digitalnog elaborata geodetskih radova od strane Republičkog geodetskog zavoda i
            mišljenja smo da je to pravi korak ka modernizaciji i osavremenjavanju katastarskog
            sistema, koji predstavlja jako bitan deo delokruga naše struke.
          </p>
          <p style={{ textAlign: "justify" }}>
            Međutim, smatramo da predloženi način implemenatcije i sertifikacije geodetskih
            organizacija u domenu uslovljavanja članstvom u dobrovoljnom stručnom udruženju nije u
            skladu ni sa etičkim ni sa zakonskim normama. Naime, uslovljavanje geodetskih
            organizacija da budu članovi bilo kog dobrovoljnog stručnog udruženja, (kako je navedeno
            u zvaničnom mejlu Republičkog geodetskog zavoda poslatog geodetskim organizacijama),
            kako bi bile svrstane u red prioritetnih organizacija za obuku i sertifikaciju vidimo
            kao jedan vid diskriminacije koji je u savremenom društvu neprihvatljiv. Smatramo da
            članstvo u dobrovoljnim stručnim udruženjima, pa makar to bio i Savez geodeta Srbije ili
            neko drugo, ne sme da pravi razliku u tretmanu geodetskih organizacija od strane
            Republičkog geodetskog zavoda.
          </p>
          <p style={{ textAlign: "justify" }}>
            Podsećamo, da smo još 3.11.2023. godine uputili zvaničan dopis Republičkom geodetskom
            zavodu da smo spremni da stavimo na raspolaganje sve naše kapacitete kako bi se
            implementacija i sertifikacija servisa digitalnog elaborata geodetskih radova sprovela
            na što brži i efikasniji način i po unapred utvrđenim kriterijumima za sve geodetske
            organizacije, nezavisno od članstva u bilo kom udruženju. Nažalost, nismo dobili nikakav
            odgovor.
          </p>
          <p style={{ textAlign: "justify" }}>
            Želimo da obavestimo naše članstvo i celokupnu stručnu zajednicu da ćemo se i dalje svim
            raspoloživim zakonskim i zdravorazumskim sredstvima boriti da ovaj proces protekne bez
            bilo kakve diskriminacije, na opštu korist i zadovoljstvo svih učesnika u procesu, kako
            državnih organa, tako i geodetskih organizacija i građana Srbije koji su korisnici naših
            usluga. Savez geodeta Srbije, kao krovno i jedino organizovano udruženje svih geodeta u
            Srbiji, ima obavezu da stoji na raspolaganju svim državnim organima (Republički
            geodetski zavod, Vlada, ministarstva, državne uprave itd.) za svaki vid saradnje u
            pogledu rešavanja aktuelnih izazova i unapređenje stanja naše struke, te naglašavamo da
            ćemo tu svoju obavezu i dalje ispunjavati, na korist cele države i društva.
          </p>
        </Col>
        <Col md={2}></Col>
      </Row>
      <br></br>
    </Container>
  );
}

export default ObavestenjePovodomSertifikacije;
