import React from "react";
import { Row, Col, Container, Table } from "react-bootstrap";
import { Carousel } from "react-bootstrap";
import Slika1 from "../../assets/img/news/rekovac2023/1.webp";
import Slika2 from "../../assets/img/news/rekovac2023/2.webp";
import Slika3 from "../../assets/img/news/rekovac2023/3.webp";
import Slika4 from "../../assets/img/news/rekovac2023/4.webp";

function Rekovac2023() {
  return (
    <Container>
      <Row>
        <div style={{ textAlign: "center" }}>
          <Col md={12}>
            <h2>
              22. Sabor geodeta centralne Srbije - Priznanja za saradnju i uspeh
            </h2>
          </Col>
        </div>
      </Row>
      <Row>
        <Col md={3}>
          <i>Savez geodeta Srbije</i>
          <br></br>
          <i>08.11.2023.</i>
        </Col>
        <Col md={6}></Col>
        <Col md={3}></Col>
      </Row>
      <hr></hr>
      <Row>
        <Col md={1}></Col>
        <Col md={10}>
          <div
            className="text-center"
            style={{ height: "auto", width: "100%" }}
          >
            <img
              className="text-center"
              style={{ height: "auto", width: "50%" }}
              src={Slika1}
            ></img>
          </div>
        </Col>
        <Col md={1}></Col>
      </Row>
      <br></br>
      <Row>
        <Col md={2}></Col>
        <Col md={8}>
          <p style={{ textAlign: "justify" }}>
            Na poziv rukovodstva Udruženja geodeta centralne Srbije, rukovodstvo
            Saveza geodeta Srbije prisustvovalo je 22. Saboru geodeta centralne
            Srbije koji je održan 14.10.2023. godine u Rekovcu. Ispred
            rukovodstva Saboru su prisustvovali predsednik dr Mladen Šoškić i
            sekretar mr Kosta Mirković koji su tom prilikom dobili i Priznanja u
            znak zahvalnosti uspešnu za saradnju i podršku u realizaciji
            aktivnosti Udruženja geodeta centralne Srbije.
          </p>
          <p style={{ textAlign: "justify" }}>
            Udruženje geodeta centralne Srbije je najaktivnija regionalana
            članica Saveza geodeta Srbije i ujedno jedina članica koja
            organizuju ovakva druženja. Novom rukovodstvu Udruženja geodeta
            centralne Srbije želimo mnogo uspeha u radu. Ubeđeni smo da će
            nastaviti i dalje unaprediti uspešan rad ovog udruženja.
          </p>
        </Col>
        <Col md={2}></Col>
      </Row>
      <br></br>
      <Row>
        <Col md={2}></Col>
        <Col md={8}>
          <Carousel>
            <Carousel.Item>
              <img className="d-block w-100" src={Slika2} alt="First slide" />
              <a
                className="carousel-control-prev"
                role="button"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon bi bi-chevron-left"
                  aria-hidden="true"
                />
              </a>
              <a
                className="carousel-control-next"
                role="button"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon bi bi-chevron-right"
                  aria-hidden="true"
                />
              </a>
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={Slika3} alt="Second slide" />
              <a
                className="carousel-control-prev"
                role="button"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon bi bi-chevron-left"
                  aria-hidden="true"
                />
              </a>
              <a
                className="carousel-control-next"
                role="button"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon bi bi-chevron-right"
                  aria-hidden="true"
                />
              </a>
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={Slika4} alt="Tird slide" />
              <a
                className="carousel-control-prev"
                role="button"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon bi bi-chevron-left"
                  aria-hidden="true"
                />
              </a>
              <a
                className="carousel-control-next"
                role="button"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon bi bi-chevron-right"
                  aria-hidden="true"
                />
              </a>
            </Carousel.Item>
          </Carousel>
        </Col>
        <Col md={2}></Col>
      </Row>
    </Container>
  );
}

export default Rekovac2023;
