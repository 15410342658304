import React from 'react';

import '../../assets/css/custom.css'
import '../../assets/vendor/animate.css/animate.min.css';
import '../../assets/vendor/bootstrap/css/bootstrap.min.css';
import '../../assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../../assets/vendor/boxicons/css/boxicons.min.css';
import '../../assets/vendor/glightbox/css/glightbox.min.css';
import '../../assets/vendor/swiper/swiper-bundle.min.css';
import '../../assets/css/style.css';
import Slika from '../../assets/img/aktuelnost.jpg'

function NewsScreen() {
    return (
        <div>
            <h2 style={{textAlign: "center"}}>Objedinjen Zapisnik sa sastanaka održanih 29.11 i 10.12.2021. god.</h2>
            <i>Savez Geodeta Srbije</i>
            <br></br>
            <i>10.01.2022.</i>
            <hr></hr>
            <div className="row" style={{textAlign: "justify", paddingTop: "4vh"}}>
              <div className="col-lg-6 order-1 order-lg-2">
              <p>
              U želji da se Savez geodeta Srbije, kao jedino geodetsko strukovno udruženje, afirmiše kod većeg broja budućih članova, pokrenuta je inicijativa rukovodstva Saveza za omasovljavanjem članova. Sagledavajući trenutak razvijenosti geodetske struke, procenjeno je da glavni nosioci i pokretači razvoja Sveza geodeta Srbije, moraju biti geodetske organizacije, pre svega kao finansijske institucije koje će vratiti Savez u okvire jedino mogućeg načina funkcionisanja.              </p>
              <p>Polazeći od zauzetih stavova, u prostorijama Saveza geodeta Srbije, organizovan je sastanak sa predstavnicima geodetskih organizacija dana 29.11.2021. god sa željom da se prisutni učesnici upoznaju sa idejom i iskrenim stavom rukovodstva za suštinskim promenama u organizaciji i funkcionisanju Saveza geodeta Srbije. U diskusiji koja se razvila, bilo je reči o načinu dosadašnjeg funkcionisanja, finansiranja, finansijskom izveštaju, predlozima budućeg funkcionisanja, finansiranja, definisanju potrebe za strukovnim udruženjem, o broju aktivnih članova, o broju članova iz sektora privatne geodetske prakse, obrazovnih ustanova, Republičkog geodetskog zavoda, državnih preduzeća koja imaju značajan broj geodetskih stručnjaka, o članarini, digitalnom načinu glasanja, registrovanju članova, oglašavanju i dostupnosti usluga Saveza geodeta Srbije. Obzirom na obiman opseg pokrenutih tema koje su sagledive ali u racionalnom redosledu vremena, zauzet je stav da se organizuje još jedan sastanak 10.12.2021. god. </p>
              </div>
              <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
                  <img src={Slika} className="img-fluid" alt=""/>
                </div>
            </div>

            <div className="row" style={{textAlign: "justify"}}>
                <p>Do narednog sastanka firma <a href="https://geotaur.com/">Geotaur</a> je predložila da izradi sajt SGS-a i softver za elektronsko glasanje članova Saveza, a firma <a href="https://www.sdcpro.rs/">SDC Professional</a> je predložila da učesnici sastanka do sledećeg puta donesu popunjen spisak zaposlenih u svojim firmama za porebe definisanja članova i iznosa članarine. Teme budućeg sastanka su: definisanje ciljeva SGS-a, definisanje organizacione strukture za minimum funkcionisanja SGS-a i definisanje iznosa članarine za sve kategorije članova.</p>
            </div>

            <div className="row">
                <p style={{textAlign: "left"}}>U periodu koji je prethodio sastanku od 10.12.2021. , nosioci predloga sa prethodnog sastanka su vrlo efikasno odradili preuzete obaveze, što je konstatovano na sastanku. Teme postavljene na prethodnom sastanku su diskutovane od strane svakog učesnika i sublimirane u sledećim navodima:</p>
                <ol>
                    <li>
                        <p>Definisanje ciljeva SGS-a </p>
                        <p>
                        Ciljevi SGS-a su definisani Statutom SGS-a, ali sagledavajući aktuelni trenutak, definisani su prioriteti u realizaciji ciljeva: </p>
                        <ul>
                            <li><i className="bi bi-check-circled" /> Povećanje broja članova SGS-a putem ličnog autoriteta svakog člana SGS-a i sprovođenje internet kampanje,</li>
                            <li><i className="bi bi-check-circled" /> Zaposlenje radnika, pravne i geodetske struke,</li>
                            <li><i className="bi bi-check-circled" /> Uspostavljanje sajta sa informacijama o članovima, postavljanjem sistema za elektronsko glasanje, mogućnost komunikacije sa SGS-om, oglašavanje, informacijama od značaja,..</li>
                            <li><i className="bi bi-check-circled" /> Izbor novog rukovodstva,</li>
                            <li><i className="bi bi-check-circled" /> Aktiviranje regionalnih centara članica SGS-a,</li>
                            <li><i className="bi bi-check-circled" /> Aktivno učešće prilikom izrade novih Zakonskih akata i njihovih izmena i dopuna, (uredbe, pravilnici, instrukcije, uputstva i stručna uputstva) koje donose nadlžene institucije, a u kojima se pojavljuje interes struke za stručnim definisanjem dobrih rešenja,</li>
                            <li><i className="bi bi-check-circled" /> Uspostavljanje pravne sigurnosti članova SGS-a u smislu zastupanja članova pred nadležnim institucijama,</li>
                            <li><i className="bi bi-check-circled" /> Saradnja sa Republičkim geodetskim zavodom i drugim organima Republike Srbije,</li>
                            <li><i className="bi bi-check-circled" /> Rad na standardizaciji i dostupnost iste svim članovima,</li>
                            <li><i className="bi bi-check-circled" /> Edukacija, razmena stručnih informacija,</li>
                            <li><i className="bi bi-check-circled" /> Mogućnost korišćenja prostorija SGS-a od strane članova SGS-a u okvirima unapređenja i razvoja članova, članica SGS-a i samog SGS-a,</li>
                            <li><i className="bi bi-check-circled" /> Razmena informacija sa članovima SGS-a,</li>
                            <li><i className="bi bi-check-circled" /> Uključivanje obrazovnih institucija u rad SGS-a,</li>
                            <li><i className="bi bi-check-circled" /> Promociji struke,</li>
                        </ul>
                    </li>
                    <br></br>
                    <li>Definisanje organizacione strukture za minimum funkcionisanja SGS-a
                    Savez geodeta Srbije za potrebe funkcionisanja mora da zaposli dva radnika, pravne i geodetske stuke. Ovi zaposleni će uz pomoć rukovodećeg kadra SGS-a reševati i sprovoditi sve postavljene ciljeve i zadatke usklđene sa potrebama SGS-a.</li>
                </ol>
                <p>Na osnovu gore navedenog, doneti su sledeći zaključci:</p>
                <ol>
                    <li>SGS šelje Zahtev za učlanjenje sa predlogom Ugovora i pratećim obrascima prisutnim firmama, dostavljanje Računa za članarinu - decembar,</li>
                    <li>Uspostavljanje sajta SGS-a, mail servera, informacije o stipendiranju..</li>
                    <li>Postavljanje na sajt članova i informacija o ukupnom broju članova</li>
                    <li>Individualno angažovanje na učlanjenju prijateljskih firmi i njihovih zaposlenih,</li>
                    <li>Dostavljanje predloga termina za sledeći sastanak</li>
                    <li>...</li>
                </ol>
            </div>
        </div>
    )
}

export default NewsScreen
