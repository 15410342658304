import React from 'react';
import {Carousel, Col, Container, Row} from "react-bootstrap";
import Slika2 from "../../assets/img/news/vest-porec/slika2.webp";
import Slika1 from "../../assets/img/news/vest-porec/slika1.webp";
import Slika3 from "../../assets/img/news/vest-porec/slika3.webp";
import Slika4 from "../../assets/img/news/vest-porec/slika4.webp";

function VestOPorecu() {
    return (
        <Container>
            <Row>
                <div style={{ textAlign: "center" }}>
                    <Col md={12}>
                        <h2>
                            Poseta 16. simpoziju ovlašćenih inženjera geodezije
                        </h2>
                    </Col>
                </div>
            </Row>
            <Row>
                <Col md={3}>
                    <i>Savez geodeta Srbije</i>
                    <br></br>
                    <i>08.11.2023.</i>
                </Col>
                <Col md={6}></Col>
                <Col md={3}></Col>
            </Row>
            <hr></hr>
            <Row>
                <Col md={1}></Col>
                <Col md={10}>
                    <div
                        className="text-center"
                        style={{ height: "auto", width: "100%" }}
                    >
                        <img
                            className="text-center"
                            style={{ height: "auto", width: "50%" }}
                            src={Slika2}
                        ></img>
                    </div>
                </Col>
                <Col md={1}></Col>
            </Row>
            <br></br>
            <Row>
                <Col md={2}></Col>
                <Col md={8}>
                    <p style={{ textAlign: "justify" }}>
                        Na poziv rukovodstva Hrvatske komore ovlašćenih inženjera geodezije rukovodstvo Saveza geodeta Srbije prisustvovalo je 16. simpoziju ovlašćenih inženjera geodezije koji je održan od 25.10. do 28.10.2023. godine. Ispred rukovodstva Saboru su prisustvovali predsednik dr Mladen Šoškić i podpredsednik dr Milutin Pejović.
                    </p>
                    <p style={{ textAlign: "justify" }}>
                        Ova poseta iskorišćena je za ostvarivanje novih i produbljivanje postojećih kontakata, kao i za sticanje dragocenih iskustava u pogledu organizacije geodetske struke u državama EU. Način organizacije, licenciranje, komunikacija i saradnja između raznih državnih i strukovnih organizacija u oblasti geodezije su oblasti u kojima možemo mnogo da naučimo od zemalja EU i primenimo kod nas.
                    </p>
                    <p style={{textAlign: "justify"}}>
                        Predsednik dr Mladen Šoškić aktivno je učestvovao u 16. simpoziju ovlašćenih inženjera geodezije tako što je bio učesnik okruglog stola posvećenog komasaciji. Tema komasacije izazvala je ogromno interesovanje učesnika simpozija budući da Hrvatsku očekuje realizacija velikog broja projekata iz oblasti komasacije zahvaljujući finansiranju iz fondova EU.
                    </p>
                </Col>
                <Col md={2}></Col>
            </Row>
            <br></br>
            <Row>
                <Col md={2}></Col>
                <Col md={8}>
                    <Carousel>
                        <Carousel.Item>
                            <img className="d-block w-100" src={Slika1} alt="First slide" />
                            <a
                                className="carousel-control-prev"
                                role="button"
                                data-bs-slide="prev"
                            >
                <span
                    className="carousel-control-prev-icon bi bi-chevron-left"
                    aria-hidden="true"
                />
                            </a>
                            <a
                                className="carousel-control-next"
                                role="button"
                                data-bs-slide="next"
                            >
                <span
                    className="carousel-control-next-icon bi bi-chevron-right"
                    aria-hidden="true"
                />
                            </a>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={Slika3} alt="Second slide" />
                            <a
                                className="carousel-control-prev"
                                role="button"
                                data-bs-slide="prev"
                            >
                <span
                    className="carousel-control-prev-icon bi bi-chevron-left"
                    aria-hidden="true"
                />
                            </a>
                            <a
                                className="carousel-control-next"
                                role="button"
                                data-bs-slide="next"
                            >
                <span
                    className="carousel-control-next-icon bi bi-chevron-right"
                    aria-hidden="true"
                />
                            </a>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={Slika4} alt="Tird slide" />
                            <a
                                className="carousel-control-prev"
                                role="button"
                                data-bs-slide="prev"
                            >
                <span
                    className="carousel-control-prev-icon bi bi-chevron-left"
                    aria-hidden="true"
                />
                            </a>
                            <a
                                className="carousel-control-next"
                                role="button"
                                data-bs-slide="next"
                            >
                <span
                    className="carousel-control-next-icon bi bi-chevron-right"
                    aria-hidden="true"
                />
                            </a>
                        </Carousel.Item>
                    </Carousel>
                </Col>
                <Col md={2}></Col>
            </Row>
        </Container>
    );
}

export default VestOPorecu;