import React, {useEffect, useState} from 'react'
import Header from './Header'
import Footer from './Footer'

function Template(props) {
    useEffect(()=>{
        /*for (let i=0; i<scripts.length; i++){
          const script = document.createElement('script');
          script.src = scripts[i];
          script.async = true;
          document.body.appendChild(script);
          scriptElements.push(script)
        }*/
        const script = document.createElement('script');
        script.src = '../assets/js/main.js';
        script.async = true;
        document.body.appendChild(script);
        return () => {
          document.body.removeChild(script);
          }
      }, []);
    return (
        <div>
            <Header />
            <main id="main">
                {/* ======= Breadcrumbs ======= */}
                <section className="breadcrumbs">
                    <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <h2>{props.title}</h2>
                        <ol>
                            {props.path.map((item, index) => {
                                    if(index !== props.path.length - 1) {
                                        return(
                                            <li><a href={props.links[index]}>{item}</a></li>
                                        )
                                    }
                                    else{ 
                                        return(
                                            <li><a>{item}</a></li>
                                        )
                                    }
                                })
                            }
                        </ol>
                    </div>
                    </div>
                </section>{/* End Breadcrumbs */}
                <section className="inner-page">
                    <div className="container">
                            {props.components}
                    </div>
                </section>
            </main>{/* End #main */}
            <Footer />
        </div>
    )
}

export default Template
