import React, {useEffect} from 'react';
import {Container, Row, Col} from 'react-bootstrap';

function MedjunarodnaSaradnjaScreen() {
    return (
        <Container>
            <Row>
                <div className="text-center"><h2>Međunarodna saradnja i stručna aktivnost</h2></div>
            </Row>
            <hr></hr>
            <Row>
                <Col md={12}>
                    <p style={{textAlign: "justify"}}>
                    U periodu 1987.-2001. godine, stručne aktivnosti SGIGJ ogledaju se u organizaciji nacionalnih savetovanja posvećenih aktuelnim temama razvoja geodetske struke. Za sva nacionalna savetovanja štampani su zbornici radova u izdanju SGIGJ. Savez geodetskih inženjera i geometara Srbije u ovom periodu organizuje niz savetovanja i sabora.
                    </p>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <p style={{textAlign: "justify"}}>
                        U cilju podrške razvoju geodezije i geofizike i uključenja u rad „Međunarodne geodetske i geofizičke unije“, u okviru SGIGJ formiran je „Komitet za geodeziju i geofiziku“ 1987. godine. U okviru međunarodne aktivnosti potpisan je 1987. godine protokol o saradnji SGIGJ i „Naučno-tehničkog saveza za geodeziju i uređenje zemljišta Bugarske“.
                    </p>
                </Col>
            </Row>
            <Row>
            <Col md={3}>
                    <div className="text-center" style={{textAlign: "left"}}>
                        <img src="assets/img/FIGlogo.png"></img>
                        <br></br>
                        <i>Logo International Federation of Surveyors</i>
                    </div>
                </Col>
                <Col md={9}>
                    <p style={{textAlign: "justify"}}>
                        U periodu 2002 - 2017 godine, Savez geodeta Srbije organizovao je i prisustvovao više uspešnih i važnih konferencija, savetovanja i sabora u saradnji sa Republičkim geodetskim zavodom i drugim domaćim i međunarodnim institucijama. Ovde bi mogla da se istakne prisustvo “XX kongresu međunarodnog udruženja za fotogrametriju i daljinsku detekciju – International Society for Photogrammetry and Remote Sensing (ISPRS)” koji je održan jula 2004. U Istanbulu. Najznačajnija ostvarena saradnja je sa Međunarodnom asocijacijom geodeta – FIG-om. Na generalnoj skupštini i XXIII međunarodnom kongresu FIG-a, održane u Minhenu 2006. godine, Savez geodeta Srbije postao je stalni član ove organizacije. Na Saboru geodeta u martu 2008. godine ostvarena je saradnja sa geodetama Bugarske, kada je pokrenuta inicijativa za formiranje strukovnog udruženja geodeta Balkana, dva meseca kasnije u Kladovu utvrđeni su principi saradnje između balkanskih geodetskih asocijacija. Početkom 2009. Godine u Bugarskoj je održana osnivačka skupština “Balkanske geodeteske asocijacije“ (BGA), čije su članice geodetske asocijacije iz Srbije, Bugarske, Rumunije, Grčke, Turske i Makedonije. Asocijacija je registrovana juna 2011. godine u Sofiji. Savez geodeta Srbije je u Kladovu 2011. Savez geodeta Srbije je u Kladovu 2011. godine, u saradnji sa naučnim i profesionalnim institucijama organizovao u okviru XXIV Sabora geodeta Srbije i Međunarodnu naučnu konferenciju pod nazivom „Profesionalna praksa i obrazovanje u geodeziji i srodnim oblastima“ (International scientific conference – Professional practice and education in Geodesy and related fields).
                    </p>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <p style={{textAlign: "justify"}}>
                        Organizatori Međunarodne naučne konferencije bili su Univerzitet u Beogradu − Građevinski fakultet i Univerzitet u Novom Sadu − Fakultet tehničkih nauka, uz podršku institucija: Ministarstvo za obrazovanje i nauku, Republički geodetski zavod, Inženjerska komora Srbije, “Međunarodna asocijacija geodeta - FIG“, Univerzitet u Ljubljani – Fakultet prirodnih nauka i inženjerstvo i Visoka građevinsko-geodetska škola Beograd.
                    </p>
                </Col>
            </Row>
        </Container>
    )
}

export default MedjunarodnaSaradnjaScreen
