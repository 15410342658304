import {useState, useEffect} from 'react'
import axios from 'axios'
import { url } from "../constants/constants";
import { Modal, Button, Form, FormGroup, Container, Alert, Spinner} from "react-bootstrap"

function ConfirmMailModal(props) {

    const [question, setQuestion] = useState();
    const [options, setOptions] = useState(["Odgovor", "Odgovor"]);
    const [type, setType] = useState("one");
    const [error, setError] = useState();
    const [working, setWorking] = useState(false);
    const [availability, setAvailability] = useState("anketa");

    function changeType(checked, type){
        if(checked){
            setType(type);
        } 
    }

    function changeAvailability(checked, type){
        if(checked){
            setAvailability(type);
        }
    }

    function changeType(checked, type){
        if(checked){
            setType(type);
        } 
    }

    function checkOptions(){
        for(let i = 0; i < options.length; i++){
            if(options[i] === ""){
                return false;
            }
        }
        return true;
    }



    async function createPoll(){
        //Pravi modal u backendu
        console.log(options);
        if(!question){
            setError("Molimo unestie pitanje.");
            return;
        } else if(!type){
            setError("Molimo izaberite tip odluke.");
            return;
        } else if(!checkOptions()){
            setError("Molimo unesite validne odgovore");
            return;
        }
        setError();
        try{
            const {data} = await axios.post(url + "api/createpoll/", {question: question, options: options, type: type, availability: availability}, {withCredentials: true});
            props.onHide();
            window.location.reload();
        }
        catch(err){
            setError("Something went wrong.");
        }
        
    }

    function setOption(index, e){
        options[index] = e.target.value;
        setOptions([...options]);
    }

    function removeRow(index){
        options.pop(index);
        setOptions([...options]);
    }

    useEffect(()=>{
    }, [])

    return (
        <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            Napravite Odluku
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {error && 
                <Alert variant="danger">{error}</Alert>
            }
            <Container>
                <Form>

                    <Form.Group>
                        <Form.Text>Pitanje:</Form.Text>
                        <Form.Control type="text" placeholder="Unesite pitanje" onChange={(e)=>setQuestion(e.target.value)}></Form.Control>
                    </Form.Group>
                    <br></br>
                    <FormGroup>
                        <Form.Text>Odgovori:</Form.Text>
                        {options.map((option, index)=> {
                            return(
                                <div style={{display: "flex", paddingBottom: 10}}>
                                    <Form.Control type="text" placeholder="Odgovor" value={option} onChange={(e)=>setOption(index, e)}></Form.Control>
                                    {options.length > 2 && 
                                        <Button variant="danger" style={{marginLeft: 5}} onClick={()=>removeRow(index)}>X</Button>
                                    }
                                </div>
                            );
                        })}
                        <Button variant="success" onClick={()=>{options.push("Odgovor"); setOptions([...options])}}>Dodaj odgovor</Button>
                    </FormGroup>
                    <br></br>
                    <Form.Group>
                        <Form.Label>Vrsta odgovora</Form.Label>
                        <Form.Check
                            type="radio"
                            label="Jedan odgovor"
                            name="formHorizontalRadios"
                            id="formHorizontalRadios1"
                            onClick={(e)=>{changeType(e.target.checked, "one")}}
                            />
                            <Form.Check
                            type="radio"
                            label="Više odgovora"
                            name="formHorizontalRadios"
                            id="formHorizontalRadios2"
                            onClick={(e)=>{changeType(e.target.checked, "many")}}
                            />
                        </Form.Group>
                    <br></br>

                    <Form.Group>
                        <Form.Label>Tip glasanja</Form.Label>
                        <Form.Check
                            type="radio"
                            label="Anketa (Dostupno svima)"
                            name="formHorizontalRadioss"
                            id="formHorizontalRadios1"
                            onClick={(e)=>{changeAvailability(e.target.checked, "anketa")}}
                            />
                            <Form.Check
                            type="radio"
                            label="Skupštinsko glasanje (Dostupno samo članovima SGS-a)"
                            name="formHorizontalRadioss"
                            id="formHorizontalRadios2"
                            onClick={(e)=>{changeAvailability(e.target.checked, "glasanje")}}
                            />
                    </Form.Group>
                    <br></br>
                    <Button variant="success" onClick={()=>{createPoll()}}>{working ? <Spinner animation="border" /> : "Napravi"} </Button>
                </Form>
            </Container>
        </Modal.Body>
        </Modal>
    )
}

export default ConfirmMailModal
