import React, {useState, useEffect} from 'react'
import { PieChart } from 'react-minimal-pie-chart';
import axios from 'axios';
import {url} from '../constants/constants';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';


ChartJS.register(ArcElement, Tooltip, Legend);

function PollResults(props) {

    const [results, setResults] = useState();
    const [data, setData] = useState();
    const [option, setOption] = useState({
        tooltips: {
          enabled: true
        },
        options: {
            plugins: {
                labels: {
                    render: "percenage"
                }
                
            }
        }
      });

    async function getPoll(){
        const {data} = await axios.get(url + "api/getpoll/?pollId=" + props.pollId, {withCredentials: true});
        setResults(data);
    }

    function processData(){
        const options = results.options; //Array
        const answers = results.votes; //Json


        


        let numberOfVotes = [];
        let colors = [];
        let borderColors = [];

        let totalVotes = 0;

        for(let i = 0; i < Object.keys(answers).length; i++){
            numberOfVotes.push(answers[i].length);
            totalVotes += answers[i].length;
            const r = Math.floor((Math.random())*255);
            const g = Math.floor((Math.random())*255);
            const b = Math.floor((Math.random())*255);
            colors.push(`rgba(${r}, ${g}, ${b}, 0.2)`);
            borderColors.push(`rgba(${r}, ${g}, ${b}, 1)`);
        }

        for(let i = 0; i < options.length; i++){
            options[i] = options[i] + " (" + ((numberOfVotes[i] / totalVotes)*100).toFixed(2) + "%)";
        }

        const borderWidth = 1;

        const formattedData = {
            labels: options,
            datasets: [
                {
                    label: "# of votes",
                    data: numberOfVotes,
                    backgroundColor: colors,
                    borderColor: borderColors,
                    borderWidth: borderWidth,
                },
            ],
        };
        setData(formattedData);
    }

    useEffect(()=>{
        if(!results){
            getPoll();
        }
        if(results && !data){
            console.log("usao");
            processData();
        }

    }, [results])


    return (
        <div>
            {data &&   
                
                <div>
                    {console.log(option)}
                    {/*<PieChart data={data} label={({ x, y, dx, dy, dataEntry }) => (
                            <text
                                x={x}
                                y={y}
                                dx={dx}
                                dy={dy}
                                dominant-baseline="central"
                                text-anchor="middle"
                                style={{
                                    fontSize: '5px',
                                    fontFamily: 'sans-serif',
                                }}
                                >
                                {dataEntry.percent > 0 && `${dataEntry.title} - ${dataEntry.percent}%` }
                            </text>
                        )} 
                            />*/}
                    <Pie data={data} options={option} />
                </div>
            }
        </div>
    )
}

export default PollResults
