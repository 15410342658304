import React from "react";
import { Carousel, Col, Container, Row } from "react-bootstrap";
import Slika1 from "../../assets/img/news/vest-skoplje/01.webp";
import Slika2 from "../../assets/img/news/vest-skoplje/02.webp";
import Slika3 from "../../assets/img/news/vest-skoplje/03.webp";
import Slika4 from "../../assets/img/news/vest-skoplje/04.webp";
import Slika5 from "../../assets/img/news/vest-skoplje/05.jpg";

function SkopljePredavanje() {
  return (
    <Container>
      <Row>
        <div style={{ textAlign: "center" }}>
          <Col md={12}>
            <h2>Predavanje u inženjerskoj komori Severne Makedonije</h2>
          </Col>
        </div>
      </Row>
      <Row>
        <Col md={3}>
          <i>Savez geodeta Srbije</i>
          <br></br>
          <i>03.04.2024.</i>
        </Col>
        <Col md={6}></Col>
        <Col md={3}></Col>
      </Row>
      <hr></hr>
      <Row>
        <Col md={1}></Col>
        <Col md={10}>
          <div
            className="text-center"
            style={{ height: "auto", width: "100%" }}
          >
            <img
              className="text-center"
              style={{ height: "auto", width: "50%" }}
              src={Slika1}
            ></img>
          </div>
        </Col>
        <Col md={1}></Col>
      </Row>
      <br></br>
      <Row>
        <Col md={2}></Col>
        <Col md={8}>
          <p style={{ textAlign: "justify" }}>
            Delegacija Saveza geodeta Srbije je na poziv Komore ovlašćenih
            geodeta i društava za geodetske radove Republike Makedonije održala
            stručno predavanje u Inženjerskoj komori Severne Makedonije.
            Delegaciju Saveza Geodeta Srbije činili su predsednik dr Mladen
            Šoškić i potpredsednik dr Milutin Pejović, a poseta je realizovana
            od 6. do 8. marta 2024. godine. U sklopu delegacije Saveza, bili su
            i predstavnici Ministarstva građevinarstva saobraćaja i
            infrastrukture kao i Ministarstva za evropske integracije, Vlade
            Republike Srbije. Poseta je oraganizovana na osnovu Memoranduma o
            međusobnoj saradnji sa Komorom ovlašćenih geodeta i društava za
            geodetske radove Republike Makedonije, potpisanog u septembru 2023.
            godine.
          </p>
          <p style={{ textAlign: "justify" }}>
            Stručno predavanje je imalo za temu „Mogućnost primene urbane
            komasacije kod uređenja naselja u zemljama Jugoistočne Evrope“.
            Predavanje je izazvalo veliku pažnju među kolegama geodetama ali i
            inženjerima urbanističke struke, članovima Inženjerske komore
            Severne Makedonije.
          </p>
          <p style={{ textAlign: "justify" }}>
            Pored stručnog predavanja, poseta je iskorišćena i za sastanke sa
            predstavnicima Inženjerske komore Severne Makedonije, predstavnicima
            Privredne komore Severne Makedonije kao i kolegama sa geodetskog
            odseka Građevinskog fakulteta u Skoplju. Pokrenute su inicijative za
            projekte međusobne saradnje u oblastima geodezije i urbanizma koji
            bi potenciajlno mogli biti realizovani uz podršku Evropske Unije.
          </p>
          <p style={{ textAlign: "justify" }}>
            Poseta i predavanja izazvali su veliku pažnju i u regionu, tako da
            su dobijene pozivnice i iz drugih zemalja našeg okruženja za
            održavanje sličnih radionica i predavanja. Savez geodeta Srbije će
            se uvek, u skladu sa svojim mogućnostima, odazivati na ovakve pozive
            u cilju regionalne stručne saradnje i podizanja ugleda geodetske
            struke.
          </p>
          <br></br>
        </Col>
        <Col md={2}></Col>
      </Row>
      <br></br>
      <Row>
        <Col md={2}></Col>
        <Col md={8}>
          <Carousel>
            <Carousel.Item>
              <img className="d-block w-100" src={Slika2} alt="First slide" />
              <a
                className="carousel-control-prev"
                role="button"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon bi bi-chevron-left"
                  aria-hidden="true"
                />
              </a>
              <a
                className="carousel-control-next"
                role="button"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon bi bi-chevron-right"
                  aria-hidden="true"
                />
              </a>
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={Slika3} alt="Second slide" />
              <a
                className="carousel-control-prev"
                role="button"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon bi bi-chevron-left"
                  aria-hidden="true"
                />
              </a>
              <a
                className="carousel-control-next"
                role="button"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon bi bi-chevron-right"
                  aria-hidden="true"
                />
              </a>
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={Slika4} alt="Tird slide" />
              <a
                className="carousel-control-prev"
                role="button"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon bi bi-chevron-left"
                  aria-hidden="true"
                />
              </a>
              <a
                className="carousel-control-next"
                role="button"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon bi bi-chevron-right"
                  aria-hidden="true"
                />
              </a>
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={Slika5} alt="Tird slide" />
              <a
                className="carousel-control-prev"
                role="button"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon bi bi-chevron-left"
                  aria-hidden="true"
                />
              </a>
              <a
                className="carousel-control-next"
                role="button"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon bi bi-chevron-right"
                  aria-hidden="true"
                />
              </a>
            </Carousel.Item>
          </Carousel>
        </Col>
        <Col md={2}></Col>
      </Row>
    </Container>
  );
}

export default SkopljePredavanje;
