import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

function SaborGeodetaScreen() {
  return (
    <Container>
      <Row>
        <div style={{ textAlign: "center" }}>
          <Col md={12}>
            <h2>XXXIII Сабор Геодета</h2>
          </Col>
        </div>
      </Row>
      <Row>
        <Col md={3}>
          <i>Савез геодета Србије</i>
          <br></br>
          <i>05.8.2022.</i>
        </Col>
        <Col md={6}></Col>
        <Col md={3}></Col>
      </Row>
      <hr></hr>
      <Row>
        <Col md={5}>
          <br></br>
          <div
            className="text-center"
            style={{ height: "auto", width: "100%" }}
          >
            <img
              style={{ height: "auto", width: "100%" }}
              src="../assets/img/sabor.png"
            ></img>
            <br></br>
            <i></i>
          </div>
        </Col>
        <Col md={7}>
          <p></p>
          <p style={{ textAlign: "justify" }}>Поштоване колеге,</p>
          <p style={{ textAlign: "justify" }}>
            Задовољство ми је да вас обавестим да ћемо ове, 2022. године после
            дуже паузе због познатих околности, организовати XXXIII Сабор
            геодета Србије. Традиција организације Сабора је једна од наших
            најлепших традиција, по којој смо препознатљиви и јединствени. На
            тај начин, у пријатној, а опет радној атмосфери, размењујемо
            искуства, упознајемо се и чинимо много добрих ствари за нас
            појединачно и струку у целини.
          </p>
          <p style={{ textAlign: "justify" }}>
            Позивам вас да присуствујете XXXIII Сабору геодета Србије и узмете
            активног учешћа. Овогодишњи Сабор одржаће се у прелепом амбијенту
            Зептер хотела “Дрина” у Бајиној Башти.
          </p>
          <p style={{ textAlign: "right" }}>
            др Младен Шошкић, дипл. инж. геод.,
          </p>
          <p style={{ textAlign: "right" }}>Председник</p>
          <p style={{ textAlign: "left" }}>
            Овде можете преузети{" "}
            <a
              href="../assets/media/Program_i_prijava_za_XXXIII_Sabor_Hotel_Drina.pdf"
              target="_blank"
            >
              Програм Сабора
            </a>
            ,
          </p>
          <p style={{ textAlign: "left" }}>видимо се!</p>
        </Col>
      </Row>
    </Container>
  );
}

export default SaborGeodetaScreen;
