import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import slikaSabor from '../../assets/img/news/sabor-2023/sabor.webp';

function Sabor2023Screen() {
  return (
    <Container>
      <Row>
        <Col md={6}>
          <div className="mb-4" style = {{height: "100%", width: "auto"}}>
            <img className='text-center' style = {{height: "auto", width: "100%"}} src={slikaSabor}></img>
          </div>
        </Col>
        <Col md={6}>
            <p style={{textAlign: "justify"}}>
                Poštovane koleginice i kolege,
            </p>
            <p style={{textAlign: "justify"}}>
                Zadovoljstvo mi je da vas obavestim da ćemo ove, 2023. godine organizovati XXXIV Sabor geodeta Srbije. Tradicija organizacije Sabora je jedna od naših najlepših tradicija, po kojoj smo prepoznatljivi i jedinstveni. Na taj način, u prijatnoj, a opet radnoj atmosferi, razmenjujemo iskustva, upoznajemo se i činimo mnogo dobrih stvari za nas pojedinačno i struku u celini.
            </p>
            <p style={{textAlign: "justify"}}>
                Pozivam vas da prisustvujete XXXIV Saboru geodeta Srbije i uzmete aktivnog učešća. Ovogodišnji Sabor održaće se u prelepom ambijentu hotela “Junior” u Brzeću, na planini Kopaonik.
            </p>
            <p style={{float: "right", textAlign: "right"}}>
            dr Mladen Šoškić, dipl. inž. geod.,<br></br>
            Predsednik
            </p>
        </Col>
      </Row>
      <Row>
        <p>Ovde možete preuzeti <a href="/assets/media/Prijava za XXXIV Sabor_Hotel Junior cirilica-Mladen.doc" download={true}>Prijavu za Sabor,</a><br></br>
        vidimo se!</p>
      </Row>
    </Container>
  )
}

export default Sabor2023Screen